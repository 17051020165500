import { TextArea } from "formsy-semantic-ui-react";
import React from "react";
import classes from './Preview.module.css'

export default function Preview(props){
    const AboutUsRendering = () => {
        if( props.fullAiData.AboutUs){
            let about = "";
            props.fullAiData.AboutUs.forEach(element => {
                element = (element && typeof element === 'string') ? JSON.parse(element) : element;
                if(element.skip === undefined ){
                    if(element.a.trim() != ""  ){
                        let question = element.q.split("?");
                        let Q = "<h4>"+question[0]+"?"+"</h4>";
                        let a = element.a.trim();
                        about += Q+a;
                    }
                }else if(element.skip === false ){
                    if(element.a.trim() != ""  ){
                        let question = element.q.split("?");
                        let Q = "<h4>"+question[0]+"?"+"</h4>";
                        let a = element.a.trim();
                        about += Q+a;
                    }
                }
            });
            return (<><div dangerouslySetInnerHTML={{__html: about}}/></>)
        }else{
            return (<></>);
        }
    }
    const ServiceesProvide = () => {
        if(props.fullAiData.BuisnessCat){
            let services = "";
            let subServices = props.fullAiData.BuisnessCat.subCatDeata.filter( mainCat =>  {
                if(mainCat.name){
                    return mainCat.name;
                }
            } ).map((ser => ser.name)).join("<br/>");
            let titletext = subServices != "" ? "<strong>What services do we offer?  </strong>" : "";
            let additioncategory = "";
            if(props.fullAiData.BuisnessCat.additionCategories && props.fullAiData.BuisnessCat.additionCategories.trim()){
                additioncategory = props.fullAiData.BuisnessCat.additionCategories;
            }
            let arrayForm = additioncategory.split("\n");
            services = titletext+"<br/>"+subServices+"<br/>"+arrayForm.join("<br/>");
            return (<><div dangerouslySetInnerHTML={{__html: services}}/></>)
        }else{
            return (<></>);
        }
    }

   
    const ExcludedServices = () => {
        let excudedSer = "";
        console.log("preview",props.fullAiData.BuisnessCat);
        if(props.fullAiData.BuisnessCat){
            props.fullAiData.BuisnessCat.subCatDeata.map( (even => {
                if(even.checked)
                excudedSer += even.name + ". <br/>";
            }) )
            excudedSer += props.fullAiData.BuisnessCat.additionCategories;
             return (<><div dangerouslySetInnerHTML={{__html: excudedSer}}/></>)
        }else{
            return (<></>)
        }
    }
    const FaqPreview = () => {
        if(props.fullAiData.YourFAQA){
            let faqs = "<br/>";
            props.fullAiData.YourFAQA.forEach(element => {
                element = (element && typeof element === 'string') ? JSON.parse(element) : element;
                if(element.a.trim() != ""){
                    let question = element.q.split("?");
                    let answer = element.a.trim();
                    let arrayForm = answer.split("\n");
                    faqs += "<strong>Question : "+question[0]+"?</strong>"+"<br> Answer: "+arrayForm.join("<br/> ")+"<br/><br/>";
                }
            });
            let additonFaq = "";
            if(props.fullAiData.AdditonalFaqContext ){
                let arrayForm = props.fullAiData.AdditonalFaqContext.split("\n");
                additonFaq = arrayForm.join("<br/> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp");
            }
            return (<><div dangerouslySetInnerHTML={{__html: faqs}}/>
            <div dangerouslySetInnerHTML={{__html: additonFaq}}/></>)
        }else{
            return (<></>)
        }
    }
    let conversationGoal = "";
    if(props.fullAiData.conversationGoal){
        let arrayForm = props.fullAiData.conversationGoal.split("\n");
        conversationGoal = arrayForm.join("<br/> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp");
    }

    let companyOverview = "";
    if(props.fullAiData.companyOverview){
        let arrayForm = props.fullAiData.companyOverview.split("\n");
        companyOverview = `<strong>Company Overview : </strong>`+arrayForm.join("<br/> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp");
    }
    
    const SquadDisplay = () => {

        if (props.fullAiData.squad_setting) {            
            return(
                <div className="row" style={{marginTop: 10}}>
                <div className="col-md-12">
                    <h4>Squad setting</h4>
                </div>    
                {props.fullAiData.squad_setting.length && props.fullAiData.squad_setting.map((squad) => (
                    <div className="col-md-5" style={{padding: 8, margin: 10, border: '1px solid #dee2e6',borderRadius: 8, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)'}}>
                        <h4><strong>Assistant name:</strong> {squad.assistantName}</h4>
                        <strong>Message:</strong> {squad.message} <br /> <br />
                        <strong>Description:</strong> {squad.description}
                    </div>
                ))}
                </div>
            )
        }else{
            return(
                <div></div>
            )
        }        
    }

    return (
        <>
            <div className={`${classes.posR}`}>
                <div
                className={`${classes.previewSection}`}
                dangerouslySetInnerHTML={{__html: conversationGoal}}
                />
                 <span 
                className={`${classes.editIconPreview} ${classes.editIconPreviewConversation}` }
                ><i class="la la-edit"
                title="Click to edit."
                onClick={(e) => props.sethandleConfusedClick(e,5)}
                ></i></span>
            </div>
                
           
                
            <div className={`${classes.previewSection}`} >   
            <h4>Information about the company is below:</h4>
                Company name : {props.fullAiData.companyInfo && props.fullAiData.companyInfo.companyName}<br/>
                Company phone number : {props.fullAiData.companyInfo && props.fullAiData.companyInfo.phoneNumber}<br/>
                Company website : {props.fullAiData.companyInfo && props.fullAiData.companyInfo.website}<br/>
                Maximum no. of response per word : {props.fullAiData.companyInfo && props.fullAiData.companyInfo.limit}<br/>

                <SquadDisplay />
                <span 
                className={`${classes.editIconPreview}`}
                ><i class="la la-edit"
                title="Click to edit."
                onClick={(e) => props.sethandleConfusedClick(e,1)}
                ></i></span>
            </div>

            <div className={`${classes.previewSection}`} 
            >
                <AboutUsRendering/>
                 <span 
                className={`${classes.editIconPreview}`}
                ><i class="la la-edit"
                title="Click to edit."
                onClick={(e) => props.sethandleConfusedClick(e,3)}
                ></i></span>

            </div>

            <div className={`${classes.posR}`}>
            <div
                className={`${classes.previewSection}`}
                dangerouslySetInnerHTML={{__html: companyOverview}}
                />
                 <span 
                className={`${classes.editIconPreview} ${classes.editIconPreviewConversation}` }
                ><i class="la la-edit"
                title="Click to edit."
                onClick={(e) => props.sethandleConfusedClick(e,2)}
                ></i></span>
            </div>

            <div className={`${classes.previewSection}`}>
                <strong>Context to exclude : </strong>
                <ExcludedServices/>
            </div>
            
            <div className={`${classes.previewSection}`}
            >
                <strong>FAQs : </strong>
                <FaqPreview/>
                <span 
                className={`${classes.editIconPreview}`}
                ><i class="la la-edit"
                title="Click to edit."
                onClick={(e) => props.sethandleConfusedClick(e,4)}
                ></i></span>
            </div>
        </>
    )
}