import React, { useState, useEffect } from 'react'
import MapContainer from '../MapContainer';
import ZipcodeInputField from './ZipcodeInputField';
import classes from "./AboutUs.module.css";
import CustomErrorField from '../../../../../Form/CustomErrorField';

const AboutUs = (props) => {
    const [aboutUsData, setAboutUsData] = useState([]);
    const [locationsCount, setLocationsCount] = useState(0)
    const [zipcodeFields, setZipcodeFields] = useState([])
    const [zipcodesData, setZipcodesData] = useState([])
    const [leadsWithinData, setLeadsWithinData] = useState([])
    useEffect(
        () => {
            setAboutUsData(props.aboutData);
        }, [props.aboutData]
    );
    useEffect(() => {
        console.log('zipcode', zipcodesData, props.zipCodeData);
        setZipcodesData(props.zipCodeData)
    }, [props.zipCodeData]);

    const handleAboutUs = (event, index) => {
        let temp_about = [...aboutUsData];
        temp_about[index] = (temp_about[index] && typeof temp_about[index] === 'string') ? JSON.parse(temp_about[index]) : temp_about[index];
        temp_about[index].a = event.target.value;
        setAboutUsData(temp_about);
        props.setValue(temp_about, 'AboutUs');
    }

    // handle skip Question
    const handleSkipQuestion = (index) => {
        let temp_about = [...aboutUsData];
        temp_about[index] = (temp_about[index] && typeof temp_about[index] === 'string') ? JSON.parse(temp_about[index]) : temp_about[index];
        if(temp_about[index].skip != undefined){
            temp_about[index].skip = !temp_about[index].skip;
        }else{
            temp_about[index]['skip'] = true;
        }
        setAboutUsData(temp_about);
        props.setValue(aboutUsData, 'AboutUs');
    }

    const renderAboutUs = () => {
        return aboutUsData.map((el, index) => {
            el = (el && typeof el === 'string') ? JSON.parse(el) : el;
            return (<div className='form-group'>
                <label className={`row ${classes.aboutus_lable}`}>
                    <div className='col-md-8'>
                        <span>{el.q}</span>
                    </div>
                    <div className='col-md-4 text-right'>
                        <button
                            className={`yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small ${classes.aboutus_skipbutton}`}
                        >
                            <span 
                            onClick={() => handleSkipQuestion(index) }
                            className={el.skip ? `${classes.skipbtn}` : ""}
                            >{el.skip ? "Add this question" : "Skip this question"}</span>
                        </button>
                    </div>
                </label>
                {/* <input className='form-control' type='text' defaultValue={el.a} value={el.a} onChange={(e) => handleAboutUs(e,index)}/> */}
                <textarea className={`form-group ${classes.inputTextare}`}
                    value={el.a}
                    onChange={(e) => handleAboutUs(e, index)} >
                </textarea>
            </div>)
        });
    }



    const addLocations = () => {
        const newZip = {
            'user_id': props.zipCodeData[0].user_id,
            'zipcode': '',
            'willing_travel': '',
            'branch_id': props.zipCodeData[0].branch_id,
            'noservice_branch_id': 0,
            'workspace_id': props.zipCodeData[0].workspace_id,
            'receive_chat_notification': 0,
            'lat': '',
            'lng' : ''
        }
        props.onAddLocation(newZip);
    }

    const zipcodeHandler = (e, index, type) => {
        // console.log(e.target.value)
        // console.log("index",index)
        console.log(index)
        let zipcode_rule = [...zipcodesData]

        if (type == '1') {

            let regexp = /^[0-9\b]+$/

            if (e.target.value === '' || regexp.test(e.target.value)) {
                zipcode_rule[index] = e.target.value
                console.log(zipcode_rule)
                setZipcodesData(zipcode_rule)
            }
        }

    }
    const sendLeadsWithinHandler = (leadsWithin, index) => {
        let leadsWithinArray = [...leadsWithinData];
        leadsWithinArray[index] = leadsWithin;
        setLeadsWithinData(leadsWithinArray)
    }

    return (
        <>
            <div className='row'>
                <div className='col-md-6'>
                    <div className="box-title">About Us</div>
                    {renderAboutUs()}
                </div>
                <div className='col-md-6'>
                    <div className="yr-chat-round-box">
                        <div className="box-title">Service Area Rules</div>
                        <div className="box-title">Add zip code rules</div>
                        {zipcodesData && zipcodesData.map((zip, i) => (
                            <div className='row'>
                                {i > 0 &&
                                    <div className="col-md-12">
                                        <button type="button" style={{ float: "right" }} title="Delete" className="btn btn-icon btn-sm cusrsorpointer" onClick={() => props.deleteLocations(i)}><i className="la la-trash red" /></button>
                                    </div>
                                }
                                <label className="col-md-4 pd10botbuilder">
                                    Zip Code
                                </label>
                                <div className={`col-md-8`}>
                                    <div className="form-group" style={{ marginBottom: 10 }}>
                                        <input
                                            type="text"
                                            className={props.zipcoderror.length > 0 && typeof props.zipcoderror[i] != 'undefined' && props.zipcoderror[i].hasOwnProperty('zipcode')
                                                ? "form-control is-invalid"
                                                : "form-control"
                                            }
                                            value={zip.zipcode}
                                            onChange={(e) => props.changeZipcode(e, i, 'zipcode')}
                                        />
                                        {props.zipcoderror.length > 0 && typeof props.zipcoderror[i] != 'undefined' && props.zipcoderror[i].hasOwnProperty('zipcode') && (
                                            <CustomErrorField
                                                message={props.zipcoderror[i].zipcode}
                                                className="error invalid-feedback"
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4 pd10botbuilder">
                                    Send Lead Within
                                </div>
                                <div className="col-md-8">
                                    <div className="form-group" style={{ marginBottom: 10 }}>
                                        <select
                                            className="form-control"
                                            value={zip.willing_travel}
                                            onChange={(e) => props.changeZipcode(e, i, 'willing_travel')}
                                        >
                                            <option value="">Select</option>
                                            {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map((x, y) => {
                                                return (
                                                    <option value={x}>{x} Miles</option>
                                                )
                                            })}

                                        </select>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {/* {zipcodeFields} */}
                        <a
                            className="add-gradient form-group cusrsorpointer add-link"
                            onClick={addLocations}
                        >
                            +Click to add locations
                        </a>
                        <div className="form-group row my-4">
                            <label className="col-md-12 col-form-label">Your service area</label>
                            <div className="col-md-12" style={{ MaxWidth: "300px", height: "400px" }}>
                                {zipcodesData  &&
                                    <MapContainer mapdata={zipcodesData} />
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 d-flex justify-content-end">
                                {/* <button
                                    type="reset"
                                    class="btn yr-cancelbtn linear-gradient-cancel mg-r"
                                    onClick={() => {this.props.popupClose()}}
                                >
                                    <span> Close </span>
                                </button> */}
                                {/* <button
                                    type="submit"
                                    className="btn linear-gradient yr-submitbtn"
                                    onClick={() => props.saveZipCodeRules()}
                                >
                                    Save
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutUs