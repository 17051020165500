import React from "react";
import api from "../../../../../../api";
import "../../../../../Grid/grid.css";
import "./dataTable.css";
import { Segment, Table } from "semantic-ui-react";
import { DatatableTable } from "./DatatableTable";
import { DatatableFilter } from "./DatatableFilter";
import DialogBoxDetail from "./DialogBoxDetail";
import DatatableDeleteBox from "./DatatableDeleteBox";
import TeamDeleteBox from "./TeamDeleteBox";
import DialogBoxAddEdit from "./DialogBoxAddEdit";
import { DatatableRow } from "./DatatableRow";
import { CSVLink, CSVDownload } from "react-csv";
import utilFunc from "../../../../../../util_funs";
import DialogContactDetail from "./DialogContactDetail";
import DialogBoxContactVia from "./DialogBoxContactVia";
import { Message } from "semantic-ui-react";
import DialogBoxDetailOnCSVUploadError from "./DialogBoxDetailOnCSVUploadError";

const tableHeader = [
  {
    title: "Date added",
    width: "0",
    sort: "updated_date",
  },
  {
    title: "Mass Action",
    width: "0",
    sort: "mass_action",
  },
  {
    title: "Team Member",
    width: "0",
    sort: "team_member",
  },
  {
    title: "Action",
    width: "0",
    sort: "action",
  },
  {
    title: "Email Subject",
    width: "0",
    sort: "email_subject",
  },

  {
    title: "Email",
    width: "0",
    sort: "email",
  },
  {
    title: "Full Name",
    width: "0",
  },
  {
    title: "Phone Number",
    width: "0",
  },
  {
    title: "Notes",
    width: "0",
    sort: "notes",
  },
];
const queryParams = ["_limit", "_order", "_sort", "q", "_page"];
const APIURL = "http://localhost/zyratalk/api/premium/settings/manageuser/";
export default class DatatableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      _sort: "added_datetime",
      _page: 1,
      _order: "desc",
      _limit: 10,
      q: "",
      team: "",
      totalCount: 0,
      loading: false,
      modalstatus: false,
      modalstatusDelete: false,
      modaltitle: null,
      modalbuttonmsg: {},
      modalType: null,
      datatableHeader: null,
      datatableRows: null,
      modalErrorMsg: false,
      modalstatusAddEdit: false,
      modalstatusConfirm: false,
      modaldata: {},
      modalFormLoader: false,
      selectedOptions: [],
      accessModuleList: [],
      notification_text: 0,
      notification_email: 0,
      duplicateamailError: "",
      duplicatePhoneError: "",
      duplicatePhoneErrorSms: "",
      renderOption1: [],
      renderOption2: [],
      renderOption3: [],
      renderOption4: [],
      country_code: "+1",
      country_code_sms: "+1",
      phone_number: "",
      sms_number: "",
      csvdata: [],
      src: null,
      crop: {
        unit: "%",
        width: 30,
        aspect: 1 / 1,
      },
      croppedImageUrl: null,
      profile_pic: null,
      croppedImage: null,
      DeletePic: 1,
      oldImage: 1,
      DeletePicAdd: 0,
      modaldetailstatus: false,
      modalContactVia: false,
      modalContactViaObject: {
        show: false,
        title: "Please select one of the below option",
        loading: false,
      },
      currentContactLabelData: [],
      userToaster: false,
      userToasterMsg: "",
      userToasterColor: "",
      modalstatusCSVfailure: false,
      csvFailArr: [],
      currentContactLabelDataOnEdit: [],
      currentContactLabelDetailDataOnEdit: [],
      calllist_id: "",
      mass_action: "",
      notesdata: "",
      tmnotesdata: "",
      emailcontent: "",
      yourteamdata: [],
    };
    this.csvLink = React.createRef();
  }

  componentDidMount() {
    this.loadData({});
    this.country_code();
  }

  componentDidUpdate(prevProps) {
    if (this.props.indextime != prevProps.indextime) {
      this.loadData({});
    }
  }

  handleFile = (e) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      this.setState({
        src: fileReader.result,
        oldImage: 0,
        DeletePicAdd: 1,
        DeletePic: 1,
      });
    };
    fileReader.readAsDataURL(e.target.files[0]);
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  handleRemovePic = (key) => {
    this.setState({ DeletePic: 0 });
  };
  handleRemovePicAdd = (key) => {
    this.setState({ DeletePicAdd: 0 });
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = this.getCroppedImg(this.imageRef, crop);
      this.setState({ croppedImageUrl });
    }
  };

  getCroppedImg(image, crop) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.dataURLtoFile(reader.result, "cropped.jpg");
      };
    });
  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    this.setState({ croppedImage: croppedImage });
  }

  country_code = (params) => {
    api.manageTeam.callCountryCode().then((data) => {
      try {
        this.setState({ renderOption1: data.data.defaultCountryCodeArr });
        this.setState({ renderOption2: data.data.remailCountryCodeArr });
        this.setState({ renderOption3: data.data.defaultCountryCodeArr });
        this.setState({ renderOption4: data.data.remailCountryCodeArr });
      } catch (err) {
        console.log(err);
      }
    });
  };

  static directionConverter(order) {
    if (order === "asc") {
      return "ascending";
    } else if (order === "desc") {
      return "descending";
    } else {
      return null;
    }
  }

  handleSort = (clickedColumn) => {
    const { _sort, _order } = this.state;

    let newOrder = _order === "asc" ? "desc" : "asc";
    if (_sort !== clickedColumn) {
      newOrder = "asc";
    }

    this.loadData({
      _sort: clickedColumn,
      _page: 1,
      _order: newOrder,
    });
  };

  onChangeLimit = (event, data) => {
    if (data.value !== this.state._limit) {
      this.loadData({ _limit: data.value, _page: 1 });
    }
  };

  onSubmitFilterDateReange = (parmas) => {
    this.loadData({
      chatfrequency: parmas["chatfrequency"],
      startdaterange: parmas["startdaterange"],
      enddaterange: parmas["enddaterange"],
      _page: 1,
    });
  };

  onSubmitFilter = (filter, val) => {
    if (filter !== this.state.q) {
      console.log(filter)
      console.log(val)
      this.loadData({ [filter]: val, _page: 1 });
    }
  };

  onChangePage = (event, data) => {
    const { activePage } = data;
    if (activePage !== this.state._page) {
      this.loadData({ _page: activePage });
    }
  };

  modalbuttofun = (data) => {
    if (data.modalType == "Delete") {
      this.onDeleteAction();
    }

    if (data.modalType == "ResendDeviceKey") {
      this.onResendDeviceKeyAction();
    }

    if (data.modalType == "Popup") {
      this.setState({
        modalstatus: false,
        modaltitle: "",
        modalmessage: "",
        modalbuttonmsg: {},
        modalType: "",
      });
    }
  };
  editContact = (id) => {
    let recordlist = this.state.lists.findIndex((list) => list.cid === id);
    let modaldata = this.state.lists[recordlist];
    console.log(modaldata);
    let editDateModule = [];
    let moduleList = this.state.accessModuleList;

    moduleList.forEach((item, index) => {
      Object.keys(item).forEach(function (key) {
        if (modaldata.linksetting.includes(key)) editDateModule.push(key);
      });
    });
    this.setState({
      country_code: modaldata.country_code,
    });

    this.setState({
      phone_number: modaldata.phone_number,
    });
    if (modaldata.notification_text == "1") {
      this.setState({
        notification_text: 1,
      });
    } else {
      this.setState({
        notification_text: 0,
      });
    }

    if (modaldata.notification_email == "1") {
      this.setState({
        notification_email: 1,
      });
    } else {
      this.setState({
        notification_email: 0,
      });
    }

    api.marketing
      .getContactLabelAndDataOnEdit({
        bid: this.props.bid,
        user_id: this.props.userdata.user_id,
        cid: id,
      })
      .then((data) => {
        this.setState({
          modalstatusAddEdit: true,
          modaltitle: "Edit Contact",
          modalType: "Edit",
          modaldata: modaldata,
          modaldata: { opt: "static", submitBtnName: "Update" },
          selectedOptions: editDateModule,
          currentContactLabelDataOnEdit: data.data.data.contact[0],
          currentContactLabelDetailDataOnEdit: data.data.data.contactDetail,
          phone_number: data.data.data.contact[0].phone,
        });
      });
  };

  deleteConfirm = (notes_id, n_team_id) => {
    //alert(notes_id);
    this.setState({
      modalstatusDelete: true,
      modaltitle: "Confirm your action",
      modalmessage: "Are you sure you want to delete?",
      modalbuttonmsg: { submit: "Yes", cancel: "No", call_notes_id: notes_id, n_team_id:n_team_id},
      modalType: "Delete",
    });
};
notesDel = ()=>{
  this.setState(
    {
      Delmag: "Notes has deleted successfully.",
    });setTimeout(
      function () {
      this.setState({ Delmag: false });
      }.bind(this),
      5000
      );
}
 OtherDetails = (id) => {
    this.setState({
      modalstatus: true,
      modaltitle: "This bucket is associated with 4 campaigns",
      modalmessage: "Are you sure you want to delete?",
      modalbuttonmsg: { submit: "Yes", cancel: "No" },
      modalType: "Details",
    });
  };

  ResendDeviceKey = (id) => {
    let recordlist = this.state.lists.findIndex((list) => list.id === id);
    let modaldata = this.state.lists[recordlist];
    this.setState({
      modalstatus: true,
      modaltitle: "Confirm your action",
      modalmessage: "Are you sure you want to send it again ?",
      modalbuttonmsg: { submit: "Yes", cancel: "No" },
      modalType: "ResendDeviceKey",
      modaldata: modaldata,
    });
  };

  onDeleteAction = () => {
    this.setState({ modalFormLoader: true });

    api.marketing
      .deleteContact({ cid: this.state.modaldata.cid, bid: this.props.bid })
      .then((data) => {
        this.setState({ modalFormLoader: false });
        try {
          let recordDeleted = this.state.lists.findIndex(
            (list) => list.cid === data.data.cid
          );

          let oldArr = this.state.lists;
          oldArr.splice(recordDeleted, 1);
          if (oldArr.length == 0) {
            var link = document.getElementById("backtocontats");
            link.click();
          } else {
            this.setState(
              {
                modalFormLoader: false,

                modalstatusDelete: false,
                modaltitle: "",
                modalmessage: "",
                modalbuttonmsg: {},
                modalType: "",
                modalErrorMsg: false,
              },
              function () {
                this.loadData({});
                this.props.loadData();
              }
            );
            this.props.showUserToaster(
              "green",
              "User has been deleted successfully."
            );
            utilFunc.scrollTop(800);
          }
        } catch (err) {
          console.log(err);
        }
      });
  };

  onResendDeviceKeyAction = () => {
    this.setState({ modalFormLoader: true });
    api.manageTeam
      .ResendDeviceKey({ id: this.state.modaldata.id })
      .then((data) => {
        this.setState({ modalFormLoader: false });
        try {
          this.setState({
            modalstatus: false,
            modaltitle: "",
            modalmessage: "",
            modalbuttonmsg: {},
            modalType: "",
            modalErrorMsg: false,
          });
          this.props.showUserToaster(
            "green",
            "Device key has been sent successfully."
          );
          utilFunc.scrollTop(800);
        } catch (err) {
          console.log(err);
        }
      });
  };
  preOpensetting = (id) => {
    let recordlist = this.state.lists.findIndex((list) => list.id === id);
    let access_selected = this.state.lists[recordlist].linksetting;
    let access_selected_lists_html = "";
    access_selected_lists_html = access_selected.map((item, value) => (
      <p
        key={item}
        style={{
          textTransform: "capitalize",
          color: "#6c7293",
          fontWeight: "400",
        }}
      >
        {item.replace(/_/g, " ")}
      </p>
    ));
    this.setState({
      modalstatus: true,
      modaltitle: "Setting/Privileges",
      modalmessage: access_selected_lists_html,
      modalbuttonmsg: { submit: "Ok", cancel: "" },
      modalType: "Popup",
    });
  };

  modalClose = (flag, fm) => {
    // document.getElementById("isd_code_id_review").selectedIndex = 0;
    // document.getElementById("isd_code_id_review_sms").selectedIndex = 0;
    this.setState({
      modalstatus: false,
      modalstatusAddEdit: false,
      modaltitle: "",
      modalmessage: "",
      modalbuttonmsg: {},
      modalType: "",
      modaldata: {},
      selectedOptions: [],
      duplicateamailError: "",
      duplicatePhoneError: "",
      currentContactLabelData: [],
      currentContactLabelDetailDataOnEdit: [],
    });
  };

  modalCloseDeleteBox = (flag, fm) => {
    this.setState({
      modalstatusteamDelete: false,
      modaltitle: "",
      modalmessage: "",
      modalbuttonmsg: {},
      modalType: "",
      modaldata: {},
    });
  };

  modalCloseNoteDeleteBox = (flag, fm) => {
    this.setState({
      modalstatusDelete: false,
      modaltitle: "",
      modalmessage: "",
      modalbuttonmsg: {},
      modalType: "",
      modaldata: {},
    });
  };
handleCheck = (key) => {
    const target = key.target;
    if (target.checked) {
      this.setState({
        [target.name]: 1,
      });
    } else {
      this.setState({
        [target.name]: 0,
      });
    }
  };

  handleDrop = (key) => {
    const target = key.target.value;
    this.setState({
      country_code: target,
    });
  };

  handleDropSms = (key) => {
    const target = key.target.value;
    this.setState({
      country_code_sms: target,
    });
  };

  checkEmail = (key) => {
    let sendData = {
      email: key.target.value,
      modalType: this.state.modalType,
      id: this.state.modaldata.id,
      bid: "1",
    };
    api.marketing.checkEmail(sendData).then((data) => {
      try {
        console.log(data);
        if (data.data > 0) {
          this.setState({
            duplicateamailError: "This email id is already exits",
          });
        } else {
          this.setState({
            duplicateamailError: "",
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  checkPhone = (key) => {
    let sendData = {
      phone_number: key.target.value,
      modalType: this.state.modalType,
      cid: this.state.modaldata.cid,
      bid: "1",
    };
    api.marketing.checkPhone(sendData).then((data) => {
      try {
        if (data.data > 0) {
          this.setState({
            duplicatePhoneError: "This phone number is already exits",
          });
        } else {
          this.setState({
            duplicatePhoneError: "",
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
    this.setState({
      phone_number: key.target.value,
    });
    this.phoneFormat(key);
  };
  checkPhoneSms = (key) => {
    let sendData = {
      sms_number: key.target.value,
      modalType: this.state.modalType,
      cid: this.state.modaldata.cid,
      bid: "1",
    };
    api.marketing.checkPhoneSms(sendData).then((data) => {
      try {
        if (data.data > 0) {
          this.setState({
            duplicatePhoneErrorSms: "This phone number is already exits",
          });
        } else {
          this.setState({
            duplicatePhoneErrorSms: "",
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
    this.setState({
      sms_number: key.target.value,
    });
    this.phoneFormatSms(key);
  };

  phoneFormat = (e) => {
    let phone = e.target.value;
    let p = phone;
    var obj = this;

    p = p.replace(/[^\d]*/gi, "");
    if (p.length < 3) {
      // setTimeout(function () {
      //obj.setState({ phone_number: p });
      // }, 100);
    } else if (p.length == 3) {
      let pp = p;
      let d4 = p.indexOf("(");
      let d5 = p.indexOf(")");
      if (d4 == -1) {
        pp = "(" + pp;
      }
      if (d5 == -1) {
        pp = pp + ")";
      }
      obj.setState({ phone_number: pp });
    } else if (p.length > 3 && p.length < 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;

      // setTimeout(function () {
      //obj.setState({ phone_number: pp });
      // }, 100);
    } else if (p.length >= 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;

      let l40 = pp.length;
      let p40 = pp.substring(0, 8);
      p40 = p40 + "-";

      let p41 = pp.substring(8, l40);
      let ppp = p40 + p41;
      let maxphonelength = 13;
      let finalPhone = ppp.substring(0, maxphonelength);
      //setTimeout(function () {
      //obj.setState({ phone_number: finalPhone });
      //}, 100);
    }
  };

  phoneFormatSms = (e) => {
    let phone = e.target.value;
    let p = phone;
    var obj = this;

    p = p.replace(/[^\d]*/gi, "");
    if (p.length < 3) {
      //setTimeout(function () {
      //obj.setState({ sms_number: p });
      //}, 100);
    } else if (p.length == 3) {
      let pp = p;
      let d4 = p.indexOf("(");
      let d5 = p.indexOf(")");
      if (d4 == -1) {
        pp = "(" + pp;
      }
      if (d5 == -1) {
        pp = pp + ")";
      }
      obj.setState({ sms_number: pp });
    } else if (p.length > 3 && p.length < 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;

      // setTimeout(function () {
      //obj.setState({ sms_number: pp });
      // }, 100);
    } else if (p.length >= 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;

      let l40 = pp.length;
      let p40 = pp.substring(0, 8);
      p40 = p40 + "-";

      let p41 = pp.substring(8, l40);
      let ppp = p40 + p41;
      let maxphonelength = 13;
      let finalPhone = ppp.substring(0, maxphonelength);
      // setTimeout(function () {
      // obj.setState({ sms_number: finalPhone });
      // }, 100);
    }
  };

  onValidSubmit = (sendData) => {
    if (this.state.modalType == "Add") {
      var $msg = "User has been added successfully.";
    } else {
      var $msg = "User has been updated successfully.";
    }
    this.loadData({});
    this.setState({
      modalstatusAddEdit: false,
      currentContactLabelData: [],
      currentContactLabelDetailDataOnEdit: [],
    });
    this.showUserToaster("green", $msg);
    utilFunc.scrollTop(800);
  };
  //@Rohit
  viewteam = (msg) => {
    //alert('View Team');
    this.veiwnotesteam();
    this.setState({
      modaltitle: msg,
      loading: false,
    });
    this.setState({ modaldetailstatus: true });
    setTimeout(
      function () {
        this.setState({ modaltitle: false });
      }.bind(this),
      5000
    );
  };
  //@Rohit
  teamDelete = (cltm_id) => {
    //alert(cltm_id);
    this.setState({
      modalstatusteamDelete: true,
      modaltitle: cltm_id,
      modalmessage: "Are you sure you want to send it again ?",
      modalbuttonmsg: { submit: "Yes", cancel: "No", cltm_id: cltm_id },
      modalType: "Delete",
    });
  };
  downloadCSV = (event, done) => {
    // let list = this.state.lists;
    // let selected_arr = [];
    // list.filter(function (key) {
    //   if (key["checked"] == true) {
    //     selected_arr.push(key["auto_id"]);
    //   }
    // });

    let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
    let time_zone = clientTimeZone;

    let params = {};
    params["_order"] = this.state._order;
    params["_sort"] = this.state._sort;
    params["bid"] = this.state.bid;
    params["q"] = this.state.q;
    params["team"] = this.state.team;
    params["chatfrequency"] = this.state.chatfrequency;
    params["strsearch"] = this.state.q;
    params["startdaterange"] = this.state.startdaterange;
    params["enddaterange"] = this.state.enddaterange;
    params["time_zone"] = time_zone;
    console.log(params);

    // this.setState({ loading: true });

    api.marketing.exportCallList(params).then((data) => {
      try {
        this.setState(
          {
            csvdata: data.data.data,
          },
          function () {
            setTimeout(
              function () {
                document.querySelector("#chatcsv").click();
                this.setState({ loading: false });
              }.bind(this),
              5000
            );
          }
        );
      } catch (err) {}
    });
  };

  loadData = (params) => {
    console.log(this.props);
    const newState = Object.assign({}, this.state, params, { loading: false });
    queryParams.forEach(function (element) {
      if (!(element in params)) {
        params[element] = newState[element];
      }
    });

    let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
    let time_zone = clientTimeZone;

      params["bid"] = this.props.bid;
      params["activetab"] = this.props.activetab;
      params["time_zone"] = time_zone;

    const esc = encodeURIComponent;
    const query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    let totalCountQuery = "";
    if (params.q !== "") {
      totalCountQuery = `q=${params.q}`;
    }

    this.setState(newState, () => {
      this.setState({ loading: true });
      api.marketing.yourCallList(query).then((data) => {
        console.log("filter data",data)
        try {
          let accessModuleStore = [];

          let datatableRowstemp;
          if (data.totalrecord) {
            datatableRowstemp = data.data.list.map((list, index) => (
              <DatatableRow
                key={index}
                list={list}
                userdata={this.props.userdata}
                teamData={data.teamData}
                yourNotes={this.yourNotes}
                showEmaildata={this.showEmaildata}
                saveMass={this.saveMass}
                updateTeam={this.updateTeam}
                test={this.test}
                ResendDeviceKey={this.ResendDeviceKey}
                preOpensetting={this.preOpensetting}
              />
            ));
          } else {
            datatableRowstemp = (
              <Table.Row>
                <Table.Cell colSpan={7} textAlign="center">
                  No record found.
                </Table.Cell>
              </Table.Row>
            );
          }
          this.setState(
            {
              datatableRows: datatableRowstemp,
              totalCount: data.totalrecord,
              lists: data.data.list,
              accessModuleList: accessModuleStore,
            },
            () => {
              this.getTeamMembers();
            }
          );
        } catch (err) {
          console.log(err);
        }
        const newState = Object.assign({}, this.state, params, {
          loading: false,
        });
        this.setState(newState);
      });
    });
  };

  //@Rohit
  yourNotes = (clid, n_team_id, notes_comment) => {

    //alert(n_team_id);
    document.getElementById("comment").value = '';
    let yourNotesdata = "";
    let notesdata = "";
    let yclid = "";
    let noteerrMasg = "";
    if( notes_comment == 0){
      noteerrMasg = 'This field is required';
    }else if (n_team_id == 'noteam'){
          noteerrMasg = 'Please select team member first then you will be able to submit the notes.';
    }else{
      noteerrMasg = '';
    }
    //alert(clid);
    document.getElementById("notes_call_list_id").value = clid;

    let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
    let time_zone = clientTimeZone;
   
    api.marketing.getyourNotes({ calllist_id: clid, call_list_n_tmid: n_team_id, time_zone:time_zone }).then((data) => {
      //console.log(data)
      try {
        yourNotesdata = data.data.list;
      } catch (err) {
        console.log(err);
      }
      let modalContactViaObject = {
        show: true,
        title: "Notes",
        loading: false,
      };
      this.setState({
        modalContactViaObject: modalContactViaObject,
        notesdata: yourNotesdata,
        yclid: clid,
        nerrmsg: noteerrMasg,
        notes_team_id: n_team_id,
        
      });
      console.log(notesdata);
    });   
  };  

  //@Rohit
  showEmaildata = (branch_id) => {
    let notesEmailcontent = "";
    let enotesdata = "";
    api.marketing
      .getNotesemailcontent({ branch_id: branch_id })
      .then((data) => {
        //console.log(data)
        try {
          notesEmailcontent = data.data.list[0].email_content;

          this.setState({
            modalstatus: true,
            emailcontent: notesEmailcontent,
          });
        } catch (err) {
          console.log(err);
        }
      });
  };
  //@Rohit

  //@Rohit
  saveMass = (calllist_id) => {
    var mass_action = document.getElementById(calllist_id).value;
    //alert(mass_action);
    // api.marketing.getContactDetails({'cid':id}).then((data) => {
    api.marketing
      .updateMass({ calllist_id: calllist_id, mass_action: mass_action })
      .then((data) => {
        console.log(data);
        try {
          //alert(data.data.list[0].notes_comment);
          this.loadData({});
          this.setState({
            responsemsg: "Mass action updated successfully.",
            loading: false,
          });
          setTimeout(
            function () {
            
             this.setState({ responsemsg: false });
            }.bind(this),
            5000
          );
          utilFunc.scrollTop(800);
        } catch (err) {
          console.log(err);
        }
      });
      
  };

  //@Rohit
  updateTeam = (calllist_id) => {
    var call_list_tm_id = document.getElementById(
      calllist_id + "_call_list_tm_id"
    ).value;
    //(call_list_tm_id);
    // api.marketing.getContactDetails({'cid':id}).then((data) => {
    api.marketing
      .updateCalllistteam({
        calllist_id: calllist_id,
        call_list_tm_id: call_list_tm_id,
      })
      .then((data) => {
        console.log(data);
        try {
          //alert(data.data.list[0].notes_comment);
          this.loadData({});
          this.setState({
            responsemsg: "Team member updated successfully.",
            loading: false,
          });
          setTimeout(
            function () {
              this.setState({ responsemsg: false });
            }.bind(this),
            5000
          );
        } catch (err) {
          console.log(err);
        }
      });
     
  };

  viewContactDetail = (id) => {
    //alert('Hiii jjjjj');
    this.setState({ loading: true });
    api.marketing.getContactDetails({ cid: id }).then((data) => {
      console.log(data);
      try {
        // this.setState({loading:false,detail_data:data.data});
        this.setState(
          {
            modaltitle: "Details",
            loading: false,
            detail_data: data.data,
          },
          function () {
            this.setState({ modaldetailstatus: true });
          }
        );
      } catch (err) {
        console.log(err);
      }
    });
  };

  modalCloseContactBox = (flag, fm) => {
    this.setState({
      modaldetailstatus: false,
      modaltitle: "",
      modalmessage: "",
      modalbuttonmsg: {},
      modalType: "",
      modaldata: {},
    });
  };

  modalCloseContactViaBox = () => {
    let modalContactViaObject = {
      show: false,
      title: "Please select one of the below option",
      loading: false,
    };
    this.setState({
      modalContactViaObject: modalContactViaObject,
    });
  };
  //@Rohit
  veiwnotesteam = () => {
    //salert('veiwnotesteam');
    let yourteamdata = "";
    api.marketing.getAllteammembar().then((data) => {
      //console.log(data)
      try {
        yourteamdata = data.data.list;
        this.setState({
          notesallteamdata: yourteamdata,
        });

        //console.log(yourteamdata);
      } catch (err) {
        console.log(err);
      }
    });
  };

  /** by hilal */
  openContactAddEdit = (opt) => {
    // alert('Hiii');
    this.setState({ loading: true });
    var submitBtnName = "Save";
    if (opt == "csv") var submitBtnName = "Submit";
    api.marketing
      .getContactLabel({
        bid: this.props.bid,
        user_id: this.props.userdata.user_id,
      })
      .then((data) => {
        this.modalCloseContactViaBox();
        this.setState({
          modalstatusAddEdit: true,
          modaltitle: "Add Contact",
          modalType: "Add",
          modaldata: { opt: opt, submitBtnName: submitBtnName },
          selectedOptions: [],
          currentContactLabelData: data.data,
          loading: false,
        });
        if (opt == "static")
          document.getElementById("AddEditContactFormModal").reset();
      });
  };
  showUserToaster = (color, msg) => {
    this.setState({
      userToaster: true,
      userToasterMsg: msg,
      userToasterColor: color,
    });
    //setTimeout(
    // function () {
    // this.setState({ userToaster: false });
    //}.bind(this),
    //5000
    //);
  };

  showCustomToaster = (color, msg) => {
    this.setState({
      userToaster: true,
      userToasterMsg: msg,
      userToasterColor: color,
    });
  };

  onValidSubmitCSV = (e) => {
    this.loadData({});

    this.setState({
      modalstatusAddEdit: false,
    });

    if (e.failStatus == false) {
      this.showUserToaster("green", "CSV User has been added successfully.");
      utilFunc.scrollTop(800);
    } else {
      this.setState({
        modalstatusCSVfailure: true,
        modaltitle: "CSV upload error",
        modalmessage: "Are you sure you want to delete?",
        modalbuttonmsg: { submit: "Yes", cancel: "No" },
        modalType: "Details",
        csvFailArr: e.failArray,
      });
    }
  };
  modalCloseCSVError = (e) => {
    this.setState({
      modalstatusCSVfailure: false,
    });
  };

  getTeamMembers = () => {
    api.marketing.getTeammembar().then((data) => {
      //console.log(data)
      try {
        //alert(data.data.list[0].tm_name);
        this.setState({
          loading: false,
          yourteamdata: data.data.listteam,
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  updateMessage = (mesaage) => {
    this.setState({
      responseaddteammsg: mesaage,
      loading: false,
    });
    setTimeout(
      function () {
        this.setState({ responseaddteammsg: false });
      }.bind(this),
      5000
    );
  };
  updateMessageErr = (mesaage1) => {
    this.setState({
      responsemsgErr: mesaage1,
    });
    setTimeout(
      function () {
        this.setState({ responsemsgErr: false });
      }.bind(this),
      5000
    );
  };

  render() {
    return (
      <Segment
        style={{ width: "100%", paddingRight: "20px", paddingLeft: "20px" }}
      >
        {this.state.loading && <div className="ui loading form"></div>}
        <DatatableFilter
          filter={this.state.q}
          totalCount={this.state.totalCount}
          onSubmitFilter={this.onSubmitFilter}
          getyourcallteam={this.getyourcallteam}
          loading={this.state.loading}
          csvdata={this.state.csvdata}
          onSubmitFilterDateReange={this.onSubmitFilterDateReange}
          viewteam={this.viewteam}
          teamDelete={this.teamDelete}
          modaladdteamstatus={this.state.modaladdteamstatus}
          downloadCSV={this.downloadCSV}
          yourteamdata={this.state.yourteamdata}
          indextime={new Date()}
        />
        <Message
          color="green"
          style={{
            display: this.state.responsemsg ? "block" : "none",
            height:"44px"
          }}
        >
          {this.state.responsemsg}
        </Message>
        <DatatableTable
          lists={this.state.lists}
          datatableHeader={tableHeader}
          datatableRows={this.state.datatableRows}
          totalCount={this.state.totalCount}
          totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
          currentPage={this.state._page}
          onChangePage={this.onChangePage}
          column={this.state._sort}
          direction={DatatableList.directionConverter(this.state._order)}
          handleSort={this.handleSort}
          onChangeLimit={this.onChangeLimit}
          yourNotes={this.yourNotes}
          limit={this.state._limit.toString()}
        />
        <DialogBoxDetail
          show={this.state.modalstatus}
          title={this.state.modaltitle}
          emailcontent={this.state.emailcontent}
          modalClose={this.modalClose}
          modalbuttofun={this.modalbuttofun}
          yourNotes={this.yourNotes}
          loading={this.state.loading}
        />
        <DatatableDeleteBox
          show={this.state.modalstatusDelete}
          title={this.state.modaltitle}
          message={this.state.modalmessage}
          modalType={this.state.modalType}
          modalCloseNoteDeleteBox={this.modalCloseNoteDeleteBox}
          buttonmsg={this.state.modalbuttonmsg}
          modalbuttofun={this.modalbuttofun}
          deleteConfirm={this.deleteConfirm}
          yourNotes={this.yourNotes}
          notesDel={this.notesDel}
          yclid={this.state.yclid}
          loading={this.state.loading}
        />
        <TeamDeleteBox
          show={this.state.modalstatusteamDelete}
          title={this.state.modaltitle}
          teamDelData={this.state.team}
          delMag={this.state.Delmag}
          message={this.state.modalmessage}
          modalType={this.state.modalType}
          modalCloseDeleteBox={this.modalCloseDeleteBox}
          buttonmsg={this.state.modalbuttonmsg}
          modalbuttofun={this.modalbuttofun}
          deleteConfirm={this.deleteConfirm}
          updateMessage={this.updateMessage}
          viewteam={this.viewteam}
          loading={this.state.loading}
        />
        <CSVLink
          id="chatcsv"
          data={this.state.csvdata}
          filename="call-list.csv"
          className="hidden"
          target="_blank"
        />
        <DialogBoxAddEdit obj={this.state} modalClose={this.modalClose} />

        <DialogContactDetail
          show={this.state.modaldetailstatus}
          title={this.state.modaltitle}
          modalClose={this.modalCloseContactBox}
          tmnotesdata={this.state.notesallteamdata}
          veiwnotesteam={this.veiwnotesteam}
          responsemsg={this.state.responsemsg}
          assigNotesteam={this.assigNotesteam}
          addNotesteam={this.addNotesteam}
          getyourcallteam={this.getyourcallteam}
          teamDelete={this.teamDelete}
          loading={this.state.loading}
          getTeamMembers={this.getTeamMembers}
          loadData={this.loadData}
          responsemsgdone={this.state.responseaddteammsg}
          viewteam={this.viewteam}
          msgErr={this.state.responsemsgErr}
          updateMessageErr={this.updateMessageErr}
          updateMessage={this.updateMessage}
          delMag={this.state.Delmag}
        />
        <DialogBoxContactVia
          obj={this.state}
          modalCloseContactViaBox={this.modalCloseContactViaBox}
          openContactAddEdit={this.openContactAddEdit}
          yourNotes={this.yourNotes}
          deleteConfirm={this.deleteConfirm}
          notesdata={this.state.notesdata}
          yclid={this.state.yclid}
          n_team_id={this.state.notes_team_id}
          Delmag={this.state.Delmag}
          nerrmsg={this.state.nerrmsg}
        />
        <DialogBoxDetailOnCSVUploadError
          obj={this.state}
          show={this.state.modalstatusCSVfailure}
          title={this.state.modaltitle}
          message={this.state.modalmessage}
          modalType={this.state.modalType}
          modalCloseCSVError={this.modalCloseCSVError}
          buttonmsg={this.state.modalbuttonmsg}
          modalbuttofun={this.modalbuttofun}
          loading={this.state.loading}
        />
      </Segment>
    );
  }
}
