	// ChildComponent.js
	import React,{useState,useContext,useEffect} from 'react';
	import { Modal, Button, Form, Input, Icon} from 'semantic-ui-react';
	import styles from "./DataConnections.module.css";
	import dataconnection from '../../../api/DataConnection/dataconnection'
	import { DataContext } from './DataProvider';
	const DataLayerModal = ({ open, onClose, onOpen, setDatabases,initFormdata}) => {
		const {data, updateData,connections, updateConeection  } = useContext(DataContext);
		const [formData, setFormData] = useState(initFormdata);
		// on update of coneections
		useEffect(() => {
			setFormData(initFormdata)
			console.log("Connections updated:", initFormdata);
		}, [connections,initFormdata]);

		// State to manage form data
		
		const handleInputChange = (e) => {
			const { name, value } = e.target;
			setFormData((prevData) => {
				const updatedData = { ...prevData, [name]: value };
				return updatedData;
			});
		};

		// Handle form reset
		const handleReset = () => {setFormData(initFormdata); };
		// Handle form submit
		const handleSubmit = () => {
			onClose();
			if(formData.id == ''){
				dataconnection.addConnection({...formData,event: "save"}).then( ( res ) => {
					setDatabases(res.data_list);
					updateConeection(res.data_list);
					handleReset();
				});
			}else{
				dataconnection.updateConnection(formData.id,{...formData,event: "save"}).then( ( res ) => {
					setDatabases(res.data);
					updateConeection(res.data);
					handleReset();
				});
			}
		};
		return (
				<Modal open={open} onClose={onClose} size="tiny" className={styles.customModal}>
				<Modal.Header className={styles.modalHeader}>
					<span>Databases</span>
					<Button
					icon
					className={styles.closeButton}
					onClick={onClose}
					>
					<Icon name="close" />
					</Button>
				</Modal.Header>
				<Modal.Content>
					<Form>
					<Form.Field>
						<label>Connection name :</label>
						<Input
						placeholder="Connection name"
						name="name"
						value={formData.name}
						onChange={handleInputChange}
						className={styles.inputField}
						/>
					</Form.Field>
					<Form.Field>
						<label>Database Hostname :</label>
						<Input
						placeholder="Database Hostname"
						name="host"
						value={formData.host}
						onChange={handleInputChange}
						className={styles.inputField}
						/>
					</Form.Field>
					<Form.Field>
						<label> Databse Username:</label>
						<Input
						type="text"
						placeholder="Database username"
						name="username"
						value={formData.username}
						onChange={handleInputChange}
						className={styles.inputField}
						/>
					</Form.Field>
					<Form.Field>
						<label>Databse Password:</label>
						<Input
						placeholder="Database password"
						name="password"
						value={formData.password}
						onChange={handleInputChange}
						className={styles.inputField}
						/>
					</Form.Field>
					<Form.Field>
						<label>Table Name:</label>
						<Input
						placeholder="Table Name"
						name="tablename"
						value={formData.tablename}
						onChange={handleInputChange}
						className={styles.inputField}
						/>
					</Form.Field>
					</Form>
				</Modal.Content>
				<Modal.Actions>
					<div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
						<Button color="blue" className="btn linear-gradient" style={{ marginRight: "10px" }}>
							Test coneection
						</Button>
						<Button color="blue" className="btn linear-gradient" onClick={() => handleSubmit()}>Save new database</Button>
					</div>
				</Modal.Actions>
				</Modal>
		);
	};

	export default DataLayerModal;
