import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Message } from "semantic-ui-react";
import './chatdashboard.css';
import ChatGraph from "./ChatGraph";
import ChatFilter from "./Chatfilter";
import { CSVLink, CSVDownload } from "react-csv";
import {
	setPageTitle,
	toggleNavigation,
	setMessage,
	setBreadCrum,
	toggleBreadcrum
} from "../../../store/Page";
import DateRangePicker from "react-bootstrap-daterangepicker";
import api from "../../../api";
import ButtonClick from "./ButtonClick";
import Top10Url from "./Top10Url";
import ButtonDetails from "./BtnDetails";
const queryString = require("query-string");




const today = new Date();

const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
const initialstartdate = `${oneMonthAgo.getMonth() + 1}/${oneMonthAgo.getDate()}/${oneMonthAgo.getFullYear()}`
const initialendtdate = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`

class ChatDashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			userToaster: false,
			userToasterMsg: "",
			userToasterColor: "",
			chatStats: null,
			isLoading: false,
			top10url: null,
			buttonClick: null,
			button_ids: null,
			chartData: null,
			filterstartdate: initialstartdate,
			filterenddate: initialendtdate,
			chatfrequency: "lastthirtydays",
			csvdata: [],
			showbtnDetails: false,
			btnId: ''
		};
	}
	componentDidMount() {
		const {
			setPageTitle,
			toggleNavigation,
			location,
			PageMessage,
			setMessage,
			setBreadCrum,
			toggleBreadcrum

		} = this.props;
		setPageTitle({ title: "Chat Dashboard", subHeaderTitle: 'Conversations' });
		toggleBreadcrum({ toggle: false });

		toggleNavigation({ toggle: true });
		setBreadCrum({ breadcrum: null });
		// const params = queryString.parse(location.search);

		// if (params && params.tab !== undefined) {
		// 	this.setState({
		// 		activetab: params.tab,
		// 	});
		// }
		this.loadData();
	}

	loadData = () => {
		this.setState({ isLoading: true });
		api.chatsetup.getStatistics({ filterstartdate: this.state.filterstartdate, filterenddate: this.state.filterenddate, frequency: this.state.chatfrequency })
			.then((res) => {
				// console.log("res", res);
				if (res.status) {
					let chartData = null;
					let newchrt = [];
					if (res.chatchartyaxis?.dataquantity?.seriespoint && Object.keys(res.chatchartyaxis?.dataquantity?.seriespoint).length === 0) {
						chartData = null;
					} else {
						chartData = [...Object.values(res.chatchartyaxis?.dataquantity?.seriespoint)];
					}
					// console.log('res chartdata', chartData);
					if (chartData) {
						newchrt = chartData.map((item) => (
							{
								'name': item.year,
								'Started': item.started,
								'Completed': item.completed,
								'Live Chat': item.livechat
							}
						));
					}


					this.setState({
						chatStats: res.chatStats,
						top10url: res.top10url,
						buttonClick: res.buttonClick,
						button_ids: res.buttonClickKeyIds,
						chartData: newchrt
					})
				}

			}).catch((err) => {

			}).finally(() => {
				this.setState({ isLoading: false });
			})
	}

	handleCallback = (start, end, label) => {
		this.setState({
			filterstartdate: start.format('MM/DD/YYYY'),
			filterenddate: end.format('MM/DD/YYYY'),
		}, () => {
			this.loadData();
		});
	}
	applyfilters = (val, filter) => {
		if (filter.rangepicker) {

			this.setState({
				filterstartdate: filter.daterange.start,
				filterenddate: filter.daterange.end,
				chatfrequency: val
			}, () => {
				this.loadData();
			});
		} else {
			this.setState({
				chatfrequency: val
			}, () => {
				this.loadData();
			});
		}

	}
	doownloadCSV = (val, filter) => {
		this.exportStat();
	}

	exportStat = () => {

		this.setState({ isLoading: true });
		api.chatsetup.exportstats({ filterstartdate: this.state.filterstartdate, filterenddate: this.state.filterenddate, frequency: this.state.chatfrequency }).then((res) => {
			// console.log(res);
			if (res.status) {
				this.setState(
					{
						csvdata: res.data,
					},
					function () {
						setTimeout(
							function () {
								document.querySelector("#chatcsv").click();
								this.setState({ isLoading: false });
							}.bind(this),
							1000
						);
					}
				)
			}
		}).catch((err) => {
			console.log(err);
		}).finally(() => {
			this.setState({ isLoading: false });
		})
	}
	ButtonModalClose = (e) => {
		this.setState({
			showbtnDetails: false,
			btnId: '',

		});
	};
	ButtonModalShow = (id, name) => {
		this.setState({
			showbtnDetails: true,
			btnId: { id: id, name: name },
		});
	};
	render() {
		// const { activetab } = this.state;
		return (
			<>
				{this.state.isLoading && <div className="ui loading form"></div>}
				<div className="row my-5">
					<div className="col-md-12">

						<ChatFilter
							filter={{
								chatfrequency: this.state.chatfrequency,
								startdaterange: this.state.startdaterange,
								enddaterange: this.state.enddaterange,
							}}

							onSubmitFilter={this.applyfilters}
							loading={this.state.loading}
							doownloadCSV={this.doownloadCSV}
						//   onSubmitFilterDateReange={this.onSubmitFilterDateReange}

						/>

					</div>
					{/* <div className="col-md-3 offset-md-4 text-right">
									<button onClick={this.exportStat} className="btn linear-gradient yr-submitbtn">Export CSV</button>
								</div> */}
				</div>
				<div className="row">
					<div className="col-md-6">
						<div className="row">
							<div className="col-md-12">
								<h2 className="mt-31">Chats</h2>
								<ul className="zy-chatStats">
									{this.state.chatStats &&
										<>
											<li>
												<label>Started</label>
												<h4>{this.state.chatStats.started}</h4>
											</li>
											<li>
												<label>Completed</label>
												<h4>{this.state.chatStats.completed}</h4>
											</li>
											<li>
												<label>Live Chat</label>
												<h4>{this.state.chatStats.livechat}</h4>
											</li>
										</>
									}
								</ul>
							</div>
							<div className="col-md-12" style={{ maxHeight: 300 }}>
								<h2 className="mt-31"><span>Chat</span> Statistics</h2>
								{this.state.chartData && <ChatGraph data={this.state.chartData} />}
							</div>
						</div>


					</div>
					<div className="col-md-5 offset-md-1">
						<div className="row">
							<div className="col-md-12">
								<h2><span>Most Popular Button</span> Clicks</h2>
								{this.state.buttonClick && <ButtonClick data={this.state.buttonClick} button_ids={this.state.button_ids} modalclose={this.ButtonModalClose} showModal={this.ButtonModalShow} />}
							</div></div>
						<div className="row">
							<div className="col-md-12">
								<h2 className="mt-31"><span>Top 10 URLs getting the most</span> Chats</h2>
								<ul className="popularUrlList">
									{this.state.top10url && <Top10Url data={this.state.top10url} />}
								</ul>
							</div>
						</div>
					</div>
				</div>
				<CSVLink
					id="chatcsv"
					data={this.state.csvdata}
					filename="stat.csv"
					className="hidden"
					target="_blank"
				/>
				<ButtonDetails
					obj={this.state}
					soundSettingModalClose={this.ButtonModalClose}

				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		PageMessage: state.page.message,
	};
};
export default connect(mapStateToProps, {
	setPageTitle,
	toggleNavigation,
	setMessage,
	setBreadCrum,
	toggleBreadcrum
})(ChatDashboard);
