import axios from "../../config/axios";
import config from "../../config/index";
const dataconnection = {
    addConnection: (params) =>
        axios
            .post(`${config.apiBaseURL2}database-connection`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),
    getConnectionList: (params) =>
        axios
            .get(`${config.apiBaseURL2}database-list`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),
    updateConnection: (id,params) =>
        axios
            .post(`${config.apiBaseURL2}database-update/${id}`,params )
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),
    deleteConnection: (params) =>
        axios
            .post(`${config.apiBaseURL2}database-delete/${params}`)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),
    testConnection: (params) =>
        axios
            .post(`/dataconnection/DataConnection/testConnection`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),
    getConnectionDetails: (params) =>
        axios
            .post(`/dataconnection/DataConnection/getConnectionDetails`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),
};

export default dataconnection;
