import React from 'react';


import {
    Form, Label, Input, TextArea, Checkbox, Radio, RadioGroup, Dropdown, Select,
} from 'formsy-semantic-ui-react';

import ChatHours from './ChatHours';
import Validator from "validator";
import CustomErrorField from "../../../Form/CustomErrorField";
import api from "../../../../api";
import Modal from './Modal';
import WorkHours from "./WorkHours";

class PageRulesHours extends React.Component {
    // const PageRulesHours = () => {
    constructor(props) {
        super(props);
        this.state = {
            inputList: [],
            errors: null,
            loading: false,
            modalstatus: false,
            modaltitle: null,
            modalbody: {},
            modalaction: null,
            modaldata:{},
            AfterQuestion:null,
        };
    }

    componentDidMount() {
        api.chatsetup.getBotHideShowList().then(data => {
            // console.log(data);
            try {
                let list=[
                    { auto_id: "", entity: '', entity_text: '', entity_type: 'show', after_url: '',after_question_mark:'' }
                ]
                if(data.data.list.length>0){
                    list=data.data.list
                }
                this.setState({
                    inputList: list,
                });
            } catch (err) {
                console.log(err);
            }
        });
    }

    cancelData =() =>{
    
        this.setState({ 
            inputList: [],
            errors: null,
            loading: false,
            modalstatus: false,
            modaltitle: null,
            modalbody: {},
            modalaction: null,
            modaldata:{},
        });
        api.chatsetup.getBotHideShowList().then(data => {
            // console.log(data);
            try {
                let list=[
                    { auto_id: "", entity: '', entity_text: '', entity_type: 'show', after_url: '' }
                ]
                if(data.data.list.length>0){
                    list=data.data.list
                }
                this.setState({
                    inputList: list,
                });
            } catch (err) {
                console.log(err);
            }
        });
    }

    addMore = () => {
        this.setState({
            inputList: [...this.state.inputList, { auto_id: "", entity: '', entity_text: '', entity_type: 'show', after_url: '',after_question_mark:'' }],
        });

    }

    deleteUrl = (index) => {

        this.setState({
            modalstatus: true,
            modaltitle: 'Confirm your action',
            modalbody: {},
            modalType: null,
            modaldata: {
                id:index
            },
          });
       
    }
    confirmDelete=(id)=>{
        this.setState({ loading : true });
        const list = this.state.inputList;
                list.splice(id, 1);
                this.setState({
                    inputList: list,
                    loading:false,
                    modalstatus:false

                });
    }
    

    saveData = () => {
        const errors = this.validateForm(this.state.inputList);
        this.setState({ errors });
        if (Object.keys(errors).length > 0) {
            return false;
        }

        this.setState({ loading: true });

        api.chatsetup.saveHideShowData(this.state.inputList).then(data => {
            console.log(data);
            try {
                this.setState({ loading: false });
                this.props.showUserToaster('teal', data.data.message);
            } catch (err) {
                console.log(err);
            }
        });

    }

    handleChange = (key, e) => {
        let list = this.state.inputList;
        list[key].entity_text = e.target.value;
        this.setState({
            inputList: list,
        });
    }

    handleRadio = (type, key) => {
        let list = this.state.inputList;
        list[key].entity_type = type;
        this.setState({
            inputList: list,
        });
    }


    handleCheckQ = (key) => {
        console.log(key)
        let list = this.state.inputList;
        list[key].after_question_mark = list[key].after_question_mark==0 ? 1 : 0;
        this.setState({
            inputList: list,
        });
    }

    handleCheck = (key,e) => {
        console.log(key)
        let list = this.state.inputList;
        list[key].after_url = list[key].after_url==0 ? 1 : 0;
        this.setState({
            inputList: list,
        });
        if(e.target.checked === false){
            list[key].after_question_mark = 1;
            this.handleCheckQ(key)
        }
        else{
            this.setState({
                AfterQuestion:null
            })
        }
    }

    validateForm = (list) => {
        const errors = {};
        list.map((x, y) => {
            if (!x.entity_text) {
                errors[y] = "This field is required";
            } else if (!Validator.isURL(x.entity_text)) {
                errors[y] = "Please enter valid url";
            }
            else if (x.entity_text.substr(-1) == '/') {
                errors[y] = "Please remove slash(/) at the last of string";
            }
            else {
                var len = list.filter(item => item.entity_text == x.entity_text).length;
                if (len > 1) {
                    errors[y] = "You can not enter duplicate URL";
                }
            }
        });

        return errors;
    };
   
    modalClose=()=>{
        this.setState({
            modalstatus: false,
        });
    }
    modalBody=()=>{
        return "Are you sure you want to delete?";
    }
    modalFooter = (id) => {
        return (
            <div className="modal-footer">
                <button type="button" onClick={() => this.modalClose()} className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small linear-gradient-cancel" data-dismiss="modal">
                    <span>No</span>
                </button>
                <button type="button" onClick={() => this.confirmDelete(this.state.modaldata.id)} className="btn linear-gradient yr-submitbtn btn-sm">
                <span>Yes</span>
							</button>
            </div>
        )
    }
    


    render() {
        const { inputList, errors, loading } = this.state
       
        return (
            <React.Fragment>
                <Form ref={forms => this.forms = forms} id="formdata">
                {loading && <div className="ui loading form"></div>}
                    <div className="yr-createTicket chatbox-padd" style={{ marginTop: 0 }}>
                        <div className="box-title">Show/Hide bot</div>
                        <div id="kt_repeater_1">
                            <div className="form-group  row mb-0" id="kt_repeater_1">
                                <div className="col-lg-12">
                                    <div className="form-group row align-items-center">
                                        <div className="col-lg-8 col-md-8 ">
                                            <div className="kt-form__group--inline">
                                                <div className="kt-form__label mb-2">
                                                    <label>Specify URL<span className="required">*</span></label>
                                                </div>

                                            </div>
                                            <div className="d-md-none kt-margin-b-10" />
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <div className="kt-radio-inline d-flex justify-content-end">
                                                <div className="column-label mx-3">
                                                    <label>Show</label>

                                                </div>
                                                <div className="column-label mx-3">
                                                    <label>Hide</label>

                                                </div>
                                                <div className="column-label mx-3">
                                                    <label style={{ whiteSpace: 'nowrap' }}>After ( / )</label>

                                                </div>
                                                <div className="column-label mx-3">
                                                    <label style={{ whiteSpace: 'nowrap' }}>After (?)</label>

                                                </div>
                                                <div className="column-label mx-3">
                                                    <label>Action</label>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { inputList && inputList.map((x, y) => {                       
                                    return (
                                        <div className="col-lg-12" key={y}>
                                            <div className="form-group row align-items-center">
                                                <div className="col-lg-8 col-md-8 ">
                                                    <div className="kt-form__group--inline">

                                                        <input
                                                        style={{width:"95%"}}
                                                            autoComplete="off"
                                                            value={x.entity_text}
                                                            name={"url[" + y + "][entity_text]"}
                                                            onChange={(e) => this.handleChange(y, e)}
                                                            className={
                                                                errors && errors[y]
                                                                    ? "form-control is-invalid"
                                                                    : "form-control"
                                                            }
                                                        />
                                                        {errors && errors.hasOwnProperty(y) && (
                                                            <CustomErrorField
                                                                message={errors[y]}
                                                                id="help-error"
                                                                className="error invalid-feedback"
                                                            />
                                                        )}


                                                    </div>
                                                    <div className="d-md-none kt-margin-b-10" />
                                                </div>
                                                <div className="col-lg-4 col-md-4" style={{marginLeft:"-22px"}}>
                                                    <div className="kt-radio-inline d-flex justify-content-end hourrules" style={{marginTop:"-0.4rem"}}>
                                                        <div className="column-label mx-3">
                                                            <label className="kt-radio inalign">
                                                                <input type="radio" name={"url[" + y + "]['entity_type']"} onClick={(e) => this.handleRadio('show', y)} defaultChecked={x.entity_type == 'show' ? true : false} />

                                                                <span />
                                                            </label>
                                                        </div>
                                                        <div className="column-label mx-3">

                                                            <label className="kt-radio inalign">
                                                                <input type="radio" name={"url[" + y + "]['entity_type']"} onClick={(e) => this.handleRadio('hide', y)} defaultChecked={x.entity_type == 'hide' ? true : false} />
                                                                <span />
                                                            </label>
                                                        </div>
                                                        <div className="column-label mx-3">
                                                            <label className="kt-checkbox chat-checkbox" >
                                                                <input type="checkbox" name={"url[" + y + "]['after_url']"} onChange={(e) => this.handleCheck(y,e)} defaultChecked={x.after_url == '1' ? true : false} />
                                                                <span />
                                                            </label>
                                                        </div>
                                                        <div className="" style={{marginLeft:"35px"}}>
                                                            <label className="kt-checkbox chat-checkbox" >
                                                                <input type="checkbox" name={"url[" + y + "]['after_question_mark']"} onChange={(e) => this.handleCheckQ(y)} defaultChecked={x.after_question_mark == '1' ? true : false} disabled={x.after_url == '1' ? false : true} checked={x.after_question_mark == '1' ? true : false}/>
                                                                <span />
                                                            </label>
                                                        </div>
                                                        <div className="column-label mx-3">
                                                            <div data-repeater-delete className="btn linear-gradient zy-delete-btn" style={{ marginTop: '8px',marginRight:'-35px', marginLeft:'30px' }} onClick={() => this.deleteUrl(y)}>
                                                                Delete
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>);
                                })}
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-4 ml-2">
                                    <div data-repeater-create className="btn linear-gradient zy-save-btn" onClick={() => this.addMore()} style={{cursor: "pointer"}}>
                                        <span>
                                            <i className="la la-plus" />
                                            <span >Add New Field</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Form>
                <div className="clearfixd" />
                {(this.props.feature!="freemium" || this.props.user_signup_from!="self" ) && <WorkHours></WorkHours>}
                <div className="clearfixd" />
                {(this.props.feature!="freemium" || this.props.user_signup_from!="self" ) &&  <ChatHours></ChatHours> }
                  <Modal
                   show={this.state.modalstatus}
                   footer={this.modalFooter()}
                   onclose={this.modalClose}
                   title={this.state.modaltitle}
                   body={this.modalBody()}
                  />   
            </React.Fragment>
        )
    }
}


  
export default PageRulesHours;
