import React, { useState } from 'react'
import { Message } from "semantic-ui-react";
import CustomErrorField from '../../../../Form/CustomErrorField';

const OpenAndCloseHour = (props) => {
    const [errors, setErrors] = useState([]);
    const [holidayerrors, setholidayerrors] = useState([]);
    const [showHideHoliday, setShowHideHoliday] = useState(false);
    
    const handleOnOff = (index, type) => {
        props.handleOnOff(index, type);
    }

    const handleTimeChange = (index, type, e) => {
        props.handleTimeChange(index, type, e);
    }
    const handleHolidayTimeChange = (index,type,e) => {
        props.handleHolidayTimeChange(index,type,e);
    }
    const  getTimeDifference = (from, to)  =>{
        let diffInMinutes= 0;
        if( from && to ){
            // Parse the time strings into Date objects
            const fromDate = new Date(`2024-06-26 ${from}`);
            const toDate = new Date(`2024-06-26 ${to}`);
            // Check if 'to' time is the next day (if 'from' is PM and 'to' is AM)
            
            // Calculate the time difference in minutes
            diffInMinutes = Math.floor((toDate.getTime() - fromDate.getTime()) / (1000 * 60));
            if(from == "9:00 AM"){ console.log(console.log("diff",from, to ,fromDate.getTime(),toDate.getTime(),diffInMinutes))}
        }
        
        return diffInMinutes;
    }

    const hourdisablecheck = (index, type, value) => {
        if(typeof props.savedcompanyHolidayList.hasOwnProperty(index) &&  props.savedcompanyHolidayList[index] ){
            if(type == 'open_hour_end' && props.savedcompanyHolidayList[index].hasOwnProperty('open_hour_start')){
                if( getTimeDifference(props.savedcompanyHolidayList[index].open_hour_start,value) <= 0){
                    return true;
                }
            }
        }
        return false;
    }
    const hideStr = () => {return <div><i className="fa fa-plus"></i>Hide holiday's hours</div>};
    return (
        <div className='row'>
            <div className='col-md-8'>
                <div className='row' style={{ margin: 'auto' }}>
                    <div className="box-title" style={{ margin: 'auto' }}>Open Hours</div>
                </div>
                <div className='row'>
                    <div className="form-group row px-4 mb-0 yr-scrll-x">
                        {props.list && props.list.map((item, index) => {
                            return (
                                <ul className="yr-time-ShowHide" key={index}>
                                    <li style={index === 0 ? { marginTop: 24 } : {}}>
                                        <label className="label-color">{item.day}</label>
                                    </li>
                                    <li>
                                        <div className="form-group" style={{ textAlign: 'center' }}>
                                            {index === 0 && <label className="drop-label">Start</label>}
                                            <span className="custom-arrow">
                                                <select
                                                    className={
                                                        errors &&
                                                            errors.length > 0 &&
                                                            errors.hasOwnProperty(index) &&
                                                            errors[index].open_hour_start
                                                            ? "form-control is-invalid mat-style"
                                                            : "form-control mat-style"
                                                    }
                                                    name=""
                                                    value={item.open_hour_start}
                                                    onChange={(e) =>
                                                        handleTimeChange(index, "open_hour_start", e)
                                                    }
                                                    disabled={
                                                        item.working || item.closed
                                                            ? true
                                                            : null
                                                    }
                                                >
                                                    <option value="">Select</option>
                                                    {Object.keys(props.hours).map(function (
                                                        key,
                                                        index
                                                    ) {
                                                        return (
                                                            <option key={index} value={key}>
                                                                {props.hours[key]}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                                {errors &&
                                                    errors.length > 0 &&
                                                    errors.hasOwnProperty(index) &&
                                                    errors[index].openerror && (
                                                        <CustomErrorField
                                                            message="This field is required"
                                                            id="help-error"
                                                            className="error invalid-feedback"
                                                        />
                                                    )}
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="form-group" style={{ textAlign: 'center' }}>
                                            {index === 0 && <label className="drop-label">End</label>}
                                            <span className="custom-arrow">
                                                <select
                                                    className={
                                                        errors &&
                                                            errors.length > 0 &&
                                                            errors.hasOwnProperty(index) &&
                                                            (errors[index].open_hour_end ||
                                                                errors[index].timediff)
                                                            ? "form-control is-invalid mat-style"
                                                            : "form-control mat-style"
                                                    }
                                                    name=""
                                                    value={item.open_hour_end}
                                                    onChange={(e) =>
                                                        handleTimeChange(index, "open_hour_end", e)
                                                    }
                                                    disabled={
                                                        (item.working || item.closed)
                                                            ? true
                                                            : null
                                                    }
                                                >
                                                    <option value="" >Select</option>
                                                    {typeof props.hours != "undefined" &&
                                                        Object.keys(props.hours).map(function (
                                                            key,
                                                            index
                                                        ) {
                                                            return (
                                                                <option 
                                                                    disabled={item.hasOwnProperty('open_hour_start_index') && index <= item.open_hour_start_index}
                                                                    key={index} 
                                                                    value={key}>
                                                                    {props.hours[key]}
                                                                </option>
                                                            );
                                                        })}

                                                    <option value="11:59 PM">11:59 PM</option>
                                                </select>
                                                {errors &&
                                                    errors.length > 0 &&
                                                    errors.hasOwnProperty(index) &&
                                                    (errors[index].closeerror ||
                                                        errors[index].timediff) && (
                                                        <CustomErrorField
                                                            message={
                                                                errors[index].timediff
                                                                    ? "Closed time will be greater than open time"
                                                                    : "This field is required"
                                                            }
                                                            id="help-error"
                                                            className="error invalid-feedback"
                                                        />
                                                    )}
                                            </span>
                                        </div>
                                    </li>
                                    <li style={index === 0 ? { marginTop: 24 } : {}}>
                                        <label className="kt-checkbox chat-checkbox" style={{fontSize: 13, fontWeight: 'inherit', paddingLeft: 25}}>
                                            <input
                                                type="checkbox"
                                                name="hide"
                                                checked={item["closed"] ? true : false}
                                                onChange={() => handleOnOff(index, "closed")}
                                            />
                                            Closed
                                            <span />
                                        </label>
                                    </li>
                                    <li style={index === 0 ? { marginTop: 24 } : {}}>
                                        <label className="kt-checkbox chat-checkbox" style={{fontSize: 13, fontWeight: 'inherit', paddingLeft: 25}}>
                                            <input
                                                type="checkbox"
                                                name="show"
                                                checked={item["working"] ? true : false}
                                                onChange={() =>
                                                    handleOnOff(index, "working")
                                                }
                                            />
                                            Available all day
                                            <span />
                                        </label>
                                    </li>
                                </ul>
                            )
                        })}
                    </div>
                </div>
                {/* hliday rendering */}               
                <div className='row' style={{ marginTop: '24px' }}>
                    <div className="box-title px-4 mb-0"  
                    style={{cursor:"pointer"}}
                    onClick={() => setShowHideHoliday(!showHideHoliday)}>{ showHideHoliday ? 
                    <div><i className="fa fa-minus"></i> Hide holiday's hours</div> : 
                    <div><i className="fa fa-plus"></i> Hide holiday's hours</div> 
                }
                    </div>
                    {
                    showHideHoliday && 
                        <div className="form-group row px-4 mb-0 yr-scrll-x">
                            {props.holidayList && props.holidayList.map((item, indexkey) => {
                                let index = item.id;
                                return (
                                    <ul className="yr-time-ShowHide" key={index}>
                                        <li>
                                            {indexkey === 0 && <label className="drop-label"></label>}
                                            <label className="label-color">{item.event}</label>
                                        </li>
                                        <li>
                                            {indexkey === 0 && <label className="drop-label">Date</label>}
                                            <label className="">({item.data})</label>
                                        </li>
                                        <li>
                                            <div className="form-group" style={{ textAlign: 'center' }}>
                                                {indexkey === 0 && <label className="drop-label">Start</label>}
                                                <span className="custom-arrow">
                                                    <select
                                                        className={
                                                            holidayerrors &&
                                                            holidayerrors.length > 0 &&
                                                            holidayerrors.hasOwnProperty(indexkey) &&
                                                            holidayerrors[indexkey].open_hour_start
                                                                ? "form-control is-invalid mat-style"
                                                                : "form-control mat-style"
                                                        }
                                                        name=""
                                                        onChange={(event) => handleHolidayTimeChange(indexkey,"open_hour_start",event)}
                                                        value={props.savedcompanyHolidayList.hasOwnProperty(indexkey) && props.savedcompanyHolidayList[indexkey]?.open_hour_start}
                                                    >
                                                        <option value="">Select</option>
                                                        {Object.keys(props.hours).map(function (
                                                            key,
                                                            index
                                                        ) {
                                                            return (
                                                                <option key={index} disabled={hourdisablecheck(indexkey,"open_hour_start",props.hours[key])}>
                                                                    {props.hours[key]}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    {holidayerrors &&
                                                        holidayerrors.length > 0 &&
                                                        holidayerrors.hasOwnProperty(indexkey) &&
                                                        holidayerrors[indexkey].openerror && (
                                                            <CustomErrorField
                                                                message="This field is required"
                                                                id="help-error"
                                                                className="error invalid-feedback"
                                                            />
                                                        )}
                                                </span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-group" style={{ textAlign: 'center' }}>
                                                {indexkey === 0 && <label className="drop-label">End</label>}
                                                <span className="custom-arrow">
                                                    <select
                                                        className={
                                                            errors &&
                                                            holidayerrors.length > 0 &&
                                                            holidayerrors.hasOwnProperty(indexkey) &&
                                                            holidayerrors[indexkey].open_hour_start
                                                                ? "form-control is-invalid mat-style"
                                                                : "form-control mat-style"
                                                        }
                                                        name=""
                                                        onChange={(event) => handleHolidayTimeChange(indexkey,"open_hour_end",event)}
                                                        value={props.savedcompanyHolidayList.hasOwnProperty(indexkey) && props.savedcompanyHolidayList[indexkey]?.open_hour_end}
                                                    >
                                                        <option value="">Select</option>
                                                        {Object.keys(props.hours).map(function (
                                                            key,
                                                            index
                                                        ) {
                                                            return (
                                                                <option key={index} disabled={hourdisablecheck(indexkey,"open_hour_end",props.hours[key])}>
                                                                    {props.hours[key]}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    {errors &&
                                                        holidayerrors.length > 0 &&
                                                        holidayerrors.hasOwnProperty(indexkey) &&
                                                        holidayerrors[indexkey].openerror && (
                                                            <CustomErrorField
                                                                message="This field is required"
                                                                id="help-error"
                                                                className="error invalid-feedback"
                                                            />
                                                        )}
                                                </span>
                                            </div>
                                        </li>
                                        <li style={indexkey === 0 ? { marginTop: 24 } : {}}>
                                            {indexkey === 0 && <label className="drop-label"></label>}
                                            <label className="kt-checkbox chat-checkbox" style={{fontSize: 13, fontWeight: 'inherit', paddingLeft: 25}}>
                                                <input
                                                    type="checkbox"
                                                    name="hide"
                                                    checked={props.savedcompanyHolidayList.hasOwnProperty(indexkey) ? props.savedcompanyHolidayList[indexkey]?.closed == "on" ? true : false : false}
                                                    onChange={(event) => handleHolidayTimeChange(indexkey, "closed",event)}
                                                />
                                                Closed
                                                <span />
                                            </label>
                                        </li>
                                        <li style={indexkey === 0 ? { marginTop: 24 } : {}}>
                                            {indexkey === 0 && <label className="drop-label"></label>}
                                            <label className="kt-checkbox chat-checkbox" style={{fontSize: 13, fontWeight: 'inherit', paddingLeft: 25}}>
                                                <input
                                                    type="checkbox"
                                                    name="show"
                                                    checked={props.savedcompanyHolidayList.hasOwnProperty(indexkey) ? props.savedcompanyHolidayList[indexkey]?.working == "on" ? true : false : false}
                                                    onChange={(event) => handleHolidayTimeChange(indexkey, "working",event)}
                                                />
                                                Available all day
                                                <span />
                                            </label>
                                        </li>
                                    </ul>
                                )
                            })}
                        </div>
                    } 
                </div>
            </div>
        </div>
    )
}

export default OpenAndCloseHour