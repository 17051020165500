import React from "react";
import { Color } from "react-input-color";
import "../../../../Modal/dialog.css";
import CustomErrorField from "../../../../Form/CustomErrorField";
const DialogBoxDetailAddSegment = ({ show, user_id, err_segment, err_segment1,segMentRule, segMentRuleOption_dataValue, segMentRuleOption_dataValue2, segMentRuleOption_dataVariables, datatype, segMentrulelist, getSegBucketlist, modalCloseAddsegment, changeRule, changeRulename,updateSegmentData, addSegmentData, sgr_id, name_rule, check1, check2, changecheck1, changecheck2, loading, addMore, removeSegoptin, addMoreUpdate, validateSegmentRule, errors }) => {
    const segMentruleOption = segMentrulelist;
    const getSegBucket = getSegBucketlist;
    const segMentRulelist = segMentRule;
    const segMentRuleOption_dataValuelist = segMentRuleOption_dataValue;
    const segMentRuleOption_dataValuelist2 = segMentRuleOption_dataValue2;
    const segMentRule_Variableslist = segMentRuleOption_dataVariables;

    //const showrule = 0;

    return (
        <React.Fragment>
            <div
                className={show ? "modal fade yr-upgrade-popup white-bg new-model-popup show" : "modal fade yr-upgrade-popup white-bg new-model-popup"}
                data-backdrop="static"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="staticBackdrop"
                aria-hidden="true"
                style={{ display: show ? "block" : "none" }}
            >
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: 800 }}>
                    {loading && <div className="ui loading form"></div>}
                    <div className="modal-content white-bg yr-campaigns-model">
                        <button
                            type="button"
                            className="close linear-gradient"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => modalCloseAddsegment(false, '')}
                        >
                            X
                        </button>
                        {datatype != 2 ? (
                            <div className="modal-header border-bottom-0" style={{ paddingBottom: 0 }}>
                                {sgr_id == '' ? (
                                    <h5 className="modal-title pl-0 pt-0">Add Segmenting Rule</h5>
                                ) : (
                                    <h5 className="modal-title pl-0 pt-0">Update Segmenting Rule</h5>
                                )
                                }
                            </div>) : (<div className="modal-header border-bottom-0" style={{ paddingBottom: 0 }}>
                                <h5 className="modal-title pl-0 pt-0">View Segmenting Rule</h5>
                            </div>)
                        }
                        <div className="modal-body new-modal-body pb-4 px-4">

                            <div class="d-flex align-items-end mb-1">
                                <label className="small text-dark" style={{ minWidth: 85, marginBottom: 0, fontWeight: 500 }}>Name your rule:</label>
                                <input type="text" name="ruleName" style={{ height: 'auto', paddingLeft: 2 }} value={name_rule} className="form-control border-top-0 border-right-0 border-left-0 radius-0" onChange={(e) => changeRulename(e)} />
                            </div>
                            <br/>
                           
                            <div class="d-flex align-items-end mb-4">
                                <label style={{ minWidth: 85 }}></label>
                                <div className="err--red">{err_segment}</div>

                            </div>
                            <div class="d-flex align-items-center mb-4">
                                {check1 == 'checked' ? (
                                    <input
                                        type="checkbox"
                                        className="mr-3"
                                        value="checked"
                                        checked={check1}
                                        onChange={(e) => changecheck1(e)}
                                    />) : (<input
                                        type="checkbox"
                                        className="mr-3"
                                        value="checked"
                                        onChange={(e) => changecheck1(e)}
                                    />)}
                                <label className="small text-dark" style={{ marginBottom: 0, fontWeight: 500 }}>When a contact is added, allow them to be subscribed to this rule if they have already been placed in another rule.</label>

                            </div>

                            <div class="d-flex align-items-center mb-4">
                                {check2 == 'checked' ? (
                                    <input
                                        type="checkbox"
                                        className="mr-3"
                                        value="checked"
                                        checked={check2}
                                        onChange={(e) => changecheck2(e)}
                                    />) : (<input
                                        type="checkbox"
                                        className="mr-3"
                                        value="checked"
                                        onChange={(e) => changecheck2(e)}
                                    />)}
                                <label className="small text-dark" style={{ marginBottom: 0, fontWeight: 500 }}>Exclude contacts from being added if they are detected as a duplicate and exist in other rules already.</label>
                            </div>

                            <div className="rules--container">
                                {/* <div className="row border-bottom pb-5 mb-3">
                                        <div className="col-sm-4">
                                            <select name=""  className="no--select__bg form-control custom-select no--input__allowed" disabled>
                                                    <option value="Email">Email</option>
                                            </select>
                                        </div>
                                        <div className="col-sm-4">
                                            <select name=""  className="no--select__bg form-control custom-select no--input__allowed" disabled>
                                                <option value="present">Present</option>
                                            </select>
                                        </div>
                                        <div className="col-sm-4">
                                            <select name=""  className="no--select__bg form-control custom-select no--input__allowed" disabled>
                                                    <option value="all">All</option>
                                            </select>    
                                        </div>
                                    </div> */}
                                   
                                {err_segment1!=="" &&   <div className="ui red message del-msg-padding">{err_segment1}</div>}
                                {segMentrulelist && Object.keys(segMentruleOption).map(function (key) {
                                    //showrule = 0;
                                    return (
                                        <div>

                                            <div className="row data--row alert--data__row">
                                                <div className="col-sm-4">

                                                    <label>Choose your variable</label>

                                                    <select name="variableSelect"
                                                        className={
                                                            Object.keys(errors).length > 0 && typeof errors['variable_name'] != 'undefined' && typeof errors['variable_name'][key] != 'undefined'
                                                                ? "form-control is-invalid focusError custom-select"
                                                                : "form-control custom-select"
                                                        }

                                                        value={segMentruleOption[key].variable_name} onChange={(e) => changeRule(e, key, 'variable')}>
                                                        <option value="0">click to select</option>

                                                        {segMentRuleOption_dataVariables && Object.keys(segMentRule_Variableslist).map(function (key) {
                                                            return (
                                                                <option value={segMentRule_Variableslist[key].keyv_option}>{segMentRule_Variableslist[key].valuev_option}</option>
                                                            )
                                                        })}
                                                    </select>
                                                    {Object.keys(errors).length > 0 && typeof errors['variable_name'] != 'undefined' && typeof errors['variable_name'][key] != 'undefined' && (
                                                        <CustomErrorField
                                                            message={errors['variable_name'][key]}
                                                            className="error invalid-feedback"
                                                        />
                                                    )}


                                                </div>
                                                <div className="col-sm-4">
                                                    <label>Set a rule</label>
                                                    {segMentruleOption[key].variable_name + '_' + key == 'amount_spent_' + key || segMentruleOption[key].variable_name + '_' + key == 'year_home_was_built_' + key || segMentruleOption[key].variable_name + '_' + key == 'pending_estimate_' + key || segMentruleOption[key].variable_name + '_' + key == 'amount_' + key ? (
                                                        <div>
                                                            <select name="ruleSelect[]" id={"rule_data_" + key}
                                                                className={
                                                                    Object.keys(errors).length > 0 && typeof errors['rule_name'] != 'undefined' && typeof errors['rule_name'][key] != 'undefined'
                                                                        ? "form-control is-invalid focusError custom-select"
                                                                        : "form-control custom-select"
                                                                }
                                                                value={segMentruleOption[key].rule_name} onChange={(e) => changeRule(e, key, 'rule')}>
                                                                <option value="0">click to select</option>
                                                                {segMentRuleOption_dataValue2 && Object.keys(segMentRuleOption_dataValuelist2).map(function (key) {
                                                                    return (
                                                                        <option value={segMentRuleOption_dataValuelist2[key].key_option}>{segMentRuleOption_dataValuelist2[key].value_option}</option>
                                                                    )
                                                                })}

                                                            </select>
                                                            {Object.keys(errors).length > 0 && typeof errors['rule_name'] != 'undefined' && typeof errors['rule_name'][key] != 'undefined' && (
                                                                <CustomErrorField
                                                                    message={errors['rule_name'][key]}
                                                                    className="error invalid-feedback"
                                                                />
                                                            )}
                                                            <input type="number"
                                                                name="remark_value[]"
                                                                value={segMentruleOption[key].remark_value}
                                                                style={{ height: 'auto', paddingLeft: 2 }}
                                                                onChange={(e) => changeRule(e, key, 'remark_value')}
                                                                className={
                                                                    Object.keys(errors).length > 0 && typeof errors['remark_value'] != 'undefined' && typeof errors['remark_value'][key] != 'undefined'
                                                                        ? "form-control border-top-0 border-right-0 border-left-0 radius-0 is-invalid focusError"
                                                                        : "form-control border-top-0 border-right-0 border-left-0 radius-0"
                                                                }
                                                            />

                                                            {Object.keys(errors).length > 0 && typeof errors['remark_value'] != 'undefined' && typeof errors['remark_value'][key] != 'undefined' && (
                                                                <CustomErrorField
                                                                    message={errors['remark_value'][key]}
                                                                    className="error invalid-feedback"
                                                                />
                                                            )}
                                                        </div>

                                                    ) : (
                                                        // present customer_type, type_Of_Job

                                                        <div>

                                                            {segMentruleOption[key].variable_name + '_' + key == 'customer_type_' + key || segMentruleOption[key].variable_name + '_' + key == 'type_Of_Job_' + key? (
                                                                <div>
                                                                    <select name="ruleSelect[]" id={"rule_data_" + key}
                                                                        className={
                                                                            Object.keys(errors).length > 0 && typeof errors['rule_name'] != 'undefined' && typeof errors['rule_name'][key] != 'undefined'
                                                                                ? "form-control is-invalid focusError custom-select"
                                                                                : "form-control custom-select"
                                                                        }


                                                                        value={segMentruleOption[key].rule_name} onChange={(e) => changeRule(e, key, 'rule')}>
                                                                        <option value="0">click to select</option>

                                                                        {segMentRuleOption_dataValue && Object.keys(segMentRuleOption_dataValuelist).map(function (key) {
                                                                            return (
                                                                                <option value={segMentRuleOption_dataValuelist[key].key_option}>{segMentRuleOption_dataValuelist[key].value_option}</option>
                                                                            )
                                                                        })}

                                                                    </select>
                                                                    {segMentruleOption[key].rule_name == 'present' ? (<input type="text"
                                                                        name="remark_value[]"
                                                                        value={segMentruleOption[key].remark_value == 0 ? '' : segMentruleOption[key].remark_value}
                                                                        style={{ height: 'auto', paddingLeft: 2 }}
                                                                        onChange={(e) => changeRule(e, key, 'remark_value')}
                                                                        className="form-control border-top-0 border-right-0 border-left-0 radius-0"
                                                                    />) : ('')

                                                                    }


                                                                </div>

                                                            ) : (<select name="ruleSelect[]" id={"rule_data_" + key}
                                                                className={
                                                                    Object.keys(errors).length > 0 && typeof errors['rule_name'] != 'undefined' && typeof errors['rule_name'][key] != 'undefined'
                                                                        ? "form-control is-invalid focusError custom-select"
                                                                        : "form-control custom-select"
                                                                }


                                                                value={segMentruleOption[key].rule_name} onChange={(e) => changeRule(e, key, 'rule')}>
                                                                <option value="0">click to select</option>

                                                                {segMentRuleOption_dataValue && Object.keys(segMentRuleOption_dataValuelist).map(function (key) {
                                                                    return (
                                                                        <option value={segMentRuleOption_dataValuelist[key].key_option}>{segMentRuleOption_dataValuelist[key].value_option}</option>
                                                                    )
                                                                })}
                                                            </select>


                                                            )}
                                                        </div>




                                                    )}
                                                    {Object.keys(errors).length > 0 && typeof errors['rule_name'] != 'undefined' && typeof errors['rule_name'][key] != 'undefined' && (
                                                        <CustomErrorField
                                                            message={errors['rule_name'][key]}
                                                            className="error invalid-feedback"
                                                        />
                                                    )}



                                                </div>
                                                <div className="col-sm-4">


                                                    <label>Add data into bucket</label>


                                                    <select name="bucketSelect[]"
                                                        className={
                                                            Object.keys(errors).length > 0 && typeof errors['bucket_id'] != 'undefined' && typeof errors['bucket_id'][key] != 'undefined'
                                                                ? "form-control is-invalid focusError custom-select"
                                                                : "form-control custom-select"
                                                        }

                                                        value={segMentruleOption[key].bucket_id} onChange={(e) => changeRule(e, key, 'bucket')}>
                                                        <option value="0">click to select</option>
                                                        {getSegBucketlist && Object.keys(getSegBucket).map(function (key) {
                                                            return (
                                                                <option value={getSegBucket[key].bid}>{getSegBucket[key].title}</option>
                                                            )
                                                        })}
                                                    </select>

                                                    {Object.keys(errors).length > 0 && typeof errors['bucket_id'] != 'undefined' && typeof errors['bucket_id'][key] != 'undefined' && (
                                                        <CustomErrorField
                                                            message={errors['bucket_id'][key]}
                                                            className="error invalid-feedback"
                                                        />
                                                    )}


                                                </div>
                                                {datatype != 2 ? (
                                                    <a key={key} className="cursorpointer alert--row__delete"
                                                        onClick={() => removeSegoptin(key)}
                                                        style={{ color: 'rgb(8, 122, 182)', fontSize: 16 }}
                                                        title="Remove"
                                                    >
                                                        <i class="la la-trash"></i>
                                                    </a>) : ( <a key={key} className="cursorpointer alert--row__delete"
                                                        onClick={() => removeSegoptin(key)}
                                                        style={{ color: 'rgb(8, 122, 182)', fontSize: 16 }}
                                                        title="Remove"
                                                    >
                                                        <i class="la la-trash"></i>
                                                    </a>)
                                                }
                                            </div></div>)
                                    })}
                            </div>
                              {datatype != 2 ? (
                                <div className="col-md-12 px-0">
                                    {sgr_id != '' ? (
                                        <button className="px-0 text-dark border-0 bg-transparent" style={{marginLeft: -6}} onClick={() => addMoreUpdate(sgr_id, user_id)}><svg style={{transform: 'scale(.7) translate(5px, -2px)'}} width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm.5 10h6v1h-6v6h-1v-6h-6v-1h6v-6h1v6z"/></svg> Add more rule</button>
                                    ) : (
                                        <button className="px-0 text-dark border-0 bg-transparent" style={{marginLeft: -6}} onClick={() => addMore(user_id)}><svg style={{transform: 'scale(.7) translate(5px, -2px)'}} width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm.5 10h6v1h-6v6h-1v-6h-6v-1h6v-6h1v6z"/></svg> Add more rule</button>
                                    )
                                    }

                                    <div className="md-footer d-flex justify-content-end align-items-center mt-5">
                                        <button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" data-dismiss="modal">
                                            <span style={{ paddingTop: 10, paddingBottom: 10 }} onClick={() => modalCloseAddsegment(false, '')}>Cancel</span></button>
                                        {sgr_id == '' ? (
                                            <button type="button" className="ml-2 btn linear-gradient yr-submitbtn btn-sm" onClick={() => validateSegmentRule('Add')}>Save</button>
                                        ) : (
                                            <button type="button" className="ml-2 btn linear-gradient yr-submitbtn btn-sm" onClick={() => validateSegmentRule('Update')}>Update</button>
                                        )
                                        }
                                    </div>
                                </div>) : (
                                            <div className="col-md-12"> 
                                            <div className="md-footer d-flex justify-content-end align-items-center mt-5">
                                            <button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" data-dismiss="modal">
                                            <span style={{ paddingTop: 10, paddingBottom: 10 }} onClick={() => modalCloseAddsegment(false, '')}>Cancel</span></button>
                                                    <button type="button" className="ml-2 btn linear-gradient yr-submitbtn btn-sm" onClick={() => validateSegmentRule('Update')}>Update</button>
                                                </div>
                                            </div>
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
};

export default DialogBoxDetailAddSegment;