import React, { useState, useContext } from "react";
import { DataProvider } from '../../../../../../../components/Integration/DataConnections/DataProvider';
import DataConnectPage from './DataConnectPage'

const DataConnect = () => {
  
  return (
	<DataProvider>
		<DataConnectPage />
	</DataProvider>
  );
};

export default DataConnect;
