import React, { useState, useContext } from "react";
import DataLayerModal from './DataLayerModal'; // Ensure this file exists
import { DataContext } from '../../../../../../../components/Integration/DataConnections/DataProvider';
import {
    Message,
  Button,
  Table,
  Header,
  Divider,
} from "semantic-ui-react";

const DataConnectPage = () => {
  
  const [modalStatus, setModalStatus] = useState(false);
  const {data, updateData,connections, updateConeection  } = useContext(DataContext);

  return (
    <>
      <div style={{ margin: "0 auto", maxWidth: "90%" }}>
        <Header as="h3" dividing>
          Manage Data Connections
        </Header>

        <Message negative>
          <Message.Header>Error!</Message.Header>
          <p>There was an issue processing your request. Please try again.</p>
        </Message>
        <Message positive>
          <Message.Header>Succes!</Message.Header>
          <p>There was an issue processing your request. Please try again.</p>
        </Message>

        {/* Data Connections Table */}
        <Table celled compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Data Connection Name</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {connections.map((conn) => (
              <Table.Row key={conn.id}>
                <Table.Cell>{conn.name}</Table.Cell>
                <Table.Cell>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Button
                      size="small"
                      onClick={() => {
                        setModalStatus(!modalStatus);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      size="small"
                      color="red"
                      onClick={() => alert(`Deleting ${conn.name}`)}
                    >
                      Delete
                    </Button>
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Button
          color="blue"
          className="btn linear-gradient add-data-btn"
          onClick={() => {
            setModalStatus(!modalStatus);
          }}
        >
          Add New Data Connection
        </Button>
        <Divider />
      </div>
      {modalStatus && (
        <DataLayerModal
          open={modalStatus}
          onClose={() => setModalStatus(false)}
          onOpen={() => setModalStatus(true)}
        />
      )}
    </>
  );
};

export default DataConnectPage;
