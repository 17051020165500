import React, { useCallback } from "react";
import Design from './Design';
import Rules from './Rules';
import Preview from './Preview';
import api from "../../../../api";

import config from "../../../../config";
import util from "../../../../util";
import utilFunc from "../../../../util_funs";
import sanitizeHtml from "sanitize-html";

var ititialCpLogo  = '';
var ititialCustomLogo  = '';
class DesignRules extends React.Component {
 
    constructor(props) {
        super(props);
        this.state = {
          loading: true,
          customizeinfo: [],
          errors: {},
          cplogo:'',
          customlogo:'',
          newcplogo:'',
          newcustomlogo:'',
          removeImg:''

        };
      }
    
    componentDidMount() {
        console.log("init",ititialCustomLogo);
       this.loadData();
    }

    cancelData =() =>{
        ititialCpLogo  = '';
        ititialCustomLogo  = '';
        this.setState({ 
          loading: true,
          customizeinfo: [],
          errors: {},
          cplogo:'',
          customlogo:''
        });
        this.componentDidMount();
    }

    loadData = () => {
    api.customize.customizeinfo().then(data => {
        try {  
                ititialCpLogo = data.data.companyLogo;
                ititialCustomLogo = data.data.customizeLogo;
                if (data.data.text_style_web) {
                    const aa = JSON.parse(data.data.text_style_web);
                    data.data.text_style_web = aa[0];
                }
                if (data.data.text_style_mobile) {
                    const aa = JSON.parse(data.data.text_style_mobile);
                    data.data.text_style_mobile = aa[0];
                }
            this.setState({
                loading: false,
                customizeinfo: data.data,
                newcustomlogo:data.data.customizeLogo
            });
              
        } catch (err) {
            console.log(err);
        }
        });
    };
 

    changeTextStyle = (e, textType, styleType) => {
        let data = this.state.customizeinfo;
        data[textType] = { ...data[textType], [styleType]: styleType === 'fontSize' ? parseInt(e.target.value) : e.target.value }
        this.setState({
            customizeinfo: data,
        });
    }

    removeTags = (str) => {
        console.log(str);
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        // Regular expression to identify HTML tags in 
        // the input string. Replacing the identified 
        // HTML tag with a null string.
        return str.replace(/(<([^>]+)>)/ig, '').trim();
    }

    saveData = () => { 
        var obj = this;
     
        this.validateForm(this.state.customizeinfo, function(errors){
            console.log(errors);
            obj.setState({ errors });
            if(Object.keys(errors).length==0){ 
            
                obj.setState({ loading: true });
                console.log(obj.state.customizeinfo.chatPopupSize)
                var myform = document.getElementById("customizeFormId");
                var formData = new FormData(myform);
                const webStyle = obj.state.customizeinfo.text_style_web && typeof obj.state.customizeinfo.text_style_web == 'object' ? JSON.stringify(obj.state.customizeinfo.text_style_web) : '';
                const mobStyle = obj.state.customizeinfo.text_style_mobile && typeof obj.state.customizeinfo.text_style_mobile == 'object' ? JSON.stringify(obj.state.customizeinfo.text_style_mobile) : '';
                formData.append('buttonHeaderColor', obj.state.customizeinfo.buttonHeaderColor);
                formData.append('buttonColor', obj.state.customizeinfo.buttonColor);
                formData.append('buttonTextColor', obj.state.customizeinfo.buttonTextColor);
                formData.append('primaryColor', obj.state.customizeinfo.primaryColor);
                formData.append('secondaryColor', obj.state.customizeinfo.secondaryColor);
                formData.append('responseBoxColor', obj.state.customizeinfo.responseBoxColor);
                formData.append('responseTextColor', obj.state.customizeinfo.responseTextColor);
                formData.append('selectedTheme', obj.state.customizeinfo.selectedTheme);
                formData.append('text_style_web', webStyle);
                formData.append('text_style_mobile', mobStyle)
                formData.append('letsTalkPopup', obj.state.customizeinfo.letsTalkPopup);
                formData.append('previous_chat', obj.state.customizeinfo.previous_chat);
                formData.append('chatPopupSize', obj.state.customizeinfo.chatPopupSize);
                formData.append('isEmoji', obj.state.customizeinfo.isEmoji);
                formData.append('chatPopupSizeMobile', obj.state.customizeinfo.chatPopupSizeMobile);
                formData.append('gptIncompletePopup', obj.state.customizeinfo.gptIncompletePopup);
                formData.set('oldCustomizeImgname', obj.state.customlogo);
                formData.set('removeImg', obj.state.removeImg);
                console.log(obj.state.customizeinfo.chatPopupSize)
                const tokenInfo = util.getAuthTokens();
                var xhr = new XMLHttpRequest();
                xhr.open("POST", config.apiBaseURL + "/chatsetup/designrules/edit", true);
                xhr.setRequestHeader(config.access_token_name, tokenInfo.access_token);
                xhr.setRequestHeader(config.refresh_token_name, tokenInfo.refresh_token);
                xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    obj.setState({ loading: false });
                    const resp = JSON.parse(xhr.response);
                    if (resp.status) {
                        let new_chat_log=resp?.chat_log;
                        let new_customize_logo=resp?.customize_logo;
                        obj.setState({newcustomlogo:new_customize_logo,newcplogo:new_chat_log,removeImg:''});
                        obj.props.showUserToaster('green', 'Data saved successfully.');
                        utilFunc.scrollTop(800);
                    }else{
                        obj.props.showUserToaster('red', resp.data);
                        utilFunc.scrollTop(800);
                        obj.setState({removeImg:''});
                    }
                }
                };
                xhr.send(formData);
            }else{
                //let scrolltop=document.querySelector(".focusError").offsetTop - document.body.scrollTop;
                //window.scroll({top: scrolltop, left: 0, behavior: 'smooth' });
            }

        }); 
        
    }

    validateForm = (data,callback) => { 
        const errors = {}; 
        const webString = this.removeTags(this.state.customizeinfo.lets_talk_web);
        const mobileString = this.removeTags(this.state.customizeinfo.lets_talk_mobile);
        // alert(webString);
        // alert(mobileString);
        if (this.state.customizeinfo.letsTalkPopup === 'ON') {
            
            if (this.state.customizeinfo.is_different_lets_talk == 1 && mobileString.length>0) {
                console.log(mobileString.length);
                if (mobileString.length > 40) {
                    let errList = this.state.errors;
                    errList['lets_talk_mobile'] = "Maximum 40 characters are allowed.";
                    errors['lets_talk_mobile'] = "Maximum 40 characters are allowed.";
                    this.setState({ errors: errList });
                }else if (mobileString.length < 6) {
                    let errList = this.state.errors;
                    errList['lets_talk_mobile'] = "Minimum 6 characters are required.";
                    errors['lets_talk_mobile'] = "Minimum 6 characters are required.";
                    this.setState({ errors: errList });
                }
            }
            if(webString.length>0){
                if (webString.length > 40) {
                    let errList = this.state.errors;
                    errList['lets_talk_web'] = "Maximum 40 characters are allowed.";
                    errors['lets_talk_web'] = "Maximum 40 characters are allowed.";
                    this.setState({ errors: errList });
                }else if (webString.length < 6) {
                    let errList = this.state.errors;
                    errList['lets_talk_web'] = "Minimum 6 characters are required.";
                    errors['lets_talk_web'] = "Minimum 6 characters are required.";
                    this.setState({ errors: errList });
                }
            }
            
            
            
        }

        let cinfo = this.state.customizeinfo;
        if (cinfo.customizedHeight > 120) {
            errors['customizedHeight'] = "Height cannot exceed 120px";
        }

        if (cinfo.customizedWidth > 120) {
            errors['customizedWidth'] = "Width cannot exceed 120px";
        }
        
        if (data.company == '') {
            errors['company'] = "This field is required.";
        } 
        if (data.openMessage=='') {
            errors['openMessage'] = "This field is required.";
        }  
        // if (data.lets_talk_text=='') {
        //     errors['lets_talk_text'] = "This field is required.";
        // }  
        
        // data.phoneList.map((x, y) => {
        //     if(data.phoneList.length > 1 ){
        //         if(x.text=='' && y==0){
        //             errors["text[" + y + "]"] = "This field is required.";
        //         }else{
        //             if(x.phone==''){
        //                 errors["phonelist[" + y + "]"] = "This field is required.";
        //             }
        //             if (x.phone.trim() != '' && x.phone.match(/\d/g).length != 10) {
        //                 errors["phonelist[" + y + "]"] = "Please Enter Valid Number.";
        //             }
        //             if(x.text==''){
        //                 errors["text[" + y + "]"] = "This field is required.";
        //             }
        //             var ct = this.countInArray(data.phoneList, x.text.trim().toLowerCase());
        //             if (ct > 1 && x.text != '') {
        //                 errors["text[" + y + "]"] = "Location can not be duplicate."; 
        //             }
        //         }
        //     }
        //     if(data.phoneList.length==y+1)
        //     
        // }) 
        callback(errors);
        return errors;
    };

    countInArray(array, what) {
        var count = 0;
        for (var i = 0; i < array.length; i++) {
            if (array[i].text.trim().toLowerCase() === what) {
                count++;
            }
        }
        return count;
    }

    onchangeUpdatedesign = (val) =>{
        let msg = this.state.customizeinfo;
        msg.openMessage = val;
        this.setState({
            customizeinfo: msg
        });
    }

    onchangeUpdateColor = (e,name) => {
        console.log(name,"names")  
        let newStatus = this.state.customizeinfo; 
        let nameArr = [];
        if(name=='buttonTextColor'){
            newStatus.buttonTextColor = e.hex;    
                this.setState({
                    customizeinfo: newStatus,
            });
        }
        if(name=='primaryColor'){
            newStatus.primaryColor = e.hex;    
                this.setState({
                    customizeinfo: newStatus,
            });
        }
        if(name=='secondaryColor'){
            newStatus.secondaryColor = e.hex;    
                this.setState({
                    customizeinfo: newStatus,
            });
        }
        if(name=='responseBoxColor'){
            newStatus.responseBoxColor = e.hex;    
                this.setState({
                    customizeinfo: newStatus,
            });
        }
        if(name=='responseTextColor'){
            newStatus.responseTextColor = e.hex;    
                this.setState({
                    customizeinfo: newStatus,
            });
        }
        
        if(name.includes("buttonHeaderColor")){
            nameArr = name.split("_");
            newStatus.buttonHeaderColor[nameArr[1]] = e.hex;   
            this.setState({
                    customizeinfo: newStatus,
            });
        }
        if(name.includes("buttonColor")){
            nameArr = name.split("_");
            newStatus.buttonColor[nameArr[1]] = e.hex;     
                this.setState({
                    customizeinfo: newStatus,
            });
        }
      } 

    deleteMoreColor = (key,type) => {
        if(type=='header'){
            let oldArr = this.state.customizeinfo;
            oldArr.buttonHeaderColor.splice(key, 1);
            this.setState({
                customizeinfo: oldArr
            }); 
        }
        if(type=='button'){
            let oldArr = this.state.customizeinfo;
            oldArr.buttonColor.splice(key, 1);
            this.setState({
                customizeinfo: oldArr
            }); 
        }
    }

    addMoreColor = (type) => {
       
        if(type=='header'){
            let hval = this.state.customizeinfo;
            hval.buttonHeaderColor.push('#ffffff');
            this.setState({
                customizeinfo: hval
            }); 
        }
        if(type=='button'){
            let bval = this.state.customizeinfo;
            bval.buttonColor.push('#ffffff');
            this.setState({
                customizeinfo: bval
            }); 
         }

    }


    // addMorePhone = () => {
    //     let newState = this.state.customizeinfo;
    //     newState.phoneList.push({ phone: "", text: ''});
    //     this.setState({
    //         customizeinfo: newState,
    //     });

    // }

    // deleteMorePhone = (key,event) => {
    //     let oldArr = this.state.customizeinfo;
    //     oldArr.phoneList.splice(key, 1);
    //     this.setState({
    //         customizeinfo: oldArr,
    //     });
    // }


    // handlePhoneformat = (key,event) => { 
    //     let oldArr = this.state.customizeinfo;
    //     oldArr.phoneList[key].phone = this.phoneFormat(event.target.value);
    //     this.setState({
    //         customizeinfo: oldArr,
    //     });
    //     if(oldArr.phoneList[key].phone!=''){
    //         let errList = this.state.errors;
    //         delete errList[event.target.name];
    //         this.setState({ errors: errList });
    //     }
    // }
 
    // handlePhoneText = (key,event) => { 
    //     let oldArr = this.state.customizeinfo;
    //     oldArr.phoneList[key].text = event.target.value;
    //     this.setState({
    //         customizeinfo: oldArr,
    //     });
    //     if(event.target.value!=''){
    //         let errList = this.state.errors;
    //         delete errList[event.target.name];
    //         this.setState({ errors: errList });
    //     }
    // }

    phoneFormat = (p) => { 
        p = p.replace(/[^\d]*/gi, "");
        if (p.length <= 3) {
            return p;
        }else if (p.length > 3 && p.length < 7) {
          p = "(" + p;
          let l30 = p.length;
          let p30 = p.substring(0, 4);
          p30 = p30 + ")";
    
          let p31 = p.substring(4, l30);
          let pp = p30 + p31;
          return pp;
        } else if (p.length >= 7) {
          p = "(" + p;
          let l30 = p.length;
          let p30 = p.substring(0, 4);
          p30 = p30 + ")";
    
          let p31 = p.substring(4, l30);
          let pp = p30 + p31;
    
          let l40 = pp.length;
          let p40 = pp.substring(0, 8);
          p40 = p40 + "-";
    
          let p41 = pp.substring(8, l40);
          let ppp = p40 + p41;
          let maxphonelength = 13;
          let finalPhone = ppp.substring(0, maxphonelength);
          return finalPhone;
        }
      };

    sanitizeConf = {
        allowedTags: ["p", "<span>"],
        allowedAttributes: { a: ["href"] }
    };

    sanitize = () => {
        console.log('sanitize');
        // this.setState({ html: sanitizeHtml(this.state.html, this.sanitizeConf) });
    };
 
    handleSunEditor = (content, editorName) => {
        
        let newState = this.state.customizeinfo;
        const newC =  content.target.value;
        console.log(newC);
        newState[editorName] = newC;
        console.log(newState[editorName]);
        this.setState({ customizeinfo: newState });

        let errList = this.state.errors;
        delete errList[editorName];
        this.setState({ errors: errList });
        // if (webString.length < 40) {
        //     let errList = this.state.errors;
        //     delete errList[editorName];
        //     this.setState({ errors: errList });
        // }
    }

    onchange = (e) => { 
        let newState = this.state.customizeinfo; 
        console.log(newState,'newState',e.target.value)
        if (e.target.name == 'chatPopup' || e.target.name == 'isdynamicCall' || e.target.name === 'letsTalkPopup') {
            if (e.target.checked)
               newState[e.target.name] = 'ON';
             else
               newState[e.target.name] = 'OFF';
        }else{newState[e.target.name] = e.target.value;}
        
        if(!['lets_talk_web','lets_talk_mobile'].includes(e.target.name)){
            if(e.target.value!=''){
                let errList = this.state.errors;
                delete errList[e.target.name];
                this.setState({ errors: errList });
              } else {
                  // const ar = ['lets_talk_web', 'lets_talk_mobile'];
                  // if (!ar.includes(e.target.name) ) {
                  let errList = this.state.errors;
                  errList[e.target.name] = "This field is required.";
                  this.setState({ errors: errList }) 
                  // }
              }
        }
        
        if (e.target.name === 'is_different_lets_talk') {
            if (e.target.checked) {
                newState[e.target.name] = 1;
            } else {
                newState['lets_talk_popup_mobile'] = null;
                newState['lets_talk_mobile'] = null;
                newState[e.target.name] = 0;
            }
        }

        if (e.target.name === 'previous_chat') {
            if (e.target.checked) {
                newState[e.target.name] = 1;
            } else {
                newState[e.target.name] = 0;
            }
        }
        
        if (e.target.name === 'chatPopupSize') {
            if (e.target.checked) {
                newState[e.target.name] = e.target.value;
            }
        }

        if (e.target.name === 'chatPopupSizeMobile') {
            if (e.target.checked) {
                newState[e.target.name] = e.target.value;
            }
        }
         
        if(e.target.name=="isEmoji"){
            if (e.target.checked) {
                newState[e.target.name] ="ON";
            }else{
                newState[e.target.name] ="OFF";
            }
           
        }

        if(e.target.name=="gptIncompletePopup"){
            if (e.target.checked) {
                newState[e.target.name] ="ON";
            }else{
                newState[e.target.name] ="OFF";
            }
           
        }


        this.setState({ customizeinfo: newState });
        if(e.target.name=='openMessage')
        this.onchangeUpdatedesign(e.target.value);

        if(e.target.name=='customizedHeight' && this.state.customizeinfo.customizeLogo!='')
        document.getElementById('preViewIcon').style.height = e.target.value+'px';
        if(e.target.name=='customizedWidth' && this.state.customizeinfo.customizeLogo!='')
        document.getElementById('preViewIcon').style.width = e.target.value+'px';

        if((e.target.name) == 'customizedWidth'){
            var div = document.querySelector('.zy--closing__popup');
            var img = document.querySelector('#preViewIcon'),
            imgWidth = img.offsetWidth,
            imgX = (img.offsetLeft - img.scrollLeft + img.clientLeft);
            console.log(imgWidth, imgX);
            div.style.left = (imgX) + 'px';
            div.style.transform = 'translateX(-110%)'
        }
      } 

    onchangeChatIcon = (img,id) => {
        let cusomLogo=""; 
      document.getElementById('icon1').src = "assets/media/logos/chat-icon-1.png";
      document.getElementById('icon2').src = "assets/media/logos/chat-icon-2.png";
      document.getElementById('icon3').src = "assets/media/logos/chat-icon-3.png";
      document.getElementById('icon4').src = "assets/media/logos/chat-icon-4.png";
      document.getElementById(id).src = "assets/media/logos/"+ img;
      document.getElementById('preViewIcon').src = "assets/media/logos/"+ img;
      let newState = this.state.customizeinfo; 
      newState.selectedTheme = img;

      document.getElementById("customizeLogoId").value = "";
      newState.customizeLogo = '';
      newState.customizedHeight = '52';
      newState.customizedWidth = '52';
      console.log("yes",this.state.customlogo,ititialCustomLogo,this.state.customizeinfo.customizeLogo,this.state.newcustomlogo);
      if(this.state.customlogo !== '' && ititialCustomLogo!='' && this.state.customizeinfo.customizeLogo!=''){
            console.log("if 1",this.state.customlogo);
            let imgArr = this.state.customizeinfo.customizeLogo.split("/");
            cusomLogo=imgArr[imgArr.length - 1];
         }
         if(this.state.newcustomlogo !== ''){
            console.log("if 2",this.state.newcustomlogo);
             cusomLogo=this.state.newcustomlogo;
         }
      this.setState({ customizeinfo: newState, removeImg:1,customlogo:cusomLogo});
    }

    handleCompanyLogo = (event) => { 
        let cusomLogo="";
        let newState = this.state.customizeinfo; 
        let imageMIME = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
        if(imageMIME.includes(event.target.files[0].type) == true && event.target.files[0].type!='' ){
            if(event.target.files[0].size < 1000001){
                let errList = this.state.errors;
                delete errList['companyLogo'];
                this.setState({ errors: errList });
                newState.companyLogo = URL.createObjectURL(event.target.files[0]);
                newState.removeImg=1;
                this.setState({
                    customizeinfo: newState,removeImg:1
                })
            }else{
                let errList = this.state.errors;
                errList['companyLogo'] = 'Invalid file size. File size must be less than 1MB. ';
                this.setState({ errors: errList }) 
            }
        }else{
            let errList = this.state.errors;
            errList['companyLogo'] = 'Invalid file format. Only "jpg, jpeg, png, gif" files are supported.';
            this.setState({ errors: errList }) 
        }

    } 

    handleCompanyLogoRemove =(event) =>{
        
        document.getElementById("comanyLogoId").value = "";
        if(this.state.cplogo == '' && ititialCpLogo!=''){
           let imgArr = this.state.customizeinfo.companyLogo.split("/");
           this.setState({
                cplogo: imgArr[imgArr.length - 1]
            })
        }
        let newState = this.state.customizeinfo; 
        newState.companyLogo = '';
        this.setState({
            customizeinfo: newState,removeImg:1
        });
    }


    handleCustomLogo = (event) => { 
        let newState = this.state.customizeinfo; 
        let imageMIME = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
         
        if(imageMIME.includes(event.target.files[0].type) == true && event.target.files[0].type!=''){
            if(event.target.files[0].size < 1000001){
                console.log("fileDescription",event.target.files[0]);
                let errList = this.state.errors;
                delete errList['customizeLogo'];
                this.setState({ errors: errList });
                newState.customizeLogo = URL.createObjectURL(event.target.files[0]);
                this.setState({
                    customizeinfo: newState,removeImg:1
                })
            }else{
                let errList = this.state.errors;
                errList['customizeLogo'] = 'Invalid file size. File size must be less than 1MB. ';
                this.setState({ errors: errList }) 
            }
        }else{
            let errList = this.state.errors;
            errList['customizeLogo'] = 'Invalid file format. Only "jpg, jpeg, png, gif" files are supported.';
            this.setState({ errors: errList }) 
        }

    } 

    handleCustomLogoRemove =(event) =>{
        console.log(this.state.customlogo,ititialCustomLogo,this.state.customizeinfo.customizeLogo,'logo',this.state.newcustomlogo);
        document.getElementById("customizeLogoId").value = "";
         if(this.state.customlogo == '' && ititialCustomLogo!='' && this.state.customizeinfo.customizeLogo!=''){
            console.log("if 1",this.state.customlogo);
            let imgArr = this.state.customizeinfo.customizeLogo.split("/");
            this.setState({
                customlogo: imgArr[imgArr.length - 1]
             });
         }else if(this.state.newcustomlogo !== ''){
            console.log("if 2",this.state.newcustomlogo);
            this.setState({
                customlogo: this.state.newcustomlogo
             });
         }

        let newState = this.state.customizeinfo; 
        newState.customizeLogo = '';
        newState.customizedHeight = '52';
        newState.customizedWidth = '52';
        newState.removeImg=1;
        this.setState({
            customizeinfo: newState,removeImg: 1,
        });
    }


	render() {
		return (
			<div className="row"> 
                    {this.state.loading && <div className="ui loading form"></div>}
                    {!this.state.loading && (
                     <form  id="customizeFormId" name="customizeForm" encType="multipart/form-data"  method="post" >
                        <div className="col-lg-12 yr-chat-box-row"> 
                        <Design 
                            customizeinfo={this.state.customizeinfo} 
                            onchangeUpdateColor={this.onchangeUpdateColor} 
                            deleteMoreColor={this.deleteMoreColor} 
                            addMoreColor={this.addMoreColor}
                            onchangeChatIcon={this.onchangeChatIcon}
                            handleCompanyLogo={this.handleCompanyLogo}
                            handleCompanyLogoRemove={this.handleCompanyLogoRemove}
                            handleCustomLogo={this.handleCustomLogo}
                            handleCustomLogoRemove={this.handleCustomLogoRemove}
                            onchange={this.onchange}
                            errors={this.state.errors} 
                            cplogo={this.state.cplogo}
                            customlogo={this.state.customlogo}
                        />
                        <Rules 
                            customizeinfo={this.state.customizeinfo} 
                            errors={this.state.errors} 
                            onchange={this.onchange}
                            // addMorePhone={this.addMorePhone}
                            // deleteMorePhone={this.deleteMorePhone}
                            // handlePhoneformat={this.handlePhoneformat}
                            // handlePhoneText={this.handlePhoneText}
                            // handleSunEditor={this.handleSunEditor}
                            handleSanitize={this.sanitize}
                                handleKeyPress={this.handleKeyPress}
                                changeTextStyle={this.changeTextStyle}
                        />
                   
                        <Preview 
                            customizeinfo={this.state.customizeinfo} 
                            onchangeUpdatedesign={this.onchangeUpdatedesign} 
                        />
                        </div> 
                    </form > 
                    )}
            </div>
    );
	};
}

export default DesignRules;
