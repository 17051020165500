import React from "react";
import {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
} from "../../../../../store/Page";
import DatePicker from "react-datepicker";
import { Form, Dropdown } from "formsy-semantic-ui-react";
import "../../../../Modal/dialog.css";
import api from "../../../../../api";
import config from "../../../../../config";
import util from "../../../../../util";
import utilFunc from "../../../../../util_funs";
import CustomErrorField from "../../../../Form/CustomErrorField";
import { Message, Visibility } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import EmailListNew from "./EmailListNew";
import Modal from "../../../ChatSetup/components/Modal";
import "../../../../Modal/dialog.css";
import DatePickerComp from "./DatePickerComp";
import "../../../Marketing/yr-style.css";
import UnlayerEditor from "./UnlayerEditor";
import validationRule from "../../../../../validation";
import "../index.css";
import ReactPaginate from "react-paginate";
import ContentEditable from "react-contenteditable";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import SendingQueue from "./SendingQueue";
import SmsTemplate from "./SmsTemplate";
import SeasonalRules from './SeasonalRules'
import SeasonalModal from './SeasonalModal'
import AddContactVia from "./AddContactVia";
import AddStaticContact from "./AddStaticContact";
import SeasonalMessageDialog from './SeasonalMessageDialog';
import DialogBoxDetailOnCSVUploadError from "./DialogBoxDetailOnCSVUploadError";
import util_funs from "../../../../../util_funs";
const errorLabel = <div className="red" />;
validationRule.isRequired();

const tokenInfo = util.getAuthTokens();
class EmailTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultpostcardtab:false,
      loading: true,
      errors: {},
      details: "",
      pid: "",
      tab: "1",
      userToaster: false,
      userToasterMsg: "",
      userToasterColor: "",
      dialogTitle: "",
      dialogMessage: "",
      dialogLoading: false,
      dialogShow: false,
      domainList: [],
      textList: [],
      emailyourtemplate: false,
      emailyourtemplateLoading: false,
      templateEmailid: "",
      alt_variable: "",
      templateSubject: "",
      list_load: false,
      selectedEditor: "",
      emailSubject: "",
      selectedEditorContent: "",
      selectedEditorContent_JSON: "",
      editorLoad: false,
      editorUseEffectRun: true,
      variables: "",
      editor_update: false,
      modalstatus: false,
      dataLoaded: false,
      modaltitle: "",
      modalbody: "",
      modalfooter: "",
      modalindex: "",
      open_id: "",
      load_first: false,
      template_rules: "",
      template_buttons: "",
      active_rule: "",
      loadingrule: "",
      rule_data: [],
      ruleerrors: "",
      check_update: false,
      moved_branch: false,
      sendmailfrom: "",
      sendtextfrom: "",
      setDateinAfter: false,
      scheduledateEmpty: "none",
      scheduleStartDate: new Date(),
      template_type: "",
      post_card_list: [],
      showrules: false,
      postcard_id: "",
      template_info: {},
      direct_rule_loding: false,
      direct_rule_message: false,
      offset: 0,
      perPage: 6,
      pageCount: 0,
      initialPage: 0,
      paginationLoad: true,
      modalaltstatus: false,
      alt_value: "",
      errors_alt: {},
      modalaltloading: false,
      seasonalRule: [
        {
          schedule_start_date: new Date(),
          schedule_end_date: new Date(),
          send_email_branch: "",
          after_time: "",
          duration_week: "",
          isSet: 0,
        },
      ],
      seasonalModalIsOpen:false,
      seasonalConfirmationModalIsOpen:false,
      activetab: "",
      pbookDetails: null,
      startD: new Date(),
      startE: new Date(),
      poperror: {},
      save_Seasonal: 0,
      customVariableValue: "",
      callType: "", 
      smsText:{},
      smsContent:{},
      smsType:'sms',
      branch_variables:[],
      canStartPlaybook:false,
      playbookBucket:[],
      addContactModal:false,
      addEditContactModal:false,
      contactLabels:[],
      optType:'static',
      sendquekey:0,
      modalstatusCSVfailure:false,
      csvFailArr:[],
      bucktitle:''
     

    };
    this.editor = window.editor;
    this.contentEditable = React.createRef();
    this.sendquelist = React.createRef();

  }

  //27-12-2021 for seting branch sequense
  updateBranchListSequenseprop = (newstate) => {
    if (newstate.length) {
      let stateForSequense = { ...this.state.details, list: newstate };
      this.setState({ ...this.state, details: stateForSequense });
    }
  };
   
  // sms handle sms data
  handleSmsData=(data)=>{
    let err=this.state.errors;
     this.setState({smsText:data,errors:err});
     
  }

  componentDidMount() {
    
    api.marketing.getUploadedPluginAssets({}).then((data) => {
      try {
        var imageData = data.data;
        this.editor.editors[0].AssetManager.add(imageData);
      } catch (err) { }
    });
    const { setPageTitle, toggleBreadcrum, toggleNavigation } = this.props;
    setPageTitle({
      title: "Email",
      navigationName: "marketing",
      subHeaderTitle: "Engage",
    });
    toggleNavigation({ toggle: true });
    toggleBreadcrum({ toggle: false });

    this.setState({ pid: util_funs.dcrypt_url(this.props.match.params.id) }, function () {
      //New ED
      this.loadDataNewEd();
    });
  }

  updateEditor = () => {
    this.updateContentNewEditor();
  };
  //New ED
  updateContentNewEditor = (html, designArr) => {
    var designStr = JSON.stringify(designArr);
    let attr = document.querySelector(".tab-pane.active");
    let tab = attr.getAttribute("id");
    this.setState({ editor_update: true }, function () {
      // if( this.state.template_type == 1) {
      // }
      console.log("this.state.template_type", attr);
      if (this.state.template_type == 1) {
        this.openRule(5);
      }
    });

    var designStr = JSON.stringify(designArr);
    api.playbookdetail
      .updateNeweditor({
        email_content_json: designStr,
        id: document.getElementById("selected_editor_id").value,
        subject: this.state.emailSubject,
        content: html,
        list_arr: this.state.variables,
      })
      .then((data) => {
        try {
          //this.setState({ editor_update: false });
          //editor_update: true
        } catch (err) {
          console.log(err);
        }
      });
  };

  loadDataNewEd = () => {
    let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
    let time_zone = clientTimeZone;

    /* Sagar-> API for getting the domainList */
    api.playbookdetail.getDomainEmailList({}).then((res) => {
      this.setState({
        domainList: res.data,
      });
    });
    api.playbookdetail.getDomainTextList({}).then((res) => {
      this.setState({
        textList: res.data,
      });
    });

    // Sagar->api for getting Detail about particular Playbook
    api.playbookdetail
      .getPlaybookDetails({
        pid: this.state.pid,
        time_zone: time_zone,
        limit: this.state.perPage,
        offset: this.state.offset,
      })
      .then((data) => {
        try {
          let current =
            typeof data.data.list[0] != "undefined" ? data.data.list[0] : "";
          var setDateinAfter = false;
          var scheduleStartDate = new Date();
          if (data.data.rule_data.after_time == "customDate") {
            setDateinAfter = true;
            scheduleStartDate = data.data.rule_data.schedule_date;
          }
          let iseditable=false;
          let buckettypes=JSON.parse(data.data.pbookDetails.bucket_type).map((item)=>{
              return item.name;

          });
        
          if(buckettypes.includes("Email") && data.data.domain_id){
           
            iseditable=true;
          }
          if(buckettypes.includes('Postcard')){
            iseditable=true;
          }
          if(buckettypes.includes("SMS") && data.data.phone_id){
            iseditable=true;
          }
          if(buckettypes.includes("Postcard") && buckettypes.includes("Email")){
            if(data.data.domain_id){
              iseditable=true;
             
            }else{
              iseditable=false;
            }
           
          }
          if(buckettypes.includes("SMS") && buckettypes.includes("Email")){
            if( data.data.phone_id && data.data.domain_id){
              iseditable=true;
             
            }else{
              iseditable=false;
            }
           
          }
          if(buckettypes.includes("SMS") && buckettypes.includes("Postcard")){
            if( data.data.phone_id){
              iseditable=true;
             
            }else{
              iseditable=false;
            }
           
          }
          if(buckettypes.includes("Postcard" && !buckettypes.includes("SMS") && !buckettypes.includes("Email"))){
            iseditable=true;
            
          }

          this.setState(
            {
              loading: false,
              details: data.data,
              load_first: true,
              editorLoad: true,
              list_load: true,
              selectedEditor:
                typeof current.id != "undefined" ? `${current.id}` : "",
              emailSubject:
                typeof current.email_subject != "undefined"
                  ? current.email_subject
                  : "",
              selectedEditorContent:
                typeof current.email_content != "undefined"
                  ? current.email_content
                  : "",
              selectedEditorContent_JSON:
                typeof current.email_content_json != "undefined"
                  ? current.email_content_json
                  : "",
              smsContent:(current.template_type==3) ? {smsContent:current.smstext,file:current.mmsfile}:{},
              branch_variables:data.data.variables,   
              variables: data.data.variables,
              template_buttons: data.data.template_buttons,
              template_rules: data.data.template_rules,
              active_rule: current.template_type == 1 ? 1 : 6,
              active_rule: 5,
              rule_data: data.data.rule_data,
              template_type:
                typeof current.template_type != "undefined"
                  ? current.template_type
                  : "",
              sendmailfrom: data.data.domain_id ? data.data.domain_id : 0,
              sendtextfrom:data.data.phone_id ? data.data.phone_id : 0,
              setDateinAfter: setDateinAfter,
              scheduleStartDate: scheduleStartDate,
              post_card_list: data.data.post_card_list,
              pageCount: Math.ceil(
                data.data.post_card_list_count / this.state.perPage
              ),
              postcard_id:
                typeof current.postcard_id != "undefined"
                  ? current.postcard_id
                  : "",
                  canStartPlaybook:iseditable,
                  playbookBucket:buckettypes,
              pbookDetails: data.data.pbookDetails,
              customVariableValue:
                typeof current.email_custom_variable != "undefined"
                  ? current.email_custom_variable
                  : "",
            },
            function () { 
              if(this.state.defaultpostcardtab === false){
                let bucketType;
                if( this.state.pbookDetails ){
                  if(this.state.pbookDetails.bucket_type){
                    if(this.state.pbookDetails.bucket_type.length){
                      bucketType = JSON.parse(this.state.pbookDetails.bucket_type);
                    }else{
                      bucketType = [];
                    }
                  }else{
                    bucketType = [];
                  }
                }else{
                  bucketType = [];
                }
                let bucket =  bucketType.filter( (tl,i) => tl.id == 'postcard');
                if(bucket.length){
                  this.setState({
                    activetab:'directmail'
                  });
                }
                this.setState({
                  defaultpostcardtab:true
                });
              }
            }
          );
          this.getPostCardContent();
        } catch (err) {
          console.log(err);
        }
      });
  };

  showToaster = (color, msg) => {
    this.setState({
      userToaster: true,
      userToasterMsg: msg,
      userToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ userToaster: false });
      }.bind(this),
      3000
    );
  };
  updateList = (data, template_type, insert_id = null) => {
    console.log(insert_id, this.state.details, data);
    let listdata = this.state.details;
    listdata.list = data;
    this.setState(
      {
        details: listdata,
        template_type:
          template_type != null ? template_type : this.state.template_type,
      },
      function () {
        if (insert_id != null) {
          this.openAttr(insert_id);
        }
      }
    );
  };
  openEmailPop = () => {
    //alert('Hi');
    this.setState({
      emailyourtemplateLoading: false,
      emailyourtemplate: true,
      errors: {},
    });
  };

  getTemplateEmailid = (e) => {
    this.setState({ templateEmailid: e.target.value });
  };

  getTemplateSubject = (e) => {
    this.setState({ templateSubject: e.target.value });
  };

  closeEmailPop = () => {
    //alert('Hi');
    this.setState({
      emailyourtemplateLoading: false,
      emailyourtemplate: false,
      errors: {},
      templateEmailid: "",
      templateSubject: "",
    });
  };

  sendEmailtemplate = (emailId = null) => {
    this.setState({
      emailyourtemplateLoading: true,
    });
    //alert(emailId); 
    api.playbookdetail
      .sentEmailtemplate({
        branch_id: this.state.selectedEditor,
        pid: this.state.pid,
        emaiId: emailId,
        emailSubject: this.state.templateSubject,
      })
      .then((data) => {
        try {
          //console.log(data.message);
          this.showToaster("green", "Email sent successfully.");
          utilFunc.scrollTop(800);
          //alert(type);
          this.closeEmailPop();
          this.setState({
            loading: false,
            templateEmailid: "",
            templateSubject: "",
            emailyourtemplateLoading: false,
          });
        } catch (err) {
          console.log(err);
        }
      });
  };

  saveEditor = (type = null) => {
    let attr = document.querySelector(".nav-link.active");
    let tab = attr.getAttribute("tab");
    let contentType=this.state.template_type;
    if (tab == "content") {
      const errors = {};
       if(contentType == 3){
          if(typeof this.state.smsText.smsContent=='undefined' && typeof this.state.smsText.file=='undefined'){
            errors["content"] = "Text or image is required.";
            this.showToaster("red", errors['content']);
          }else if(this.state.smsText.smsContent=="" && this.state.smsText.file=="" && this.state.smsText.smsContent=="" && this.state.smsText.file==""){
            errors["content"] = "Text or image is required.";
            this.showToaster("red", errors['content']);
          }else if(this.state.smsText.smsContent.length>0 && this.state.smsText.smsContent.length>1500 ){
            errors["content"] = "Text characters count cannot exceed more than 1500.";
            this.showToaster("red", errors['content']);
          }else if(this.state.smsText.smsContent.length>0 && this.state.smsText.smsContent.split(' ').length<2 ){
            errors["content"] = "Text should contain more than one word.";
            this.showToaster("red", errors['content']);
          }
       }else{
        if (this.state.emailSubject == "") {
          errors["emailSubject"] = "This field is required.";
        }
        
    }
    this.setState({ errors });
      if (Object.keys(errors).length == 0) {
        this.setState({loading:true})
        if(contentType!=3){
        api.playbookdetail
          .saveNeweditor({
            id: this.state.selectedEditor,
            subject: this.state.emailSubject,
            list_arr: this.state.variables,
            customVariableValue: this.state.customVariableValue,
          })
          .then((data) => {
            try {
              this.setState({
                editor_update: false,
                content: data.data.content,
                template_buttons: data.data.template_buttons,
                rule_data: data.data.rule_data,
                loading:false
              });
              if(this.state.active_rule===7){
                this.setState({seasonalConfirmationModalIsOpen:true,tab:2})
              }else{
                this.showToaster("green", "Email template edited successfully.");
                utilFunc.scrollTop(800);
                //alert(type);
                if (type == "alert") {
                  this.saveAlert("Close");
                  this.setState({ tab: 2 });
                  document
                    .getElementById("rule")
                    .setAttribute("data-toggle", "tab");
                  document.getElementById("rule").click();
                }
              }
            } catch (err) {
              this.setState({loading:false})
              console.log(err);
            }
          });
        }else{
          var header="";
          let postdata={};
           postdata= {
             id: this.state.selectedEditor,
              content: this.state.smsText.smsContent,
              file:(this.state.smsText.file!=null) ? this.state.smsText.file:null
          }
            api.playbookdetail
          .saveNewSmseditor(postdata)
          .then((data) => {
            try {
              if(data.status){
                this.setState({
                  editor_update: false,
                  content: data.data.content,
                  template_buttons: data.data.template_buttons,
                  rule_data: data.data.rule_data,
                  loading:false
                });
                  this.showToaster("green", "Email template edited successfully.");
                  utilFunc.scrollTop(800);
                  //alert(type);
                 
              }else{
                this.showToaster("red",data.message);
                utilFunc.scrollTop(800);
                if (type == "alert") {
                  this.saveAlert("Close");
                  this.setState({ tab: 2 });
                  document
                    .getElementById("rule")
                    .setAttribute("data-toggle", "tab");
                  document.getElementById("rule").click();
                }
              }
              
            } catch (err) {
              console.log(err);
            }
          });
          
        }
      }
    } else {
      this.saveRules();
    }
  };

  //ADDED BY ANKIT FOR REMOVE RULE DATA START -----------
  resetRulesConfirm = () => {
    let modalbody = this.modalBody("delete_reset");
    this.setState({
      modalaltstatus: true,
      modalaltloading: false,
      modalindex: "delete_reset",
      modalbody: modalbody,
      modaltitle: "Confirm your action",
    });
  };
  resetRules = () => {
    this.setState({loading:true,modalaltstatus:false})
    api.playbookdetail
      .resetRules({
        branch_id: this.state.selectedEditor,
        rule_type: this.state.active_rule,
        playbook_id:this.state.pid
      })
      .then((data) => {
        try {
          this.setState(
            {
              loading: false,
              editor_update: false,
              modalaltstatus: false,  
            },
            () => {
              this.openRule(this.state.active_rule);
            } 
          );
          // if(this.state.active_rule==7){
          //   this.setState({seasonalConfirmationModalIsOpen:true})
          // }else{
            this.showToaster("green", "Rules reset successfully.");
            document.getElementById("seasonal_list").firstElementChild.scrollIntoView({behavior:"smooth"})
            // utilFunc.scrollTop();
          // }
        } catch (err) {
          console.log(err);
        }
      });
  };
  //ADDED BY ANKIT FOR REMOVE RULE DATA END -----------

  onchange = (e) => {
    if (e.target.value != "") {
      let errList = this.state.errors;
      delete errList[e.target.name];
      this.setState({ errors: errList });
    } else {
      let errList = this.state.errors;
      errList[e.target.name] = "This field is required.";
      this.setState({ errors: errList });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  onchangeVariable = (val, key, varName) => {
    
    let emailSub = this.state.emailSubject.replace('<br>', '')
    let val2 =
      `<span class='alt_val_span' alt_var='${varName}' alt_key='${val.slice(2, -2)}'>${val}</span>`;

    // const index = emailSub.lastIndexOf("&nbsp;")
    // const emailSublength = emailSub.length
    // console.log(index)
    // if(emailSub.substring((index,(index+6))==="&nbsp;")){
    //   if(index>0){
    //     const str1 = emailSub.substring(0,index)
    //     const str2 = emailSub.substring(index+6,emailSub.length)
    //     console.log(str1)
    //     console.log(str2)
    //     emailSub = str1 + str2
    //     console.log(emailSub)
    //   }
      
    // }
    //code by manoj ,removed last nbsp;
    // var n = emailSub.lastIndexOf("&nbsp;");
  
    // let sublen = emailSub.length;
    // console.log(emailSub)
    // console.log(emailSub.substring(sublen - 6).trim())

    // if (emailSub.substring(sublen - 6).trim() == "&nbsp;") {
    //   console.log("white space")
    //   emailSub = emailSub.substring(0, sublen - 6);
    // }
    this.setState({ emailSubject: emailSub + val2 }, function () { });
  };
  openAttr = (branch_id, branchindex = 0, templateType = null) => {
    
    console.log(branch_id, branchindex, templateType);
    if (templateType == 3) {
      this.setState({
        template_type: 3,

      });
       
    }
    this.setState({ loading: true, dataLoaded: false, paginationLoad: false, });
    this.setState({ editor_update: true })


    api.playbookdetail.openEditor({ branch_id: branch_id }).then((data) => {
      try {
        console.log("ruledata", data.data.rule_data);
        let ruldata = data.data.rule_data;
        let ttype=data.data.list.template_type;
        this.setState(
          {
            
            selectedEditor: branch_id,
            loading: false,
            emailSubject: data.data.list.email_subject,
            template_buttons: data.data.template_buttons,
            template_rules: data.data.template_rules,
            check_update: false,
            rule_data: ruldata,
            template_type:
              typeof data.data.list.template_type != "undefined" &&
                data.data.list.template_type != null
                ? data.data.list.template_type
                : "",
            /* Sagar */
            selectedEditorContent:
              typeof data.data.list.email_content != "undefined"
                // data.data.list.email_content!= null
                ? data.data.list.email_content
                : "",
            selectedEditorContent_JSON:
              typeof data.data.list.email_content_json != "undefined"
                // data.data.list.email_content_json!= null
                ? data.data.list.email_content_json
                : "",
            /* end */
            active_rule: data.data.list.template_type == 1 ? 5 : 6,
            editorUseEffectRun: true,
            showrules: false,
            postcard_id:
              typeof data.data.list.postcard_id != "undefined" &&
                data.data.list.postcard_id != null
                ? data.data.list.postcard_id
                : "",
            direct_rule_message: false,
            offset: 0,
            paginationLoad: true,
            initialPage: 0,
            editor_update: false,
            activetab: data.data.list.template_type == 2 ? "directmail" : "",
            customVariableValue: data.data.list.email_custom_variable,
            editor_update: true,
            smsContent:(ttype==3) ? {smsContent:data.data.list.email_content,file: data.data.list.file_path}:{},
            branch_variables:data.data.list.bucket_variables
          },
          function () {
            if (this.state.template_type == 2) {
              if (
                this.state.postcard_id != "" &&
                this.state.postcard_id != "0" &&
                this.state.postcard_id != "null"
              ) {
                this.openDirectRules();
              }
              this.getPostCardContent();

            } else {
              this.changesTab("content");
            }

            //console.log(JSON.stringify(this.state))
            let scrolltop =
              document.querySelector(".uppertestvisible").offsetTop -
              document.body.scrollTop;
            window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
          }
        );
      } catch (err) {
        console.log(err);
      }
    });
  };

  addNewTemplate = (data) => {
    //this.setState({ editor_update: true })
    api.playbookdetail
      .saveNeweditor({
        id: this.state.selectedEditor,
        subject: this.state.emailSubject,
        list_arr: this.state.variables,
      })
      .then((data2) => {
        try {
          this.setState(
            {
              selectedEditor: data.id,
              emailSubject: data.email_subject,
              selectedEditorContent: data.email_content,
              selectedEditorContent: data.email_content,
              selectedEditorContent_JSON:
                typeof data.email_content_json != "undefined" &&
                  data.email_content_json != null
                  ? data.email_content_json
                  : "",
              editor_update: false,
              rule_data: data2.data.rule_data,
              // editor_update: true,
              active_rule: 1,
            },
            function () {
              //alert(this.state.editor_update)
              // this.initGrapeJs();
            }
          );
        } catch (err) {
          console.log(err);
        }
      });
    //this.editor.editors[0].setComponents(content);
  };

  updateEditorStatus = () => {
    this.setState({ editor_update: false });
  };

  modalClose = (index) => {
    this.setState(
      {
        modalstatus: false,
        open_id: "",
        modalaltstatus: false,
        modalid: "",
      },
      function () { }
    );
  };

  addEditModelopen=(labels,opt,title)=>{
    
    this.setState({addContactModal:false,addEditContactModal:true,contactLabels:labels,optType:opt,bucktitle:title});
  }
  addEditModelClose=()=>{
    this.setState({addEditContactModal:false});
  }

  onChangeInput = (type, data) => {
    this.setState({ [data.target.name]: data.target.value });
  };

  modalFooter = (index) => {
    if (index == "editor_update") {
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalClose(index)}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>No</span>
          </button>
          <button
            type="button"
            onClick={() => this.callOpenAttr(this.state.open_id)}
            className="btn linear-gradient yr-submitbtn btn-sm"
          >
            <span>Yes</span>
          </button>
        </div>
      );
    }

    if (index == "alt_value") {
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalClose(index)}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>Cancel</span>
          </button>
          <button
            type="button"
            onClick={() => this.saveAltValue()}
            className="btn linear-gradient yr-submitbtn btn-sm"
          >
            <span>Save</span>
          </button>
        </div>
      );
    }

    if (index == "delete_reset") {
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalClose(index)}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>No</span>
          </button>
          <button
            type="button"
            onClick={() => this.resetRules()}
            className="btn linear-gradient yr-submitbtn btn-sm"
          >
            <span>Yes</span>
          </button>
        </div>
      );
    }
  };

  modalBody = (tab) => {
    if (tab == "delete") {
      return "Are you sure you want to delete it?";
    }

    if (tab == "delete_reset") {
      return "Are you sure you want to reset the rule?";
    }

    if (tab == "alt_value") {
      let time2 = Date.now();

      return (
        <div className="col-lg-12 col-md-12 col-sm-12">
          <label className="form-control-label">
            If variable is not present, replace with: <span></span>
          </label>
          <input
            type="text"
            className={
              Object.keys(this.state.errors_alt).length > 0 &&
                typeof this.state.errors_alt["alt_value"] != "undefined"
                ? "form-control is-invalid focusError"
                : "form-control"
            }
            key={time2}
            placeholder="Enter Title"
            defaultValue={this.state.alt_value}
            name="alt_value"
            onChange={(e) => this.onChangeInput("alt_value", e)}
          />
          {Object.keys(this.state.errors_alt).length > 0 &&
            typeof this.state.errors_alt["alt_value"] != "undefined" && (
              <CustomErrorField
                message={this.state.errors_alt["alt_value"]}
                id="help-error"
                className="error invalid-feedback"
              />
            )}
        </div>
      );
    }
  };
  callOpenAttr = (id) => {
    this.setState({ editor_update: false }, function () {
      this.openAttr(id);
      this.modalClose();
    });
  };

  saveAlert = (action) => {
    if (this.state.tab == "1" || this.state.tab == '3') {
      if (action == "Open") {
        //get button update
      
        api.playbookdetail
          .getTempplateButton({ id: this.state.selectedEditor })
          .then((data) => {
            try {
              this.setState(
                {
                  template_buttons: data.data.template_button,
                  tab: 2,
                },
                function () {
                  if(this.state.template_type==1){
                    document
                    .getElementById("sendingQueue")
                    .removeAttribute("data-toggle");
                  }
                  
                  document
                    .getElementById("rule")
                    .setAttribute("data-toggle", "tab");
                  document.getElementById("rule").click();
                }
              );
            } catch (err) {
              console.log(err);
            }

            this.openRule(5);
          });

        // if (this.state.editor_update == true) {
        //   this.setState({
        //     dialogTitle: '',
        //     dialogMessage: 'Are you sure you want to save the content?',
        //     dialogLoading: false,
        //     dialogShow: true,
        //     errors: {},
        //   });
        // } else {
        //   this.setState({ tab: 2 })
        //   document.getElementById('sendingQueue').removeAttribute("data-toggle");
        //   document.getElementById('rule').setAttribute("data-toggle", "tab");
        //   document.getElementById('rule').click();

        //   //document.getElementById('buttonruleclick').click();

        // }
        
      }
      if (action == "Close") {
        this.setState(
          {
            dialogTitle: "",
            dialogMessage: "",
            dialogLoading: false,
            dialogShow: false,
            errors: {},
          },
          function () {
            //document.getElementById('buttonruleclick').click();
          }
        );
      }
      if (action == "Yes") {
        this.saveEditor("alert");
      }
    } else {
      this.setState({ tab: 2 });
      document.getElementById("sendingQueue").removeAttribute("data-toggle");
      document.getElementById("rule").setAttribute("data-toggle", "tab");
      document.getElementById("rule").click();
    }
  };

  changesTab = () => {
    this.setState({ tab: 1, ruleerrors: "", editor_update: false });
    document.getElementById("rule").removeAttribute("data-toggle");
    if(this.state.template_type==1){
      document.getElementById("sendingQueue").removeAttribute("data-toggle");
    }
    
    //let elems=document.querySelectorAll('.nav-link')
    var elems = document.querySelectorAll(".nav-link");

    [].forEach.call(elems, function (el, index) {
      el.classList.remove("active");
      if (index == elems.length - 1) {
        var element = document.getElementById("contenttab");
        element.classList.add("active");
        var tabelement = document.getElementById(
          "kt_portlet_base_demo_1_1_tab_content"
        );
        tabelement.classList.add("active");

        var tabelementc = document.getElementById(
          "kt_portlet_base_demo_1_2_tab_content"
        );
        tabelementc.classList.remove("active");
        var tabelementd = document.getElementById(
          "kt_portlet_base_demo_1_2_tab_sendingQueue"
        );
        tabelementd.classList.remove("active");
        //this.setState({editor_update:false})
      }
    });
    //alert(elem.length)
  };

  openTab = (tab) => {
    this.setState({ tab: tab, ruleerrors: "", editor_update: false });
    document.getElementById("rule").removeAttribute("data-toggle");
    document.getElementById("contenttab").removeAttribute("data-toggle");
    document.getElementById("sendingQueue").setAttribute("data-toggle", "tab");
    document.getElementById("sendingQueue").click();
  };

  openRule = (type) => {
    this.setState({ loadingrule: true, ruleerrors: "" });
    let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
    let time_zone = clientTimeZone;
    api.playbookdetail
      .openRules({
        playbook_id:this.state.pid,
        branch_id: this.state.selectedEditor,
        type: type,
        button_id: "",
        time_zone: time_zone,
      })
      .then((data) => {
        try {
          var setDateinAfter = false;
          var scheduleStartDate = new Date();
          if (data.data.rule_data.after_time == "customDate") {
            setDateinAfter = true;
            scheduleStartDate = data.data.rule_data.schedule_date;
          }
          
          if(this.state.template_type == 3){
            data.data.rule_data.next_email_rules = 1
          }
          this.setState(
            {
              active_rule: type,
              rule_data: data.data.rule_data,
              seasonalRule: data.data.seasonal_rule_data,

              loadingrule: false,
              setDateinAfter: setDateinAfter,
              scheduleStartDate: scheduleStartDate,
            },
            function () {
              // this.editor.editors[0].setComponents(this.state.selectedEditorContent);
              // this.initGrapeJs();
            }
          );
          if(this.state.active_rule===7){
            this.setState({seasonalModalIsOpen:true})
          }
          if (type == 7 && this.state.seasonalRule.length == 0) {
            this.addSeasonalRule("add", "");
          }
        } catch (err) {
          console.log(err);
        }
      });
  };

  changeRule = (type, data, from) => {//aslam doing
    var rules = this.state.rule_data;
    if (type == "button") {
      let test = data.target.value;
      this.setState({ loadingrule: true });
      let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
      let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
      let time_zone = clientTimeZone;
      api.playbookdetail
        .openRules({
          branch_id: this.state.selectedEditor,
          type: 1,
          button_id: data.target.value,
          time_zone: time_zone,
        })
        .then((data) => {
          try {
            var setDateinAfter = false;
            var scheduleStartDate = new Date();
            if (data.data.rule_data.after_time == "customDate") {
              setDateinAfter = true;
              scheduleStartDate = data.data.rule_data.schedule_date;
            }
            let rules = data.data.rule_data;
            rules["button_id"] = test;
            this.setState(
              {
                active_rule: 1,
                rule_data: rules,
                loadingrule: false,
                setDateinAfter: setDateinAfter,
                scheduleStartDate: scheduleStartDate,
              },
              function () {
                //console.log(rules)
                //this.editor.editors[0].setComponents(this.state.selectedEditorContent);
                // this.initGrapeJs();
              }
            );
          } catch (err) {
            console.log(err);
          }
        });
    } else {
      if (
        data.target.name == "email_sent" ||
        data.target.name == "email_opened" ||
        data.target.name == "button_link_clicked"
      ) {
        rules[data.target.name] = data.target.checked == true ? 1 : 0;
      } else {
        if (data.target.name == "next_email_rules") {
          rules[data.target.name] =
            data.target.checked == true ? data.target.value : "";
        } else {
          rules[data.target.name] = data.target.value;
        }
      }
      var s = rules;
      s[data.target.name] = data.target.value;
      if (
        data.target.name == "email_sent" ||
        data.target.name == "email_opened" ||
        data.target.name == "button_link_clicked"
      ) {
        s[data.target.name] = data.target.checked == true ? 1 : 0;
      }
      if (this.state.template_type == 2) {
        s["rule_type"] = 6;
      }
      let v = Object.assign({}, s);
      this.setState({ rule_data: v });
      console.log("ruless", v);
    }

    if (from == "after") {
      if (data.target.value == "customDate") {
        this.setState({
          setDateinAfter: true,
        });
      } else {
        this.setState({
          setDateinAfter: false,
        });
      }
    }
  };

  saveRules = () => {
    const ruleerrors = {};

    if (this.state.active_rule == 1) {
      if (this.state.rule_data.button_id == "") {
        ruleerrors["button_id"] = "This field is required.";
      }
    }

    if (
      this.state.active_rule == 1 ||
      this.state.active_rule == 2 ||
      this.state.active_rule == 3 ||
      this.state.active_rule == 5 ||
      this.state.active_rule == 6
    ) {
      if (this.state.rule_data.after_time == "") {
        ruleerrors["after_time"] = "This field is required.";
      }
      if (this.state.rule_data.send_email_branch == "") {
        ruleerrors["send_email_branch"] = "This field is required.";
      }

      if (
        this.state.rule_data.duration_week == "" &&
        this.state.setDateinAfter == false
      ) {
        ruleerrors["duration_week"] = "This field is required.";
      }

      if (this.state.setDateinAfter == true) {
        if (document.getElementById("scheduleDateTime").value == "") {
          this.setState({
            scheduledateEmpty: "block",
          });
          ruleerrors["schedule_date"] = "This field is required.";
          document.getElementById("scheduleDateTime").style.border =
            "solid 1px red";
        }
      }
    }

    if (this.state.active_rule == 4) {
      if (
        this.state.rule_data.email_sent == 0 &&
        this.state.rule_data.email_opened == 0 &&
        this.state.rule_data.button_link_clicked == 0
      ) {
        ruleerrors["calllisterror"] = "Please select at least one option.";
      }
    }

    if (this.state.active_rule == 5) {
      //alert(this.state.rule_data.next_email_rules)
      if (
        this.state.rule_data == "" ||
        this.state.rule_data.next_email_rules == 0 ||
        this.state.rule_data.next_email_rules == "" ||
        this.state.rule_data.next_email_rules == "undefined"
      ) {
        ruleerrors["next_email_rules"] = "Please select at least one option.";
      }
    }

    this.setState({ ruleerrors: ruleerrors });

    if (Object.keys(ruleerrors).length == 0 && this.state.active_rule != 7) {
      this.setState({ loading: true });
      let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
      let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
      let time_zone = clientTimeZone;
      var scheduleDate = "0000:00:00 00:00:00";
      if (this.state.rule_data.after_time == "customDate") {
        scheduleDate = document.getElementById("scheduleDateTime").value;
      } else {
        this.setState({
          scheduleStartDate: new Date(),
        });
      }
      let rules = this.state.rule_data;
      if (this.state.active_rule == 6) {
        rules.setRule = true;
      }

      api.playbookdetail
        .saveRules({
          branch_id: this.state.selectedEditor,
          rule_type: this.state.active_rule,
          rules_data: rules,
          time_zone: time_zone,
          scheduleDate: scheduleDate,
          postcard_id: this.state.postcard_id,
        })
        .then((data) => {
          try {
            let rules_data = this.state.rule_data;
            rules_data["buttonrulelist"] = data.data.count_data;
            rules_data["setRule"] = true;
            this.setState({
              loading: false,
              rule_data: rules_data,
              editor_update: false,
            });
            this.showToaster("green", "Email template edited successfully.");
            utilFunc.scrollTop(800);
          } catch (err) {
            console.log(err);
          }
        });
    }

    if (this.state.active_rule == 7) {
      //alert('RM');
      const errorsSR = [];
      // Add validation
      new Promise((resolve) => {
        this.state.seasonalRule.forEach((data, key) => {
          //alert(data.send_email_branch);
          
          if (data.schedule_start_date == "") {
            if (typeof errorsSR.schedule_start_date != "undefined") {
              errorsSR.schedule_start_date[key] = "This field is required";
            } else {
              errorsSR.schedule_start_date = {
                [key]: "This field is required",
              };
            }
          }

          if (data.schedule_end_date == "") {
            if (typeof errorsSR.schedule_end_date != "undefined") {
              errorsSR.schedule_end_date[key] = "This field is required";
            } else {
              errorsSR.schedule_end_date = { [key]: "This field is required" };
            }
          }

          if (data.send_email_branch == "") {
            if (typeof errorsSR.send_email_branch != "undefined") {
              errorsSR.send_email_branch[key] = "This field is required";
            } else {
              errorsSR.send_email_branch = { [key]: "This field is required" };
            }
          }

          if (data.after_time == "") {
            if (typeof errorsSR.after_time != "undefined") {
              errorsSR.after_time[key] = "This field is required";
            } else {
              errorsSR.after_time = { [key]: "This field is required" };
            }
          }
          if (data.duration_week == "") {
            if (typeof errorsSR.duration_week != "undefined") {
              errorsSR.duration_week[key] = "This field is required";
            } else {
              errorsSR.duration_week = { [key]: "This field is required" };
            }
          }

          // Start
          var recordlist = this.state.seasonalRule.filter((list, typeindex) => {
            //alert( list.schedule_start_date );
            //alert( list.schedule_start_date );

            var schedule_start_date_data = this.setRuleDate(
              data.schedule_start_date
              
            );
            var schedule_end_date_data = this.setRuleDate2(
              data.schedule_end_date
            );

            var schedule_start_date_list = this.setRuleDate(
              list.schedule_start_date
            );
            var schedule_end_date_list = this.setRuleDate2(
              list.schedule_end_date
            );

            //alert( schedule_start_date_list + 'list' );

            //alert( schedule_start_date_data + 'data' );

            if (
              schedule_start_date_list.getDate() ==
              schedule_start_date_data.getDate() &&
              schedule_start_date_list.getMonth() ==
              schedule_start_date_data.getMonth() &&
              schedule_start_date_list.getFullYear() ==
              schedule_start_date_data.getFullYear() &&
              schedule_end_date_list.getDate() ==
              schedule_end_date_data.getDate() &&
              schedule_end_date_list.getMonth() ==
              schedule_end_date_data.getMonth() &&
              schedule_end_date_list.getFullYear() ==
              schedule_end_date_data.getFullYear() &&
              list.send_email_branch == data.send_email_branch &&
              list.after_time == data.after_time &&
              list.duration_week == data.duration_week &&
              key != typeindex
            ) {
              return true;
              //alert('true');
              //errorsSR.duration_week[key] = 'This rule already exist'
            } else {
              //alert('false');
              return false;
            }
          });
          resolve(errorsSR);
          var dw_id = "duration_week_" + key;
          var a = document.getElementById(dw_id).offsetTop;
          window.scrollTo(0, a);
          //alert(recordlist);
          if (recordlist != "") {
            // setTimeout(function(){
            var dw_id = "duration_week_" + key;
            var a = document.getElementById(dw_id).offsetTop;
            window.scrollTo(0, a);
            // },1000)
            //intent_err = true;
            //intent_msg = 'This set od data already exist';
            errorsSR.duration_week = { [key]: "This rule already exist" };
            // utilFunc.scrollTop(0);
            return false;
            //console.log(recordlist)
            //errorsSR.duration_week = { [key]: 'This rule already exist' }
          } else {
            this.setState({ save_Seasonal: 1 });
          }

          // End
        });
      }).then((data) => {
        if (Object.keys(data).length > 0) {
          this.setState({ poperror: data }, function () {
            console.log(this.state.poperror);
          });

          setTimeout(
            function () {
              this.setState({ poperror: false });
            }.bind(this),
            5000
          );
        } else {
          this.setState({ loading: true });
          let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
          let clientTimeZone =
            u.substring(0, 3) + ":" + u.substring(3, u.length);
          let time_zone = clientTimeZone;

          if (this.state.rule_data.after_time == "customDate") {
            scheduleDate = document.getElementById("scheduleDateTime").value;
          } else {
            this.setState({
              scheduleStartDate: new Date(),
            });
          }
          api.playbookdetail
            .saveSeasonalRules({
              seasonalOption: this.state.seasonalRule,
              branch_id: this.state.selectedEditor,
              rule_type: this.state.active_rule,
              time_zone: time_zone,
              scheduleDate: scheduleDate,
              postcard_id: this.state.postcard_id,
              playbook_id: this.state.pid,
            })
            .then((data) => {
              try {
                //alert( this.state.save_Seasonal );
                let rules_data = this.state.rule_data;
                rules_data["setRule"] = true;
                this.setState({ loading: false, rule_data: rules_data },
                //   ()=>{
                //   this.setState({seasonalConfirmationModalIsOpen:true})
                // }
                ()=>{
                }
                );
                this.showToaster(
                  "green",
                  "Email template edited successfully."
                );
                document.getElementById("seasonal_list").firstElementChild.scrollIntoView({behavior:"smooth"})
                // utilFunc.scrollTop();
                
              } catch (err) {
                console.log(err);
              }
            });
        }
      });
      //end here
    }
  };
  //updateEmailChanges

  updateEmailChanges = (email) => {
    let btypes=this.state.playbookBucket;
    let isstart=false;
    this.setState({ sendmailfrom: email }, () => {
      if(this.state.sendmailfrom==""){
        api.playbookdetail
          .updateSendEmail({
            pid: this.state.pid,
            sendmailfrom: this.state.sendmailfrom,
          }).then((data)=>{
            try{
              isstart=false
              this.setState({canStartPlaybook:isstart})
            }catch(err){
              console.log(err)
            }
          })
      }
      if (this.state.sendmailfrom != "") {
        api.playbookdetail
          .updateSendEmail({
            pid: this.state.pid,
            sendmailfrom: this.state.sendmailfrom,
          })
          .then((data) => {
            try {
              
              if(btypes.includes("Email") && !btypes.includes("SMS")){
                 isstart=true;
              }
              if(btypes.includes("Email") && btypes.includes("SMS") && this.state.sendtextfrom){
                isstart=true;
              }
              
              this.setState({canStartPlaybook:isstart});
              this.showToaster("green", "Email id updated successfully.");
              utilFunc.scrollTop(800);
            } catch (err) {
              console.log(err);
            }
          });
      }
    });
  };

  updatePhoneChanges = (email) => {
    let btypes=this.state.playbookBucket;
    let isstart=false;
    this.setState({ sendtextfrom: email }, () => {
      if(this.state.sendtextfrom==""){
        api.playbookdetail
          .updateSendPhone({
            pid: this.state.pid,
            sendtextfrom: this.state.sendtextfrom,
          }).then(data=>{
            try{
              isstart=false
              this.setState({canStartPlaybook:isstart})
            }catch(err){
              console.log(err)
            }
          })
      }
      if (this.state.sendtextfrom != "") {
        api.playbookdetail
          .updateSendPhone({
            pid: this.state.pid,
            sendtextfrom: this.state.sendtextfrom,
          })
          .then((data) => {
            try {
              //this.setState({ loading: false ,rule_data:rules_data})
              
              if(btypes.includes("SMS") && !btypes.includes("Email")){
                 isstart=true;
              }
              if(btypes.includes("SMS") && btypes.includes("Email") && this.state.sendmailfrom){
                isstart=true;
              }
              this.setState({ canStartPlaybook:isstart});
              this.showToaster("green", "Phone updated successfully.");
              utilFunc.scrollTop(800);
            } catch (err) {
              console.log(err);
            }
          });
      }
    });
  };


  changeDate = (e) => {
    this.setState({
      scheduledateEmpty: "none",
      scheduleStartDate: e,
    });
    document.getElementById("scheduleDateTime").style.border = "";
  };

  editorUseEffectRunChange = (e) => {
    this.setState({
      editorUseEffectRun: false,
    });
  };

  onValidSubmitNewsletter = (e) => {
    this.sendEmailtemplate(this.state.templateEmailid);
   
  };
  onValidSubmitall= (e) => {
    var $msg = "Contact has been added successfully.";
   this.addEditModelClose();
   this.showToaster("green", $msg);
		utilFunc.scrollTop(800);
    this.setState({sendquekey:Math.floor(Math.random() * (100 - 1 + 1)) + 1})
    //this.sendquelist.current.loadData({});
  };

  //csv upload
  onValidSubmitCSV = (e) => {
    this.setState({sendquekey:Math.floor(Math.random() * (100 - 1 + 1)) + 1});
    this.addEditModelClose();
		
		if (e.failStatus == false) {
			this.showToaster("green", "CSV User has been added successfully.");
			utilFunc.scrollTop(800);
		} else if (e.status == false && e.message == "limit") {
			this.showToaster("red", e.data);
			utilFunc.scrollTop(800);
		} else {
			if (e.failArray.length) {
				this.setState({
					modalstatusCSVfailure: true,
					csvFailArr: e.failArray,
				});
			}
		}
	};
	modalCloseCSVError = (e) => {
		this.setState({
			modalstatusCSVfailure: false,
		});
	};


  //ens csv

  openDirectRules = () => {
    let direct_rule_error = false;
    if (this.state.postcard_id == "") {
      direct_rule_error = true;

      this.setState({ direct_rule_message: "Please select at least one" });
    } else {
      this.setState({ direct_rule_loding: true });
      api.playbookdetail
        .loadDirectEmailRules({
          temp_id: this.state.postcard_id,
          branch_id: this.state.selectedEditor,
        })
        .then((data) => {
          try {
            //alert(this.state.postcard_id)
            //this.setState({ loading: false ,rule_data:rules_data})
            this.setState(
              {
                showrules: true,
                template_info: data.info,
                direct_rule_loding: false,
                direct_rule_message: false,
              },
              function () {
                let scrolltop =
                  document.querySelector(".uppertestvisible").offsetTop -
                  document.body.scrollTop;
                window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
              }
            );
          } catch (err) { }
        });
    }
  };

  backToTemplate = () => {
    this.setState({ showrules: false });
  };
  loadDirectEmailRules = (e, temp_id) => {
    this.setState({ postcard_id: temp_id, direct_rule_message: false }, function () {
      let scrolltop =
        document.querySelector(".addsendingrule").offsetTop -
        document.body.scrollTop;
      window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
    });
  };

  saveDirectEmail = () => {
    this.saveRules();
  };

  handlePageClick = (data) => {
    let selected = data.selected;
    let offset = Math.ceil(selected * this.state.perPage);

    this.setState({ offset: offset }, () => {
      this.getPostCardContent();
    });
  };

  getPostCardContent() {
    this.setState({ loading: true })
    api.playbookdetail
      .getPostCardContent({
        branch_id: this.state.selectedEditor,
        limit: this.state.perPage,
        offset: this.state.offset,
      })
      .then((data) => {
        this.setState({
          loading: false,
          post_card_list: data.data.post_card_list,
          initialPage: 0,
          pageCount: Math.ceil(
            data.data.post_card_list_count / this.state.perPage
          ),
          dataLoaded: true
        });
      });
  }

  handleSubjectChange = (evt) => {
    this.setState({ emailSubject: evt.target.value });
  };

  handleCustomVariableChange = (a) => {
    this.setState({ customVariableValue: a });
  };

  addListner = () => {
    //   if(document.querySelectorAll(".alt_val_span").length>0){
    //   document.querySelectorAll(".alt_val_span").forEach(box => {
    //     box.addEventListener("click", () => this.addAltValue(box.getAttribute("alt_key")))
    //   }
    //   )
    // }
  };
  addAltValue = (key, callType = null, varName) => {
    this.setState(
      { alt_value: "", modalid: key, errors_alt: {}, callType: callType },
      function () {
        api.playbookdetail
          .getAltValue({
            branch_id: this.state.selectedEditor,
            hid: varName,
            pid: this.state.pid,
            callType: callType,
          })
          .then((data) => {
            this.setState(
              {
                modalaltstatus: true,
                alt_value: data.alter_value,
                modalaltloading: false,
                modalindex: "alt_value",
              },
              () => {
                let modalbody = this.modalBody("alt_value");
                this.setState({ modalbody: modalbody });
              }
            );
          });
      }
    );
  };

  saveAltValue = () => {
    let error = [];
    if (this.state.alt_value == "") {
      error["alt_value"] = "This field is required";
    }
    if (Object.keys(error).length > 0) {
      this.setState({ errors_alt: error }, function () {
        let modalbody = this.modalBody("alt_value");
        this.setState({ modalbody: modalbody });
      });
    } else {
      this.setState({ modalaltloading: true });

      api.playbookdetail
        .saveAltValue({
          pid: this.state.pid,
          alt_value: this.state.alt_value,
          hid: this.state.alt_variable,
          branch_id: this.state.selectedEditor,
          callType: this.state.callType,
        })
        .then((data) => {
          try {
            this.setState(
              { alt_value: "", modalaltloading: false, modalid: "" },
              function () {
                this.modalClose("");
              }
            );
          } catch (err) {
            console.log(err);
          }
        });
    }
  };

  onClickContentEditable = (e) => {

    if (e.target === this.contentEditable.current) {
      return;
    }
    // an element in `ContentEditable` was clicked!
    if (e.target.className.indexOf("alt_val_span") > -1) {
      if(!e.target.getAttribute('alt_var')){
        // alert("for migration")
        const alt_var = e.target.textContent.slice(2,-2)
        this.addAltValue(e.target.getAttribute("alt_key"), null,alt_var)
        this.setState({ alt_variable: alt_var });
      }else{
        // alert("for new")
        this.addAltValue(e.target.getAttribute("alt_key"), null, e.target.getAttribute('alt_var'));
        this.setState({ alt_variable: e.target.getAttribute('alt_var') });
      }

      // this.setAltVal(e.target.getAttribute('alt_var'))
    }
  };

  applyDateRange = (event, picker) => {
    this.setState({
      selectedtext:
        picker.startDate.format("MM/DD/YYYY") +
        " - " +
        picker.endDate.format("MM/DD/YYYY"),
    });
    let range = {};
    range["chatfrequency"] = "daterange";
    range["startdaterange"] = picker.startDate.format("MM/DD/YYYY");
    range["enddaterange"] = picker.endDate.format("MM/DD/YYYY");
    //this.props.onSubmitFilterDateReange(range);
  };

  // this function will work on edit/start campaign

  onStartCampaign = (resp) => {
    if (resp) {
      this.loadDataNewEd();
      this.showToaster("green", "Campaign modified successfully.");
      utilFunc.scrollTop(800);
    }
  };
  //Seasonal start here
  // added by rohit

  applyDateRange = (event, picker, key) => {
    let dateRang_get =
      picker.startDate.format("YYYY/MM/DD") +
      " - " +
      picker.endDate.format("YYYY/MM/DD");
    let list_seasonal = this.state.seasonalRule;
    list_seasonal[key].dateRang = dateRang_get;
    this.setState({ seasonalRule: list_seasonal });
  };

  changeSeasonalRule = (key, e) => {
    let send_email_branch = e.target.value;
    //alert(key);
    let list_seasonal_branch = this.state.seasonalRule;
    list_seasonal_branch[key].send_email_branch = send_email_branch;
    this.setState({ seasonalRule: list_seasonal_branch });
  };

  changeAfter = (d, e, key) => {
    if (d == "after_time") {
      let after_time = e.target.value;
      //alert(key);
      let list_seasonal_after1 = this.state.seasonalRule;
      list_seasonal_after1[key].after_time = after_time;
      this.setState({ seasonalRule: list_seasonal_after1 });
    }
    if (d == "time") {
      let duration_week = e.target.value;
      //alert(key);
      let list_seasonal_after2 = this.state.seasonalRule;
      list_seasonal_after2[key].duration_week = duration_week;
      this.setState({ seasonalRule: list_seasonal_after2 });

      console.log(this.state.seasonalRule);
    }
  };

  setStartD = (date, key) => {
    let Date = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hrs = date.getHours();
    let min = date.getMinutes();
    let sec = date.getSeconds();
    let final =
      year + "-" + month + "-" + Date + " " + hrs + ":" + min + ":" + sec;
    this.setState({ startD: date, startE_mid: date });
    let list_seasonal = this.state.seasonalRule;
    var start_date = final;
    list_seasonal[key].schedule_start_date = start_date;
    this.setState({ seasonalRule: list_seasonal });
    this.setStartE(date, key);
    //console.log(this.state.seasonalRule[key].schedule_start_date);
  };

  setStartE = (date, key) => {
    let Date = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hrs = date.getHours();
    let min = date.getMinutes();
    let sec = date.getSeconds();
    let final =
      year + "-" + month + "-" + Date + " " + hrs + ":" + min + ":" + sec;
    //alert(key);
    // this.setState({ startE: date });
    // var end_date = date;
    // let list_seasonal = this.state.seasonalRule;
    // list_seasonal[key].schedule_end_date = end_date;
    // this.setState({ seasonalRule: list_seasonal });
    this.setState({ startE: date });
    var end_date = final;
    let list_seasonal = this.state.seasonalRule;
    list_seasonal[key].schedule_end_date = end_date;
    this.setState({ seasonalRule: list_seasonal });
  };

  addSeasonalRule = (type, id) => {
    if (type == "add") {
      let newState = this.state.seasonalRule;
      newState.push({
        schedule_start_date: new Date(),
        schedule_end_date: new Date(),
        send_email_branch: "",
        after_time: "",
        duration_week: "",
        isSet: 0,
      });
      this.setState({
        seasonalRule: newState,
      },()=>{
        // console.log(document.getElementById("seasonal_list"))
        document.getElementById("seasonal_list").lastElementChild.previousSibling.scrollIntoView({behavior: "smooth"});
      });
    }
    if (type == "delete") {
      let oldArr = this.state.seasonalRule;
      oldArr.splice(id, 1);
      this.setState({
        seasonalRule: oldArr,
      });
    }
  };

  showActiveTab = (btn, e) => {
    e.preventDefault();
    this.setState({
      activetab: btn,
    });
  };
  setAltVal = e => {
    this.setState({ alt_variable: e });
  }
  setRuleDate = (date) => {
    console.log(date, "seasonal start_date")
    return new Date(date)
  };
  setRuleDate2 = (date) => {
    // alert(date)
    // return new Date()
    return new Date(date);
  };

  loadSendingQueue = () => { };

  // this function will work on edit/start campaign

  onStartCampaign = (resp) => {
    if (resp) {
      this.loadDataNewEd();
      this.showToaster("green", "Campaign modified successfully.");
      utilFunc.scrollTop(800);
    }
  };

  openSeasonalRules = ()=>{
    this.setState({seasonalModalIsOpen:true})
  }
  seasonalModalClose = ()=>{
    this.setState({seasonalModalIsOpen:false},()=>{
      this.openRule(5)
    })
  }

  SeasonalMessageDialogClose = ()=>{
    this.setState({seasonalConfirmationModalIsOpen:false})
  }

  // openMessageDialog = ()=>{
  //   this.setState({seasonalModalIsOpen:true})
  // }
  OpenAddContactModel=()=>{
    this.setState({addContactModal:true});
  }
  contactModalClose=()=>{
    this.setState({addContactModal:false});
  }

  render() {
    const { errors, ruleerrors, activetab } = this.state;

    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let final = month + "-" + date + "-" + year;
    let playbookBucketType;
    if( this.state.pbookDetails ){
      if(this.state.pbookDetails.bucket_type){
        if(this.state.pbookDetails.bucket_type.length){
          playbookBucketType = JSON.parse(this.state.pbookDetails.bucket_type);
        }else{
          playbookBucketType = [];
        }
      }else{
        playbookBucketType = [];
      }
    }else{
      playbookBucketType = [];
    }
    let types = playbookBucketType.map((tp,i) => {
      return tp.name.toLowerCase()
    });
    let Seasonalbody= <SeasonalRules
    active_rule = {this.state.active_rule} 
    seasonalRule = {this.state.seasonalRule}
    addSeasonalRule = {this.addSeasonalRule}
    setRuleDate = {this.setRuleDate} 
    setStartD = {this.setStartD}
    poperror = {this.state.poperror}    
    setRuleDate2 = {this.setRuleDate2}
    setStartE = {this.setStartE}
    startE = {this.state.startE}
    changeSeasonalRule={this.changeSeasonalRule}
    details={this.state.details}
    changeAfter = {this.changeAfter} 
    userToasterColor = {this.state.userToasterColor}
    userToaster = {this.state.userToaster}
    userToasterMsg = {this.state.userToasterMsg}            
    />

    return (
      <>
      {/* {<SeasonalMessageDialog
        show={this.state.seasonalConfirmationModalIsOpen}
        onclose = {this.SeasonalMessageDialogClose}
        // title = {"Confirm your action"}
        message= {"Email template edited successfully"}
        title={"Alert"}
      />} */}
      {
      this.state.active_rule==7 &&
      <SeasonalModal 
      show={this.state.seasonalModalIsOpen}
      onclose = {this.seasonalModalClose}
      title = {"Seasonal Rules"}
      body={Seasonalbody}
      modalSize = {true}
      loading = {this.state.loading}
      tab={this.state.tab}
      rule_data = {this.state.rule_data}
      resetRulesConfirm= {this.resetRulesConfirm}
      saveEditor = {this.saveEditor}
      />}
      <div
        className="kt-container kt-body  kt-grid kt-grid--ver completedchatmain temlatenewadmin"
        id="kt_body"
      >
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className="kt-content kt-grid__item kt-grid__item--fluid yr-mt-20 yr-manage-contact">
            {this.state.loading && <div className="ui loading form"></div>}
            {this.state.active_rule!=7 && <Message
              color={
                this.state.userToasterColor
                  ? this.state.userToasterColor
                  : "teal"
              }
              style={{
                display: this.state.userToaster ? "block" : "none",
                marginTop: 30,
              }}
            >
              {this.state.userToasterMsg}
            </Message>}

            <div className="mt-4 email-customization">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-lg-3">
                      {this.state.list_load && (
                        <EmailListNew
                          addNewTemplate={this.addNewTemplate}
                          active_id={this.state.selectedEditor}
                          openAttr={this.openAttr}
                          emaillist={this.state.details}
                          domainList={this.state.domainList}
                          textList={this.state.textList}
                          indextime={new Date()}
                          pid={this.state.pid}
                          updateList={this.updateList}
                          editor_update={this.state.editor_update}
                          updateEditorStatus={this.updateEditorStatus}
                          updateEmailChanges={this.updateEmailChanges}
                          updatePhoneChanges={this.updatePhoneChanges}
                          sendmailfrom={this.state.sendmailfrom}
                          sendtextfrom={this.state.sendtextfrom}
                          showToaster={this.showToaster}
                          playbookStatus={this.state.pbookDetails.status}
                          onStartCampaign={this.onStartCampaign}
                          playbookDetail={this.state.pbookDetails}
                          updateBranchListSequense={
                            this.updateBranchListSequenseprop
                          }
                          type={types}
                          canStartPlaybook={this.state.canStartPlaybook}
                        />
                      )}
                    </div>

                    <div className="col-lg-9 uppertestvisible">
                      <div
                        className={
                          this.state.template_type == 2
                            ? "hiddenvisibility"
                            : ""
                        }
                      >
                        <div
                          className="kt-portlet yr-chat-round-box kt-portlet--tabs"
                          style={{
                            paddingTop: 4,
                            paddingLeft: 15,
                            paddingRight: 15,
                          }}
                        >
                          <div className="kt-portlet__head yr-pd0-lr yr-pd0-mob">
                            <div className="kt-portlet__head-toolbar">
                              <ul
                                className="nav nav-tabs nav-tabs-line nav-tabs-line-success nav-tabs-line-3x"
                                role="tablist"
                              >
                                <li className="nav-item">
                                  <a
                                    className="nav-link active"
                                    id="contenttab"
                                    onClick={(e) => this.changesTab("content")}
                                    tab="content"
                                    data-toggle="tab"
                                    href="#kt_portlet_base_demo_1_1_tab_content"
                                    role="tab"
                                  >
                                    Content
                                  </a>
                                </li>
                                <li
                                  className="nav-item"
                                  tab="rules"
                                  onClick={(e) => this.saveAlert("Open")}
                                >
                                  <a
                                    className="nav-link"
                                    id="rule"
                                    href="#kt_portlet_base_demo_1_2_tab_content"
                                    role="tab"
                                  >
                                    Rules
                                  </a>
                                </li>
                              
                                <li
                                  className={ this.state.template_type==2 ? 'nav-item hiddenvisibility':'nav-item'}
                                  tab="sendingQueue"
                                  onClick={(e) => this.openTab("3")}
                                >
                                  <a
                                    className="nav-link"
                                    id="sendingQueue"
                                    href="#kt_portlet_base_demo_1_2_tab_sendingQueue"
                                    role="tab"
                                  >
                                    Sending Queue
                                  </a>
                                </li>
  
                              </ul>
                            </div>
                            <div>
                              {this.state.tab == "2" &&
                                this.state.rule_data.setRule == true && this.state.active_rule !==7 && (
                                  <button
                                    type="button"
                                    style={{ marginTop: 10 }}
                                    className="btn linear-gradient zy-save-btn ml-2"
                                    onClick={() => this.resetRulesConfirm()}
                                  >
                                    Reset Rules
                                  </button>
                                )}
                              {(this.state.tab == "3" && this.state.variables.length>0 && this.state.pbookDetails.status=='Active' && this.state.template_type===1 ) && (
                                  <button
                                    type="button"
                                    style={{ marginTop: 10 }}
                                    className="btn linear-gradient zy-save-btn ml-2"
                                    onClick={() => this.OpenAddContactModel()}
                                  >
                                    Add Contacts
                                  </button>
                                )}

                                {this.state.tab == "2" && this.state.template_type===1 && (
                                <button
                                  type="button"
                                  style={{ marginTop: 10 }}
                                  className="btn linear-gradient zy-save-btn ml-2"
                                  // onClick={() => this.openRule(7)}
                                  onClick={()=>this.openRule(7)}
                                >
                                  Seasonal Rules
                                </button>
                              )}

                              {this.state.tab != "3" && this.state.active_rule !=7 && (
                                <button
                                  type="button"
                                  style={{ marginTop: 10 }}
                                  className="btn linear-gradient zy-save-btn ml-2"
                                  onClick={() => this.saveEditor()}
                                >
                                  Save
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="kt-portlet__body yr-pd0-lr">
                            <div className="tab-content">
                              <div
                                className="tab-pane active"
                                id="kt_portlet_base_demo_1_1_tab_content"
                                role="tabpanel"
                              >

                               {this.state.template_type==3 &&  <div className={this.state.template_type != 3 ? 'hiddenvisibility' : ''}>
                                  <SmsTemplate activetab="sms" handleSmsData={this.handleSmsData} errors={this.state.errors} smsData={this.state.smsContent} smsvars={this.state.branch_variables} pid={this.state.pid} selectedEditor={this.state.selectedEditor}  />
                                </div>}
                                
                                <div className={this.state.template_type != 1 ? 'hiddenvisibility' : ''}>
                                  <div className="add-branch-title mb-3">
                                    Add/Edit Email
                                  </div>
                                  <div className="yr-email-s-line">
                                    <div style={{ width: "100%" }}>
                                      <div
                                        class="kt-input-icon kt-input-icon--right header-search kt-input-icon--right"
                                        style={{ width: "100%" }}
                                      >
                                        <ContentEditable
                                          innerRef={this.contentEditable}
                                          html={this.state.emailSubject} // innerHTML of the editable div
                                          disabled={false} // use true to disable editing
                                          onChange={this.handleSubjectChange} // handle innerHTML change
                                          tagName="div" // Use a custom HTML tag (uses a div by default)
                                          className="contenteditable"
                                          onClick={(e) => this.onClickContentEditable(e)}
                                        />
                                      </div>
                                      {errors &&
                                        errors.hasOwnProperty(
                                          "emailSubject"
                                        ) && (
                                          <div className="red">
                                            {errors["emailSubject"]}
                                          </div>
                                        )}
                                    </div>
                                    <input
                                      type="hidden"
                                      id="email_play_book_id"
                                      value={this.state.pid}
                                    ></input>
                                    {/* <span>Email subject line</span> */}
                                    <div
                                      className=""
                                      style={{
                                        paddingTop: "6px",
                                        position: "relative",
                                      }}
                                    >
                                      {(this.state.details.variables && Object.keys(this.state.details.variables).length > 0) && 
                                      <a
                                        className="btn linear-gradient btn-icon dropdown-toggle yr-managebtn cursorpointer"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        Insert Variable{" "}
                                        <span>
                                          {/* <i className="fa fa-arrow-down"></i> */}
                                        </span>
                                      </a>}
                                      <div
                                        className="dropdown-menu dropdown-menu-right yr-custom-menu"
                                        style={{
                                          minWidth: "250px",
                                          maxHeight: "300px",
                                          overflowY: "scroll",
                                          zIndex: "100",
                                        }}
                                      >
                                        <ul>
                                          {Object.keys(this.state.variables)
                                            .length > 0 &&
                                            Object.keys(
                                              this.state.variables
                                            ).map((obj, y) => {
                                              return (
                                                <li
                                                  onClick={(e) =>
                                                    this.onchangeVariable(
                                                      "{{" +
                                                      this.state.variables[
                                                      obj
                                                      ] +
                                                      "}}",
                                                      obj,
                                                      this.state.variables[
                                                      obj
                                                      ]
                                                    )
                                                  }
                                                  key={y}
                                                >
                                                  <a className="cursorpointer">
                                                    <span>
                                                      {
                                                        this.state.variables[
                                                        obj
                                                        ]
                                                      }
                                                    </span>
                                                  </a>
                                                </li>
                                              );
                                            })}

                                          {/* <li onClick={(e) => this.onchangeVariable('[[company]]')}>
                                            <a  href="javascript:void(0);">
                                                <span>Company</span>
                                            </a>
                                        </li>	 */}
                                        </ul>
                                      </div>
                                    </div>
                                    {/* end varialbes */}
                                  </div>

                                  <input
                                    type="hidden"
                                    id="selected_editor_id"
                                    value={this.state.selectedEditor}
                                  />

                                  <div className="row" id="grapeJsContainer">
                                    <div className="col-md-12">
                                      {this.state.editorLoad && (
                                        <UnlayerEditor
                                          setAltVal={this.setAltVal}
                                          updateContentNewEditor={
                                            this.updateContentNewEditor
                                          }
                                          selectedEditorContent_JSON={
                                            this.state
                                              .selectedEditorContent_JSON
                                          }
                                          editorUseEffectRun={
                                            this.state.editorUseEffectRun
                                          }
                                          editorUseEffectRunChange={
                                            this.editorUseEffectRunChange
                                          }
                                          openEmailPop={this.openEmailPop}
                                          variableValue={this.state.variables}
                                          addAltValue={this.addAltValue}
                                          customVariableValue={
                                            this.state.customVariableValue
                                          }
                                          handleCustomVariableChange={
                                            this.handleCustomVariableChange
                                          }
                                        />
                                      )}
                                      {/* <div width="100%" height="700px" id="gjs"></div> */}
                                    </div>
                                  </div>
                                </div>

                              </div>
                              {this.state.loadingrule && (
                                <div className="ui loading form"></div>
                              )}
                              <div
                                className="tab-pane"
                                id="kt_portlet_base_demo_1_2_tab_content"
                                role="tabpanel"
                              >
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div style={{ overflow: "inherit" }}>
                                      {/* {!(this.state.active_rule == 7) && <> */}
                                      <label className="bold">
                                        Add branch rules
                                      </label>
                                      <div className="form-group rulesmarketing">
                                        <a
                                          className={
                                            this.state.active_rule == 5
                                              ? "add-gradient mb-3 activebuttonlink otherbuttonlinks"
                                              : "add-gradient mb-3  otherbuttonlinks"
                                          }
                                          style={{ color: "#087ab6" }}
                                          onClick={() => this.openRule(5)}
                                        >
                                          + { this.state.template_type!=3 ? 'Next Email/Text rules' :'Next Email/Text rules'}
                                        </a>
                                        {(this.state.template_type==1 || this.state.template_type==2) ? <a
                                          className={
                                            this.state.active_rule == 1
                                              ? "add-gradient mb-3 activebuttonlink"
                                              : "add-gradient mb-3"
                                          }
                                          style={{
                                            color: "#087ab6",
                                            maxWidth: "250px",
                                          }}
                                          onClick={() => this.openRule(1)}
                                          id="buttonruleclick"
                                        >
                                          + Button click rules (
                                          {this.state.rule_data.buttonrulelist}{" "}
                                          rules Added)
                                        </a> : '' }

                                       {(this.state.template_type==1 || this.state.template_type==2) ? <a
                                          className={
                                            this.state.active_rule == 2
                                              ? "add-gradient mb-3 activebuttonlink otherbuttonlinks"
                                              : "add-gradient mb-3  otherbuttonlinks"
                                          }
                                          style={{ color: "#087ab6" }}
                                          onClick={() => this.openRule(2)}
                                        >
                                          + Email open rules
                                        </a> :""}
                                        {(this.state.template_type==1 || this.state.template_type==2) ? <a
                                          className={
                                            this.state.active_rule == 3
                                              ? "add-gradient mb-3 activebuttonlink otherbuttonlinks"
                                              : "add-gradient mb-3  otherbuttonlinks"
                                          }
                                          style={{ color: "#087ab6" }}
                                          onClick={() => this.openRule(3)}
                                        >
                                          + No open rules
                                        </a> : '' }
                                        {(this.state.template_type==1 || this.state.template_type==2) ? <a
                                          className={
                                            this.state.active_rule == 4
                                              ? "add-gradient mb-3 activebuttonlink otherbuttonlinks"
                                              : "add-gradient mb-3  otherbuttonlinks"
                                          }
                                          style={{ color: "#087ab6" }}
                                          onClick={() => this.openRule(4)}
                                        >
                                          + Call list rules
                                        </a> : ''}

                                        {/* {(this.state.template_type==1 || this.state.template_type==2) ? <a
                                          className={
                                            this.state.active_rule == 7
                                              ? "add-gradient mb-3 activebuttonlink otherbuttonlinks"
                                              : "add-gradient mb-3  otherbuttonlinks"
                                          }
                                          style={{ color: "#087ab6" }}
                                          onClick={() => this.openRule(7)}
                                        >
                                          + Seasonal rules
                                        </a> :''} */}
                                      </div>
                                      {/* </>} */}
                                      
                                      {(this.state.active_rule!=7) &&(this.state.active_rule == 1 ||
                                        this.state.active_rule == 2 ||
                                        this.state.active_rule == 3 ||
                                        this.state.active_rule == 5) && (
                                          <div className="firstrules">
                                            {this.state.active_rule == 1 && (
                                              <div className="form-group">
                                                <label className="bold">
                                                  Add a click rule{" "}
                                                </label>
                                                <label
                                                  style={{ paddingLeft: "5px" }}
                                                >
                                                  {" "}
                                                  If the following link or button
                                                  is clicked
                                                </label>
                                                <select
                                                  className={
                                                    Object.keys(
                                                      this.state.ruleerrors
                                                    ).length > 0 &&
                                                      typeof this.state.ruleerrors[
                                                      "button_id"
                                                      ] != "undefined"
                                                      ? "form-control is-invalid focusError"
                                                      : "form-control"
                                                  }
                                                  name="button_id"
                                                  onChange={(e) =>
                                                    this.changeRule(
                                                      "button",
                                                      e,
                                                      "none"
                                                    )
                                                  }
                                                  value={
                                                    typeof this.state.rule_data
                                                      .button_id != "udefined"
                                                      ? this.state.rule_data
                                                        .button_id
                                                      : ""
                                                  }
                                                >
                                                  <option value="">Select</option>
                                                  {typeof this.state
                                                    .template_buttons !=
                                                    "undefined" &&
                                                    this.state.template_buttons
                                                      .length > 0 &&
                                                    this.state.template_buttons.map(
                                                      (obj, index) => {
                                                        return (
                                                          <option
                                                            value={obj.auto_id}
                                                            key={obj.auto_id}
                                                          >
                                                            {obj.title}{" "}
                                                            {obj.url != "" &&
                                                              " || " + obj.url}
                                                          </option>
                                                        );
                                                      }
                                                    )}
                                                </select>

                                                {ruleerrors &&
                                                  ruleerrors.hasOwnProperty(
                                                    "button_id"
                                                  ) && (
                                                    <div className="red">
                                                      {ruleerrors["button_id"]}
                                                    </div>
                                                  )}
                                              </div>
                                            )}

                                            {(this.state.active_rule == 5 && this.state.template_type!=3 ) && (
                                              <div className="calllistrule">
                                                <div className="bold calllistsecond">
                                                 Next Email/Text Rules
                                                </div>
                                                <div className="form-group rulesmarketing nextemaillinkradio">
                                                  <a
                                                    className="add-gradient mb-3"
                                                    style={{ color: "#087ab6" }}
                                                  >
                                                    <label class="kt-radio kt-radio--single chat-checkbox checboxrules">
                                                      <input
                                                        type="radio"
                                                        name="next_email_rules"
                                                        value="1"
                                                        checked={
                                                          this.state.rule_data
                                                            .next_email_rules == 1
                                                            ? true
                                                            : false
                                                        }
                                                        onClick={(e) =>
                                                          this.changeRule(
                                                            "next_email_rules",
                                                            e,
                                                            "none"
                                                          )
                                                        }
                                                      />
                                                      <span></span>
                                                    </label>
                                                    <span className="textrule">
                                                      Send the next email/text to all
                                                      recipients regardless of
                                                      other rules
                                                    </span>{" "}
                                                  </a>
                                                  <a
                                                    className="add-gradient mb-3"
                                                    style={{ color: "#087ab6" }}
                                                  >
                                                    <label class="kt-radio kt-radio--single chat-checkbox checboxrules">
                                                      <input
                                                        type="radio"
                                                        name="next_email_rules"
                                                        value="2"
                                                        checked={
                                                          this.state.rule_data
                                                            .next_email_rules == 2
                                                            ? true
                                                            : false
                                                        }
                                                        onClick={(e) =>
                                                          this.changeRule(
                                                            "next_email_rules",
                                                            e,
                                                            "none"
                                                          )
                                                        }
                                                      />
                                                      <span></span>
                                                    </label>
                                                    <span className="textrule">
                                                      Only send the next email/text to
                                                      all users who don't fall
                                                      into other rules
                                                    </span>{" "}
                                                  </a>
                                                </div>
                                                <div
                                                  style={{ display: "none" }}
                                                  className={
                                                    Object.keys(
                                                      this.state.ruleerrors
                                                    ).length > 0 &&
                                                      typeof this.state.ruleerrors[
                                                      "next_email_rules"
                                                      ] != "undefined"
                                                      ? "form-control is-invalid focusError"
                                                      : "form-control"
                                                  }
                                                ></div>
                                                {ruleerrors &&
                                                  ruleerrors.hasOwnProperty(
                                                    "next_email_rules"
                                                  ) && (
                                                    <div className="red">
                                                      {
                                                        ruleerrors[
                                                        "next_email_rules"
                                                        ]
                                                      }
                                                    </div>
                                                  )}
                                              </div>
                                            )}
                                            <div className="form-group">
                                              <div className="bold">THEN</div>
                                              <label>Send to email/sms:</label>
                                              <select
                                                className={
                                                  Object.keys(
                                                    this.state.ruleerrors
                                                  ).length > 0 &&
                                                    typeof this.state.ruleerrors[
                                                    "send_email_branch"
                                                    ] != "undefined"
                                                    ? "form-control is-invalid focusError"
                                                    : "form-control"
                                                }
                                                name="send_email_branch"
                                                onChange={(e) =>
                                                  this.changeRule(
                                                    "send_email_branch",
                                                    e,
                                                    "none"
                                                  )
                                                }
                                                value={
                                                  typeof this.state.rule_data
                                                    .send_email_branch !=
                                                    "udefined"
                                                    ? this.state.rule_data
                                                      .send_email_branch
                                                    : ""
                                                }
                                              >
                                                <option value="">Select</option>
                                                {typeof this.state.details.list !=
                                                  "undefined" &&
                                                  this.state.details.list.length >
                                                  0 &&
                                                  this.state.details.list.map(
                                                    (obj, index) => {
                                                      return (
                                                        <option
                                                          value={obj.id}
                                                          key={obj.id}
                                                        >
                                                          {obj.title}{" "}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                              </select>
                                              {ruleerrors &&
                                                ruleerrors.hasOwnProperty(
                                                  "send_email_branch"
                                                ) && (
                                                  <div className="red">
                                                    {
                                                      ruleerrors[
                                                      "send_email_branch"
                                                      ]
                                                    }
                                                  </div>
                                                )}
                                            </div>

                                            <div className="form-group">
                                              <label>After:</label>
                                              <div className="row">
                                                <div className="col-md-6">
                                                  <select
                                                    className={
                                                      Object.keys(
                                                        this.state.ruleerrors
                                                      ).length > 0 &&
                                                        typeof this.state
                                                          .ruleerrors[
                                                        "after_time"
                                                        ] != "undefined"
                                                        ? "form-control is-invalid focusError"
                                                        : "form-control"
                                                    }
                                                    name="after_time"
                                                    onChange={(e) =>
                                                      this.changeRule(
                                                        "after_time",
                                                        e,
                                                        "after"
                                                      )
                                                    }
                                                    value={
                                                      typeof this.state.rule_data
                                                        .after_time != "udefined"
                                                        ? this.state.rule_data
                                                          .after_time
                                                        : ""
                                                    }
                                                  >
                                                    <option value="">
                                                      Select
                                                    </option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    {/* <option value="customDate">Date Time</option> */}
                                                  </select>
                                                  {ruleerrors &&
                                                    ruleerrors.hasOwnProperty(
                                                      "after_time"
                                                    ) && (
                                                      <div className="red">
                                                        {ruleerrors["after_time"]}
                                                      </div>
                                                    )}
                                                </div>
                                                {this.state.setDateinAfter ==
                                                  false ? (
                                                  <div className="col-md-6">
                                                    <select
                                                      className={
                                                        this.state
                                                          .setDateinAfter ==
                                                          false &&
                                                          Object.keys(
                                                            this.state.ruleerrors
                                                          ).length > 0 &&
                                                          typeof this.state
                                                            .ruleerrors[
                                                          "duration_week"
                                                          ] != "undefined"
                                                          ? "form-control is-invalid focusError"
                                                          : "form-control"
                                                      }
                                                      name="duration_week"
                                                      onChange={(e) =>
                                                        this.changeRule(
                                                          "duration_week",
                                                          e,
                                                          "none"
                                                        )
                                                      }
                                                      value={
                                                        typeof this.state
                                                          .rule_data
                                                          .duration_week !=
                                                          "udefined"
                                                          ? this.state.rule_data
                                                            .duration_week
                                                          : ""
                                                      }
                                                    >
                                                      <option value="">
                                                        Select
                                                      </option>
                                                      <option value="days">
                                                        days
                                                      </option>
                                                      <option value="week">
                                                        week
                                                      </option>
                                                      <option value="month">
                                                        month
                                                      </option>
                                                      <option value="year">
                                                        year
                                                      </option>
                                                    </select>
                                                    {this.state.setDateinAfter ==
                                                      false &&
                                                      ruleerrors &&
                                                      ruleerrors.hasOwnProperty(
                                                        "duration_week"
                                                      ) && (
                                                        <div className="red">
                                                          {
                                                            ruleerrors[
                                                            "duration_week"
                                                            ]
                                                          }
                                                        </div>
                                                      )}
                                                  </div>
                                                ) : (
                                                  <div className="col-md-6 mh-datePickerWrapperInTemplate">
                                                    <DatePickerComp
                                                      scheduledateEmpty={
                                                        this.state
                                                          .scheduledateEmpty
                                                      }
                                                      changeDate={this.changeDate}
                                                      scheduleStartDate={
                                                        this.state
                                                          .scheduleStartDate
                                                      }
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        )}

                                      {this.state.active_rule == 4 && (
                                        <div className="calllistrule">
                                          <div className="bold calllistsecond">
                                            Add Recipient to "Your call list"
                                            if:
                                          </div>
                                          <div className="form-group rulesmarketing">
                                            <a
                                              className="add-gradient mb-3"
                                              style={{ color: "#087ab6" }}
                                            >
                                              <label class="kt-checkbox kt-checkbox--single chat-checkbox checboxrules">
                                                <input
                                                  type="checkbox"
                                                  name="email_sent"
                                                  checked={
                                                    this.state.rule_data
                                                      .email_sent == 1
                                                      ? true
                                                      : false
                                                  }
                                                  onClick={(e) =>
                                                    this.changeRule(
                                                      "email_sent",
                                                      e,
                                                      "none"
                                                    )
                                                  }
                                                />
                                                <span></span>
                                              </label>
                                              <span className="textrule custom-textRule">
                                                Email was successfully sent
                                              </span>{" "}
                                            </a>
                                            <a
                                              className="add-gradient mb-3"
                                              style={{ color: "#087ab6" }}
                                            >
                                              <label class="kt-checkbox kt-checkbox--single chat-checkbox checboxrules">
                                                <input
                                                  type="checkbox"
                                                  name="email_opened"
                                                  checked={
                                                    this.state.rule_data
                                                      .email_opened == 1
                                                      ? true
                                                      : false
                                                  }
                                                  onClick={(e) =>
                                                    this.changeRule(
                                                      "email_opened",
                                                      e,
                                                      "none"
                                                    )
                                                  }
                                                />
                                                <span></span>
                                              </label>
                                              <span className="textrule custom-textRule">
                                                Email is opened
                                              </span>{" "}
                                            </a>
                                            <a
                                              className="add-gradient mb-3"
                                              style={{ color: "#087ab6" }}
                                            >
                                              <label class="kt-checkbox kt-checkbox--single chat-checkbox checboxrules">
                                                <input
                                                  type="checkbox"
                                                  name="button_link_clicked"
                                                  checked={
                                                    this.state.rule_data
                                                      .button_link_clicked == 1
                                                      ? true
                                                      : false
                                                  }
                                                  onClick={(e) =>
                                                    this.changeRule(
                                                      "button_link_clicked",
                                                      e,
                                                      "none"
                                                    )
                                                  }
                                                />
                                                <span></span>
                                              </label>
                                              <span className="textrule custom-textRule">
                                                A button or link is clicked
                                              </span>{" "}
                                            </a>
                                          </div>
                                          <div
                                            style={{ display: "none" }}
                                            className={
                                              Object.keys(this.state.ruleerrors)
                                                .length > 0 &&
                                                typeof this.state.ruleerrors[
                                                "calllisterror"
                                                ] != "undefined"
                                                ? "form-control is-invalid focusError"
                                                : "form-control"
                                            }
                                          ></div>
                                          {ruleerrors &&
                                            ruleerrors.hasOwnProperty(
                                              "calllisterror"
                                            ) && (
                                              <div className="red">
                                                {ruleerrors["calllisterror"]}
                                              </div>
                                            )}
                                        </div>
                                      )}
                                      
                                      {/* {this.state.active_rule == 7 && (
                                        <div className="calllistrule">
                                          <div className="form-group">
                                            <a
                                              className={"add-gradient mb-3"}
                                              style={{ color: "#087ab6" }}
                                              onClick={() =>
                                                this.addSeasonalRule("add", "")
                                              }
                                            >
                                              + Add additional timeframe
                                            </a>
                                          </div>
                                        </div>
                                      )} */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tab-pane"
                                id="kt_portlet_base_demo_1_2_tab_sendingQueue"
                                role="tabpanel"
                              >
                                {this.state.loading == false && (
                                  <SendingQueue
                                    playbook_id={util_funs.dcrypt_url(this.props.match.params.id)}
                                    branch_id={this.state.selectedEditor}
                                    template_type={this.state.template_type}
                                    playbookStatus = {this.state.pbookDetails.status}
                                    key={this.state.sendquekey}
                                  ></SendingQueue>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={
                          this.state.template_type == 2
                            ? ""
                            : "hiddenvisibility"
                        }
                      >
                        {this.state.direct_rule_loding && (
                          <div className="ui loading form"></div>
                        )}

                        <div>
                          <div class="row">
                            <div class="col-md-9">
                              <h2 className="mb-4 mt-0 text-dark px-2">
                                Add Edit / Direct Email
                              </h2>
                            </div>
                            {this.state.showrules && (
                              <div class="col-md-3 text-right">
                                <button
                                  type="button"
                                  className="btn linear-gradient zy-save-btn ml-2"
                                  onClick={() => this.saveDirectEmail()}
                                >
                                  Save
                                </button>
                              </div>
                            )}
                            <div className="col-lg-12">
                              <ul
                                className="nav nav-tabs nav-tabs-line nav-tabs-line-success nav-tabs-line-3x"
                                role="tablist"
                              >
                                <li className="nav-item">
                                  <a
                                    className={`nav-link ${activetab == "directmail" ? "active" : ""
                                      }`}
                                    href="#directmailview"
                                    data-toggle="tab"
                                    role="tab"
                                    onClick={(e) =>
                                      this.showActiveTab("directmail", e)
                                    }
                                  >
                                    Direct Mail
                                  </a>
                                </li>
                                {/* <li className="nav-item">
                                  <a
                                    className={`nav-link ${
                                      activetab == "sendingqueueDirectmail"
                                        ? "active"
                                        : ""
                                    }`}
                                    href="#sendingqueueDirectmailView"
                                    data-toggle="tab"
                                    role="tab"
                                    onClick={(e) =>
                                      this.showActiveTab(
                                        "sendingqueueDirectmail",
                                        e
                                      )
                                    }
                                  >
                                    Sending Queue
                                  </a>
                                </li> */}
                              </ul>

                              <div className="tab-content">
                                <div
                                  className={`tab-pane ${activetab == "directmail" ? "active" : ""
                                    }`}
                                  id="directmailview"
                                  role="tabpanel"
                                >
                                  {this.state.direct_rule_message != false && (
                                    <div style={{ color: "red" }}>
                                      {this.state.direct_rule_message}
                                    </div>
                                  )}

                                  <div
                                    style={{
                                      backgroundColor: "#efefef",
                                      padding: "10px",
                                    }}
                                  >
                                    {this.state.showrules == false && (
                                      <div>
                                        {this.state.post_card_list.length > 0 ?
                                          <>
                                            <div className="d-flex justify-content-between align-items-center my-2">
                                              <p className="my-0 text--blue">
                                                Select which template to send:
                                              </p>
                                            </div>
                                            <div className="card border py-3 px-2">
                                              <ul className="postcard--list d-flex flex-wrap">
                                                {
                                                  this.state.post_card_list.map(
                                                    (el) => (
                                                      <li
                                                        key={el.auto_id}
                                                        title={el.title}
                                                        className={
                                                          this.state.postcard_id ==
                                                            el.auto_id
                                                            ? "postcard--listitem border_highlighted"
                                                            : "postcard--listitem"
                                                        }
                                                      >
                                                        <a
                                                          onClick={(e) =>
                                                            this.loadDirectEmailRules(
                                                              e,
                                                              el.auto_id
                                                            )
                                                          }
                                                        >
                                                          <img
                                                            src={
                                                              typeof el.post_card_url !=
                                                                "undefined" &&
                                                                el.post_card_url != ""
                                                                ? el.post_card_url
                                                                : "https://via.placeholder.com/1024x768"
                                                            }
                                                            className="img-fluid"
                                                            alt=""
                                                          />
                                                        </a>
                                                      </li>
                                                    )
                                                  )}
                                              </ul>

                                              <div className="d-flex justify-content-between px-4">
                                                <nav aria-label="Page navigation example">
                                                  {this.state.paginationLoad && this.state.pageCount > 1 && (
                                                    <ReactPaginate
                                                      previousLabel={"Prev"}
                                                      nextLabel={"Next"}
                                                      breakLabel={"..."}
                                                      breakClassName={"break-me"}
                                                      pageCount={this.state.pageCount}
                                                      marginPagesDisplayed={2}
                                                      pageRangeDisplayed={5}
                                                      onPageChange={
                                                        this.handlePageClick
                                                      }
                                                      containerClassName={
                                                        "pagination small"
                                                      }
                                                      activeClassName={"active"}
                                                      pageClassName={"page-item"}
                                                      pageLinkClassName={
                                                        "page-link"
                                                      }
                                                      previousClassName={
                                                        "page-link"
                                                      }
                                                      nextClassName={"page-item"}
                                                      previousLinkClassName={
                                                        "page-item"
                                                      }
                                                      nextLinkClassName={
                                                        "page-link"
                                                      }
                                                      initialPage={
                                                        this.state.initialPage
                                                      }
                                                      forcePage={
                                                        this.state.initialPage
                                                      }
                                                      renderOnZeroPageCount={null}
                                                    />
                                                  )}
                                                </nav>

                                                <button
                                                  className="zy-save-btn linear-gradient addsendingrule"
                                                  onClick={() =>
                                                    this.openDirectRules()
                                                  }
                                                >
                                                  Edit Sending Rule
                                                </button>
                                              </div>
                                            </div>
                                          </> : this.state.dataLoaded &&
                                          <div className="text-center py-4">
                                            <h4 class="display-4">No Template Available.</h4>
                                            <Link to="/marketing/postcard" className="h4 text-success">Click here to add</Link>
                                          </div>
                                        }
                                      </div>
                                    )}

                                    {this.state.showrules && (
                                      <div className="card border-0">
                                        <div className="card-header py-3 d-flex justify-content-between align-items-center">
                                          <h4 className="fw-normal mb-0">
                                            Design and Rules
                                          </h4>
                                          <button
                                            className="zyra--btn zyra--primary linear-gradient"
                                            onClick={() =>
                                              this.backToTemplate()
                                            }
                                          >
                                            Back to Template
                                          </button>
                                        </div>
                                        <div className="card-body border bg-white p-3 pt-5">
                                          <div className="row">
                                            <div className="col-md-6">
                                              <div className="mb-4 d-flex justify-content-around align-items-center">
                                                <label className="PostCard_Subtitle">
                                                  Front Design <br></br>
                                                  Postcard:{" "}
                                                </label>

                                                <img
                                                  src={
                                                    typeof this.state
                                                      .template_info
                                                      .fornt_image_url !=
                                                      "undefined" &&
                                                      this.state.template_info
                                                        .fornt_image_url != ""
                                                      ? this.state.template_info
                                                        .fornt_image_url
                                                      : "https://via.placeholder.com/1024x768"
                                                  }
                                                  alt=""
                                                  className="postCardImg"
                                                />
                                              </div>
                                              <div className="d-flex justify-content-around align-items-center">
                                                <label className="PostCard_Subtitle">
                                                  Back Design <br></br>Postcard:{" "}
                                                </label>

                                                <img
                                                  src={
                                                    typeof this.state
                                                      .template_info.backurl
                                                      .data != "undefined" &&
                                                      this.state.template_info
                                                        .backurl.data
                                                        .handwriting_image != ""
                                                      ? this.state.template_info
                                                        .backurl.data
                                                        .handwriting_image
                                                      : "https://via.placeholder.com/1024x768"
                                                  }
                                                  alt=""
                                                  className="postCardImg"
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <h3 class="mt-3">Rules</h3>
                                              <hr />
                                              <div className="card border-0">
                                                <div className="card-header px-2 bg-transparent">
                                                  <p className="my-0 ">
                                                    After direct mail sends:
                                                  </p>
                                                </div>
                                                <div className="card-body p-4 border rounded">
                                                  <div className="mb-3">
                                                    <label className="form-label mb-0 text-blue">
                                                      Then:
                                                    </label>
                                                    <p className="small mb-1 text-muted">
                                                      Trigger this branch to
                                                      start
                                                    </p>
                                                    <select
                                                      className={
                                                        Object.keys(
                                                          this.state.ruleerrors
                                                        ).length > 0 &&
                                                          typeof this.state
                                                            .ruleerrors[
                                                          "send_email_branch"
                                                          ] != "undefined"
                                                          ? "form-control is-invalid focusError"
                                                          : "form-control"
                                                      }
                                                      name="send_email_branch"
                                                      onChange={(e) =>
                                                        this.changeRule(
                                                          "send_email_branch",
                                                          e,
                                                          "none"
                                                        )
                                                      }
                                                      value={
                                                        typeof this.state
                                                          .rule_data
                                                          .send_email_branch !=
                                                          "udefined"
                                                          ? this.state.rule_data
                                                            .send_email_branch
                                                          : ""
                                                      }
                                                    >
                                                      <option value="">
                                                        Select
                                                      </option>
                                                      {typeof this.state.details
                                                        .list != "undefined" &&
                                                        this.state.details.list
                                                          .length > 0 &&
                                                        this.state.details.list.map(
                                                          (obj, index) => {

                                                            return (
                                                              <option
                                                                value={obj.id}
                                                                key={obj.key}
                                                              >
                                                                {obj.title}{" "}
                                                              </option>
                                                            );
                                                          }
                                                        )}
                                                    </select>
                                                    {ruleerrors &&
                                                      ruleerrors.hasOwnProperty(
                                                        "send_email_branch"
                                                      ) && (
                                                        <div className="red">
                                                          {
                                                            ruleerrors[
                                                            "send_email_branch"
                                                            ]
                                                          }
                                                        </div>
                                                      )}
                                                  </div>
                                                  <div className="mb-3">
                                                    <label className="form-label mb-2 text-blue">
                                                      After:
                                                    </label>
                                                    <select
                                                      className={
                                                        Object.keys(
                                                          this.state.ruleerrors
                                                        ).length > 0 &&
                                                          typeof this.state
                                                            .ruleerrors[
                                                          "after_time"
                                                          ] != "undefined"
                                                          ? "form-control is-invalid focusError"
                                                          : "form-control"
                                                      }
                                                      name="after_time"
                                                      onChange={(e) =>
                                                        this.changeRule(
                                                          "after_time",
                                                          e,
                                                          "after"
                                                        )
                                                      }
                                                      value={
                                                        typeof this.state
                                                          .rule_data
                                                          .after_time !=
                                                          "udefined"
                                                          ? this.state.rule_data
                                                            .after_time
                                                          : ""
                                                      }
                                                    >
                                                      <option value="" >
                                                        Select
                                                      </option>
                                                      <option value="1">
                                                        1
                                                      </option>
                                                      <option value="2">
                                                        2
                                                      </option>
                                                      <option value="3">
                                                        3
                                                      </option>
                                                      {/* <option value="customDate">Date Time</option> */}
                                                    </select>
                                                    {ruleerrors &&
                                                      ruleerrors.hasOwnProperty(
                                                        "after_time"
                                                      ) && (
                                                        <div className="red">
                                                          {
                                                            ruleerrors[
                                                            "after_time"
                                                            ]
                                                          }
                                                        </div>
                                                      )}
                                                  </div>
                                                  <div className="mb-0">
                                                    <label className="form-label mb-2 text-blue">
                                                      Time Frame:
                                                    </label>

                                                    <select
                                                      className={
                                                        this.state
                                                          .setDateinAfter ==
                                                          false &&
                                                          Object.keys(
                                                            this.state.ruleerrors
                                                          ).length > 0 &&
                                                          typeof this.state
                                                            .ruleerrors[
                                                          "duration_week"
                                                          ] != "undefined"
                                                          ? "form-control is-invalid focusError"
                                                          : "form-control"
                                                      }
                                                      name="duration_week"
                                                      onChange={(e) =>
                                                        this.changeRule(
                                                          "duration_week",
                                                          e,
                                                          "none"
                                                        )
                                                      }
                                                      value={
                                                        typeof this.state
                                                          .rule_data
                                                          .duration_week !=
                                                          "udefined"
                                                          ? this.state.rule_data
                                                            .duration_week
                                                          : ""
                                                      }
                                                    >
                                                      <option value="">
                                                        Select
                                                      </option>
                                                      <option value="days">
                                                        days
                                                      </option>
                                                      <option value="week">
                                                        week
                                                      </option>
                                                      <option value="month">
                                                        month
                                                      </option>
                                                      <option value="year">
                                                        year
                                                      </option>
                                                    </select>
                                                    {this.state
                                                      .setDateinAfter ==
                                                      false &&
                                                      ruleerrors &&
                                                      ruleerrors.hasOwnProperty(
                                                        "duration_week"
                                                      ) && (
                                                        <div className="red">
                                                          {
                                                            ruleerrors[
                                                            "duration_week"
                                                            ]
                                                          }
                                                        </div>
                                                      )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                           <button className="zyra--btn border-2 zyra--primary-outline mt-4">Edit Content</button> 
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div
                                  className={`tab-pane ${activetab == "sendingqueueDirectmail"
                                      ? "active"
                                      : ""
                                    }`}
                                  id="sendingqueueDirectmailView"
                                  role="tabpanel"
                                >
                                  {this.state.loading == false && (
                                    <SendingQueue
                                      playbook_id={util_funs.dcrypt_url(this.props.match.params.id)}
                                      branch_id={this.state.selectedEditor}
                                    ></SendingQueue>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      this.state.dialogShow ? "modal fade show" : "modal fade"
                    }
                    data-backdrop="static"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="staticBackdrop"
                    aria-hidden="true"
                    style={{
                      display: this.state.dialogShow ? "block" : "none",
                    }}
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                      style={{ maxWidth: 600 }}
                    >
                      {this.state.dialogLoading && (
                        <div className="ui loading form"></div>
                      )}
                      <div className="modal-content">
                        <button
                          type="button"
                          className="close linear-gradient"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={(e) => this.saveAlert("Close")}
                        >
                          X
                        </button>
                        <div className="modal-header">
                          <h5 className="modal-title">Confirm your action</h5>
                        </div>
                        <div className="modal-body">
                          <div className="px-4">{this.state.dialogMessage}</div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                            onClick={(e) => this.saveAlert("Close")}
                            data-dismiss="modal"
                          >
                            <span>No</span>
                          </button>
                          <button
                            type="button"
                            className="btn linear-gradient yr-submitbtn btn-sm"
                            onClick={(e) => this.saveAlert("Yes")}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Start Email your template */}
                  {this.state.emailyourtemplate && (
                    <div
                      className={
                        this.state.emailyourtemplate
                          ? "modal fade show"
                          : "modal fade"
                      }
                      data-backdrop="static"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="staticBackdrop"
                      aria-hidden="true"
                      style={{
                        display: this.state.emailyourtemplate
                          ? "block"
                          : "none",
                      }}
                    >
                      <Form
                        noValidate
                        autoComplete="off"
                        id="sendNewsLetter"
                        onValidSubmit={this.onValidSubmitNewsletter}
                      >
                        <div
                          className="modal-dialog modal-dialog-centered"
                          role="document"
                          style={{ maxWidth: 600 }}
                        >
                          {this.state.emailyourtemplateLoading && (
                            <div className="ui loading form"></div>
                          )}
                          <div className="modal-content">
                            <button
                              type="button"
                              className="close linear-gradient"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => this.closeEmailPop()}
                            >
                              X
                            </button>
                            <div className="modal-header">
                              <h5 class="modal-title">Test your email</h5>
                            </div>
                            <div className="modal-body">
                              <div class="px-4">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                  <Form.Group widths="equal">
                                    <Form.Input
                                      fluid
                                      autoComplete="off"
                                      name="subject"
                                      value={this.state.templateSubject}
                                      onChange={(e) =>
                                        this.getTemplateSubject(e)
                                      }
                                      placeholder="Subject"
                                      validations="isRequired"
                                      validationErrors={{
                                        isRequired: "This field is required.",
                                      }}
                                      errorLabel={errorLabel}
                                    />
                                  </Form.Group>
                                  <Form.Group widths="equal">
                                    <Form.Input
                                      fluid
                                      autoComplete="off"
                                      name="email"
                                      value={this.state.templateEmailid}
                                      onChange={(e) =>
                                        this.getTemplateEmailid(e)
                                      }
                                      placeholder="Email"
                                      validations="isEmail,isRequired"
                                      validationErrors={{
                                        isEmail:
                                          "Please enter a valid email address.",
                                        isRequired: "This field is required.",
                                      }}
                                      errorLabel={errorLabel}
                                    />
                                  </Form.Group>
                                </div>
                              </div>
                            </div>

                            <div className="modal-footer">
                              <button
                                type="button"
                                className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                                onClick={(e) => this.closeEmailPop()}
                                data-dismiss="modal"
                              >
                                <span>Close</span>
                              </button>
                              <button
                                name="submit"
                                type="submit"
                                className="btn linear-gradient yr-submitbtn"
                              >
                                Send
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}

                  {/* End  Email your template */}
                </div>

                <Modal
                  show={this.state.modalaltstatus}
                  footer={this.modalFooter(this.state.modalindex)}
                  onclose={this.modalClose}
                  title={this.state.modaltitle}
                  body={this.state.modalbody}
                  loading={this.state.modalaltloading}
                />
                {this.state.addContactModal && <AddContactVia 
                  obj={this.state}
                  title="Add Contact"
                  onClose = {this.contactModalClose}
                  loading={this.state.modalaltloading}
                  userdata={this.props.user}
                  addEditModelopen={this.addEditModelopen}
                  />}

                 {this.state.addEditContactModal && <AddStaticContact 
                  obj={this.state}
                  title="Add Contact"
                  oncloseForm = {this.addEditModelClose}
                  loading={this.state.modalaltloading}
                  userdata={this.props.user}
                  onValidSubmitall={this.onValidSubmitall}
                  onValidSubmitCSV={this.onValidSubmitCSV}
                  />}

                <DialogBoxDetailOnCSVUploadError
                          obj={this.state}
                          show={this.state.modalstatusCSVfailure}
                          title="CSV upload error"
                          message="Are you sure you want to delete?"
                          modalType="Details"
                          modalCloseCSVError={this.modalCloseCSVError}
                          buttonmsg={{ submit: "Yes", cancel: "No" }}
                          //modalbuttofun={this.modalbuttofun}
                          loading={this.state.loading}
                        />
                
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
})(EmailTemplate);
