import React from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";

import config from "../../../config";
import { setUserLoggedOut } from "../../../store/User";
import { setPageLoading } from "../../../store/Page";
import util from "../../../util";
import api from "../../../api";

class UserInfo extends React.Component {

  logout = () => {
    this.props.setPageLoading({ isLoading: true });
    if (util.getAuthTokens().access_token) {
      this.props.setUserLoggedOut({ user: null });
      util.removeAuthTokens();
      this.props.setPageLoading({ isLoading: false });
        if(typeof this.props.user.whitelabelurl=='undefined'|| typeof this.props.user.whitelabelurl=='')
        window.location.assign(config.authLogoutUrl);
        else
        window.location.assign(this.props.user.whitelabelurl);
    } else {
      this.props.setPageLoading({ isLoading: false });
      if(typeof this.props.user.whitelabelurl=='undefined'|| typeof this.props.user.whitelabelurl=='')
         window.location.assign(config.authLogoutUrl);
      else
         window.location.assign(this.props.user.whitelabelurl);
    
    }
  };

  changeToLegacy = () => {
    api.user.changeToLegacy().then(data => {

      try {

        window.location.href = data.data.url;

      } catch (err) {
        console.log(err);
      }

    });
  }
  render() {
    const { user } = this.props;
    const { from } = this.props;
    var sidebar_icon_color = user.customizedDashboard[0].sidebar_icon_color;
    //alert(sidebar_icon_color);
    var display = (from == "web") ? "block" : "none";
    var arrowClass = (from == "mobile") ? "mh-mobileHeadrProfileInfo yr-topbar-icon" : "yr-topbar-icon";


    return !user ? (
      ""
    ) : (
      <div
        className="kt-header__topbar-item kt-header__topbar-item--user"
        style={{ marginLeft: 10 }}
      >
        <div
          className="kt-header__topbar-wrapper"
          data-toggle="dropdown"
          data-offset="0px,10px"
        >
          {/* <span class="kt-header__topbar-welcome kt-visible-desktop">Hi,</span> */}
          <img style={{ display: display }} alt="Pic" src={user.logoUrl} width="100px" />
          <span className="kt-header__topbar-icon kt-bg-brand kt-font-lg kt-font-bold kt-font-light kt-hidden">
            S
          </span>
          <span className="kt-header__topbar-icon kt-hidden">
            <i className="flaticon2-user-outline-symbol" />
          </span>
          <span
            className="kt-header__topbar-username kt-visible-desktop"
            style={{ marginLeft: 10, display: display, color: sidebar_icon_color }}
          >
            {(display == "block") ? user.name : ""}
          </span>
          <span className={arrowClass}>
            <i className="flaticon2-down-arrow" style={{ color: sidebar_icon_color }} />
          </span>
        </div>
        <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-xl">
          {/*begin: Head */}
          <div className="kt-user-card kt-user-card--skin-light kt-notification-item-padding-x">
            <div className="kt-user-card__avatar">

              {/*use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) */}
              <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold kt-hidden">
                S
              </span>
            </div>
            {/* Added By Rohit */}
            <div className="kt-user-card__name" style={{ color: sidebar_icon_color }}>{user.name}</div>

          </div>
          {/*end: Head */}
          {/*begin: Navigation */}
          <div className="kt-notification">
            <Link to="/settings" className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-calendar-3 kt-font-success" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Profile
                </div>
                <div className="kt-notification__item-time">
                  Account settings and more
                </div>
              </div>
            </Link>



            {/* <a style={{ cursor: "pointer" }} className="kt-notification__item" onClick={() => this.changeToLegacy()}>
              <div className="kt-notification__item-icon">
               <i className="flaticon2-hourglass kt-font-brand" /> 
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  Downgrade Design
                </div>
                <div className="kt-notification__item-time">
                  Change to legacy design
                </div>
              </div>
            </a>*/}


            <div className="kt-notification__custom">
              <a
                onClick={this.logout}
                className="btn btn-label-brand btn-sm btn-bold linear-gradient"
                to="#"
              >
                Sign Out
              </a>
            </div>
          </div>
          {/*end: Navigation */}
        </div>
      </div>
    );
  }
}

export default connect(null, { setUserLoggedOut, setPageLoading })(UserInfo);
