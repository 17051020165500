// ChildComponent.js
import React,{useState} from 'react';
import {
  Form,
  Button,
  Checkbox,
  Table,
  Input,
  TextArea,
  Dropdown,
  Header,
  Divider,
  Modal,
  Icon
} from "semantic-ui-react";
import styles from "./DataConnect.module.css";

const DataLayerModal = ({ open, onClose, onOpen }) => {
    // State to manage form data
    const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    });

    // Handle form input change
    const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
        ...prevState,
        [name]: value,
    }));
    };

    // Handle form reset
    const handleReset = () => {
    setFormData({ name: '', email: '', password: '' });
    };

    // Handle form submit
    const handleSubmit = () => {
    alert(`Form Submitted with: ${JSON.stringify(formData)}`);
    onClose(); // Close modal after submitting
    };

    const [properties, setProperties] = useState([
		{ id: 1, name: "work to be done" },
		{ id: 2, name: "starts on or after" },
	  ]);
	  const [newProperty, setNewProperty] = useState({ name: "", description: "", type: "string" });
	  const handleAddConnection = () => {
		const newConnection = { id: connections.length + 1, name: `Data connection ${connections.length + 1}` };
		setConnections([...connections, newConnection]);
	  };
	
	  const handleAddProperty = () => {
		if (newProperty.name) {
		  setProperties([...properties, { id: properties.length + 1, ...newProperty }]);
		  setNewProperty({ name: "", description: "", type: "string" });
		}
	  };

	  const [connections, setConnections] = useState([
		{ id: 1, name: "Data connection 1" },
		{ id: 2, name: "Data connection 2" },
	  ]);
	return (
			<Modal open={open} onClose={onClose} size="tiny" className={styles.customModal}>
			<Modal.Header className={styles.modalHeader}>
				<span>Salesforce Integration</span>
				<Button
				icon
				className={styles.closeButton}
				onClick={onClose}
				>
				<Icon name="close" />
				</Button>
			</Modal.Header>
			<Modal.Content>
			{/* Connection Details Form */}
				<Form>
					<Form.Field>
					<label>Connection Name</label>
					<Input placeholder="CustomerLookup" disabled style={{ backgroundColor: "#f9f9f9" }} />
					</Form.Field>
					<Form.Field>
					<label>What does this connection do?</label>
					<TextArea
						placeholder="The purpose of the tool is to look up info about an existing customer"
						style={{ minHeight: 80 }}
					/>
					</Form.Field>
					<Form.Field>
					<Checkbox label="Async" />
					</Form.Field>
				</Form>
				<Divider />

				{/* Properties Section */}
				<Header as="h4">Properties</Header>
				<Form.Group widths="equal">
				<Form.Field>
					<label>Property Name</label>
					<Input
					placeholder="Enter property name"
					value={newProperty.name}
					onChange={(e) => setNewProperty({ ...newProperty, name: e.target.value })}
					/>
				</Form.Field>
				<Form.Field>
					<label>Describe the property and what would cause it to be found</label>
					<TextArea
					placeholder="Enter description"
					value={newProperty.description}
					onChange={(e) => setNewProperty({ ...newProperty, description: e.target.value })}
					style={{ minHeight: 80 }}
					/>
				</Form.Field>
				<Form.Field>
					<label>Property Type</label>
					<Dropdown
					placeholder="Select property type"
					fluid
					selection
					options={[
						{ key: "string", text: "String", value: "string" },
						{ key: "number", text: "Number", value: "number" },
					]}
					value={newProperty.type}
					onChange={(e, { value }) => setNewProperty({ ...newProperty, type: value })}
					/>
				</Form.Field>
				<Form.Field style={{ display: "flex", alignItems: "flex-end" }}>
				<div style={{ display: "flex", gap: "10px" }}>
					<Button size="small" >
					Edit
					</Button>
					<Button size="small" color="red" >
					Delete
					</Button>
				</div>
				</Form.Field>
				</Form.Group>

				{/* Properties Table */}
				<Header as="h4">Properties for this connection:</Header>
				<Table celled compact>
				<Table.Header>
					<Table.Row>
					<Table.HeaderCell>Property</Table.HeaderCell>
					<Table.HeaderCell>Actions</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{properties.map((prop) => (
					<Table.Row key={prop.id}>
						<Table.Cell>{prop.name}</Table.Cell>
						<Table.Cell>
						<div style={{ display: "flex", gap: "10px" }}>
							<Button size="small" onClick={() => alert(`Editing ${prop.name}`)}>
							Edit
							</Button>
							<Button size="small" color="red" onClick={() => alert(`Deleting ${prop.name}`)}>
							Delete
							</Button>
						</div>
						</Table.Cell>
					</Table.Row>
					))}
				</Table.Body>
				</Table>
			</Modal.Content>
			<Modal.Actions>
				<Button color="blue" onClick={handleSubmit} className={styles.saveButton}>Save</Button>
			</Modal.Actions>
			</Modal>
	);
};

export default DataLayerModal;
