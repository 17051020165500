import React, { useEffect, useState, createContext } from 'react';
import dataconnection from '../../../api/DataConnection/dataconnection'
// Create context object using createContext
export const DataContext = createContext();

// Create context provider
export const DataProvider = ({ children }) => {
    const [data, setData] = useState(5);
    const [connections, setConnections] = useState([]);
    useEffect(async () => {
        dataconnection.getConnectionList({}).then( res => {
            setConnections(res)
        })
    }, []);

    const updateData = (updatedData) => {
        setData(updatedData);
    };
    
    const updateConeection = (connections) => {
        setConnections(connections)
    }
    return (
        <DataContext.Provider value={{ data, updateData,connections, updateConeection }}>
            {children}
        </DataContext.Provider>
    );
};
