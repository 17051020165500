import React from "react";
import { connect } from "react-redux";
import { Table } from 'semantic-ui-react';
//import CustomErrorField from "../../../../Form/CustomErrorField";
import CustomErrorField from "../../Form/CustomErrorField";
// import DesignRules from './DesignRules';

import { Message } from "semantic-ui-react";
import {
    setPageTitle,
    toggleNavigation,
    setMessage,
    setBreadCrum,
} from "../../../store/Page";
import api from "../../../api";
import Modal from '../ChatSetup/components/Modal';
const queryString = require("query-string");

class Notes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           teamnotes:[],
           notesval:'',
           noteerror:false,
           userToaster: false,
           userToasterMsg: "",
           userToasterColor: "",
           delete_note_id:"",
           delete_note_index:"",
           loading:false,
           disabledSubmit:false,
          };
    }

    
    componentDidMount (){
      // console.log('notes');
      this.setState({
        loading:true,
        notesval:'',
        noteerror:false
    })
      this.loadNotesData(this.props.auto_id);
   }

   componentDidUpdate(prevProps, prevState) {
    if (prevProps.auto_id !== this.props.auto_id) {
        this.setState({
            loading:true,
            notesval:'',
            noteerror:false
        })
        this.loadNotesData(this.props.auto_id);
    }
  }



   handleChange=(e)=>{
       this.setState({notesval:e.target.value})
   }
  
   loadNotesData=(auto_id)=>{
    let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    let clientTimeZone = u.substring(0, 3) + ':' + u.substring(3, u.length);
    let time_zone = clientTimeZone;
     api.chatlist.getTeamNotes({ 'auto_id': auto_id,'time_zone':time_zone,'ref':this.props.tableref }).then(data => {
        
        try {
            this.setState({loading:false})
            this.setState({teamnotes:data.data.teamNotes});
          
          console.log();
  
  
        } catch (err) {
  
        }
      });
   }

    deleteNote=(note_id,index)=>{
        this.setState({
            modalstatus: true,
            delete_note_id:note_id,
            delete_note_index:index
        });
    }

    confirmDelete=()=>{
        this.setState({ loading: true, modalstatus: false});
        api.chatlist.deleteTeamNotes({ 'noteId': this.state.delete_note_id }).then(data => {
            try {
                 let notelist = this.state.teamnotes;
                
                 let index=this.state.delete_note_index;
                 notelist.splice(index, 1);
                let color = "green";
                if (data.data.status == false) {
                    color = "red";
                }
                this.setState({
                    teamnotes: notelist,
                    notesval: '',
                    userToaster: true,
                    userToasterMsg: data.message,
                    userToasterColor: color,
                    delete_note_id: '',
                    delete_note_index: '',
                    loading:false,
                    modalstatus: false,
                },function(){
                });
                if(notelist.length==0){  
                    this.props.updatenote(this.props.auto_id,false);
                 }
                setTimeout(
                    function () {
                        this.setState({ userToaster: false });
                    }.bind(this),
                    3000
                );
            } catch (err) {
      
            }
          });
    } 
   
    
    handleSubmit=(e)=>{
        e.preventDefault();
        if(this.state.notesval.trim()==''){
            this.setState({
                noteerror: true,

            });
        }else{
            this.setState({
                noteerror: false,
                submitDisabled:true
                });
                //let datatosend = {  };
                let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
                let clientTimeZone = u.substring(0, 3) + ':' + u.substring(3, u.length);
                let time_zone = clientTimeZone;
                let datatosend={'auto_id':this.props.auto_id,'ref':this.props.tableref,'time_zone':time_zone,'notes':this.state.notesval.trim()}
                this.setState({ loading: true,
                    disabledSubmit:true });
                api.chatlist.saveTeamNotes(datatosend).then(data => {
                    try {
                        this.setState({ loading: false,
                            disabledSubmit:false });

                        let scrolltop=document.querySelector('.blog-comments').offsetTop - document.body.scrollTop;
                        document.querySelector('.newmodalscroll').scroll({top: 10, left: 0, behavior: 'smooth' });

                        let teamnotesdata=this.state.teamnotes;

                        
                        if(teamnotesdata.length==0){
                          
                            this.props.updatenote(this.props.auto_id,true);
                        }

                        teamnotesdata.unshift(data.data.teamnotes);
                        
                        this.setState({teamnotes:teamnotesdata,notesval:''});
                        document.getElementById('notes').value='';
                       
                        this.setState({
                            teamnotes:teamnotesdata,
                            notesval:'',
                            userToaster: true,
                            userToasterMsg: data.message,
                            userToasterColor: 'green',
                        });
                        setTimeout(
                            function () {
                                this.setState({ userToaster: false });
                            }.bind(this),
                            3000
                        );
                    } catch (err) {
                        console.log(err);
                    }
                });
        } 
    }


    modalFooter = () => {
        return (
            <div className="modal-footer">
                <button type="button" onClick={() => this.modalClose()} className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" data-dismiss="modal">
                    <span>No</span>
                </button>
                <button type="button" onClick={() => this.confirmDelete(this.state.delete_note_id)}  className="btn linear-gradient yr-submitbtn btn-sm">
                <span>Yes</span>
							</button>
            </div>
        )
    }
  
    
    modalClose=()=>{
        this.setState({
            modalstatus: false
        });
    }

    render() {
       
        const { teamnotes,loading }= this.state
     console.log(this.props);
        return (
            <div className="blog-comments">

                <Message
                    color={
                        this.state.userToasterColor
                            ? this.state.userToasterColor
                            : "teal"
                    }
                    style={{
                        display: this.state.userToaster ? "block" : "none",
                        marginTop: 30,
                    }}
                >
                    {this.state.userToasterMsg}
                </Message>

                <div className="c-comment-list" >
                {loading && <div className="ui loading form"></div>}    
                {teamnotes && teamnotes.map((obj, index) => {
                  return (
                    <div className="media" id="deletedNote" key={index}>
                        <div className="media-left">
                            <a href="#">

                                <img className="media-object" alt="" src={obj.imagepath} style={{height:"50px",width:"50px"}} />
                            </a>
                        </div>
                        <div className="media-body">

                            <h4 className="media-heading">
                  <a href="#">{(obj.name!='')  ? obj.name : obj.user_name}</a> on &nbsp;

                  <span className="c-date">{obj.actualtime}</span>

                    &nbsp;<span style={{ color: "red", cursor: "pointer" }} title="Delete" onClick={()=>this.deleteNote(obj.id,index)} ><i className="la la-trash" aria-hidden="true"></i></span>

                            </h4>
                  <span style={{ fontSize: "14px" }}>{obj.comment}</span>
                        </div>
                    </div>
               
                  )
                 })}
             </div>
             {this.props.commentform =='true' && 
                <form id="notesForm" method="post" onSubmit={(e)=>this.handleSubmit(e)}>
                Leave a notes
                    <div className="form-group">
                        <textarea rows="5" style={{resize: "none"}} onChange={(e)=>this.handleChange(e)} name="notes" id="notes"  value={this.state.notesval} placeholder="Write notes here ..." className={
                                            this.state.noteerror 
                                                ? "form-control is-invalid"
                                                : "form-control"}></textarea>
                        {this.state.noteerror &&  (
                                                            <CustomErrorField
                                                                message="This field is required"
                                                                id="help-error"
                                                                className="error invalid-feedback"
                                                            />
                                                        )}
                    </div>
                    <div className="form-group">
                        

                        <button disabled={this.state.disabledSubmit} type="submit" className="btn linear-gradient yr-submitbtn btn-md sbold btn-block">Submit</button>
                    </div>
                </form>
                }
                <Modal
                   show={this.state.modalstatus}
                   footer={this.modalFooter()}
                   onclose={this.modalClose}
                   title="Confirm your action"
                   body="Do you really want to delete?"
                  />            
            </div>
        );
    }
}
export default Notes