import React, { useEffect, useState } from 'react'
import AboutUs from './AboutUs';
import BusinessCategories from './BusinessCategories';
import CompanyInformation from './CompanyInformation';
import classes from "./ConfusedBranchDetails.module.css";
import FAQs from './FaqPage';
import ComapnyOverview from './CompanyOverview';
import Preview from './Preview';
import ConversationGoal from './ConversationGoal';
import DataConnect from './DataConnect/DataConnect';
import api from "../../../../../../api";   
import Integration from './Integration';
const ConfusedBranchDetails = (props) => {
const [trainYourAiData, setTrainYourAiData] = useState({});
const [integrationData, setIntegrationData] = useState({st_integration:'OFF',zp_integration:"OFF"});
const [errorData, setErrorData] = useState({});
const [confusedButtonActive, setconfusedButtonActive] = useState(1);
const [zipcoderror, setzipcoderror] = useState([]);
const [charCount, setcharCount] = useState();
const [gptType, setGptType] = useState(0);
const [deletedSquad, setDeletedSquad] = useState([]);


    const createConversationGoalContent = (data = null) => {
        // variable for all conversationgoal content to be added
        data = data ?? trainYourAiData;
        let goalContent = '';
        const gptModelType = data.gpttype ? data.gpttype : gptType;
        
        const conversationGoal = data.conversationGoal;
        const companyInfo = data.companyInfo;
        const excludedServices = data.BuisnessCat;
        const companyFaq = data.YourFAQA;
        const additionalFaq = data.AdditonalFaqContext;
        const companyAbout = data.AboutUs && typeof data.AboutUs === 'object' ? data.AboutUs : JSON.parse(data.AboutUs);
        const zipCodeData = data.zipcoderules;
        const companyOverview = data.companyOverview;
        goalContent += `${conversationGoal} \n Information about the company is below: 
        `; 
        if(companyOverview){
            goalContent += `${companyOverview} \n`;  
        }
        goalContent += `Company name : ${companyInfo.companyName}
        Company phone number: ${companyInfo.phoneNumber}
        Company website: ${companyInfo.website}
        `;
        companyAbout.forEach((about, i) => {
            let aboutQuestion = '';
            const aboutData = (about && typeof about === 'string') ? JSON.parse(about) : about;
            if(aboutData.a.trim() != ''){
                if(aboutData.q.includes('financing page')){
                    aboutQuestion = `Our financing page ${aboutData.a.trim()} \n`;
                }else{
                    aboutQuestion = aboutData.q.split('?');
                    aboutQuestion = `${aboutQuestion[0]}? \n ${aboutData.a.trim()} \n`;
                }
            }
            goalContent += `${aboutQuestion}`;
        });
        if(excludedServices.mainCatSelected.length || excludedServices.additionCategories){
            let maincategory = '';
            let subcategory = '';
            goalContent += `Below are a list of actions to exclude during a chat: \n`;
            if(excludedServices.mainCatSelected.length){
                excludedServices.mainCatSelected.forEach((cat, i) => {
                    maincategory += `${cat.name} \n`;
                })
            }
            if(excludedServices.subCatDeata.length){
                excludedServices.subCatDeata.forEach((subcat, i) => {
                    if(subcat.checked){
                        subcategory += `${subcat.name} \n`;
                    }
                    
                })
            }
            goalContent += `${maincategory} ${subcategory} ${excludedServices.additionCategories.trim()} \n`;
        }
        if(companyFaq.length || additionalFaq){
            goalContent += `Here are some frequently asked questions about the company: \n`;
            companyFaq.forEach((faq, i) => {
                let faqlist = '';
                const faqEle = faq && typeof faq === 'string' ? JSON.parse(faq) : faq;
                if(faqEle.a.trim() != ''){
                    let faqArray = faqEle.q.split('?');
                    faqlist = `${faqArray[0]}? \n ${faqEle.a.trim()}`;
                    goalContent += `${faqlist} \n`;
                }
            });
            goalContent += `${additionalFaq} \n`;
        }
        const totalchar = (gptModelType == 2) ? 26214 : 48000;
        setcharCount(totalchar - goalContent.length);
    }

    function loadData() {
        if( !props.isConfuseChangedV){
            api.botbuilder
            .fetchTrainYourAiData({ "workflowId": props.workspace_id, 'branch_id': props.branch_id })
            .then((data) => {
                    if (data.status) {
                        console.log('fetched copy branch data',props.branch_id, data.data);
                        setTrainYourAiData(data.data);
                        loadIntegrationData(data.data)
                        createConversationGoalContent(data.data);
                        props.trainYourAiData(data.data);
                    }
                })
        }
    }
    useEffect(()=>{
        if(props.editBranchType !== null){
            api.botbuilder
            .fetchTrainYourAiData({ "workflowId": props.workspace_id, 'branch_id': props.editBranchType })
            .then((data) => {
                if (data.status) {
                    setTrainYourAiData(data.data);
                    loadIntegrationData(data.data)
                    createConversationGoalContent(data.data);
                    props.trainYourAiData(data.data);
                    setconfusedButtonActive(1);
                    }
            })
        } 
    },[props.editBranchType])
    const loadIntegrationData=(data)=>{
        let detail={}
        console.log("inteX",data.zp_integration,data.st_integration)
         detail['workspaceId']=data.workspaceId;
         detail['branch_id']=props.editBranchType;
         detail['user_id']=data.UserId;
         detail['zp_integration']=data.zp_integration;
         detail['st_integration']=data.st_integration;
        
        setIntegrationData(detail);
    }

    useEffect(() => {
        loadData();
    }, []);

    // set data in database
    const setTrainYourAiDataChildcalue = (data, indexName) => {
        let temp_stateTYAI = { ...trainYourAiData }
        if (indexName == "conversationGoal") {
            temp_stateTYAI.conversationGoal = data;
        } else if (indexName == "YourFAQA") {
            temp_stateTYAI.YourFAQA = data;
        } else if (indexName == "BuisnessCat") {
            temp_stateTYAI.BuisnessCat.subCatDeata = temp_stateTYAI.BuisnessCat.subCatDeata ? temp_stateTYAI.BuisnessCat.subCatDeata : [];
            let selectCheckedServeice = temp_stateTYAI.BuisnessCat.subCatDeata.filter((e) => {
                if (e.checked === true) {
                    return e.id;
                } else {
                    return false;
                }
            });
            let selectedArrayId = selectCheckedServeice.map((e) => e.id);
            if (data.subCatDeata) {
                data.subCatDeata.map(e => {
                    if (selectedArrayId.includes(e.id)) {
                        e.checked = true;
                    }
                    return e;
                });
            }
            temp_stateTYAI.BuisnessCat = data;
        } else if (indexName == "companyInfo") {
            
            let tempCompanyInfo = { ...temp_stateTYAI.companyInfo }
            console.log('save', tempCompanyInfo);
            temp_stateTYAI.companyInfo = data
        } else if (indexName == "AdditonalFaqContext") {
            temp_stateTYAI.AdditonalFaqContext = data
        }
        else if(indexName == 'companyOverview'){
            temp_stateTYAI.companyOverview = data
        }else if(indexName == 'AboutUs'){
            temp_stateTYAI.AboutUs = data
        }else if(indexName === 'assistantsInfo'){
            temp_stateTYAI.squad_setting = data
            
        }
        setTrainYourAiData(temp_stateTYAI);
        createConversationGoalContent(temp_stateTYAI);
        props.trainYourAiData(temp_stateTYAI);
        props.isConfuseChanged(true);
    }

    // tabclick program
    const handleConfusedClick = (e, buttonIndex) => {
        e.stopPropagation();
        setconfusedButtonActive(buttonIndex);
        return false;
    }

    // handle prev next
    const handleconfusedNextPrevious = (e, buttonIndex, type) => {
        if (type === 'next') {
            if (confusedButtonActive == 9) {
                return false;
            }
            setconfusedButtonActive(buttonIndex + 1)
        }

        if (type === 'prev') {
            if (confusedButtonActive == 1) {
                return false;
            }
            setconfusedButtonActive(buttonIndex - 1)
        }

    }
    const validateZipcode = () => {
        let errors = [];
        return new Promise((resolve, reject) => {
            let aidata = { ...trainYourAiData }
            const zipcodedata = aidata.zipcoderules;
            // check all zipcode
            zipcodedata.forEach((element, index) => {
                let recordlist = zipcodedata.filter(
                    (list, typeindex) => {

                        if (list.zipcode == element.zipcode && index != typeindex) {

                            return true
                        } else {
                            return false;
                        }
                    }
                );
                if (recordlist != '' && element.zipcode != '') {
                    // alert(recordlist);
                    if (typeof errors[index] != 'undefined') {
                        errors[index].zipcode = 'This zip code already exist';
                    } else {
                        let err = { zipcode: 'This zip code already exist' };
                        errors[index] = err;
                    }
                }
            });
            if (errors.length) {
                reject(errors);
            } else {
                resolve();
            }
        })
    }
    //submit All data 

    const submitFinalData = () => {
        // alert('d');
        let tempCompanyInfo = { ...trainYourAiData }
        let errorData = {};

        // validate squad setting
        const squadData = tempCompanyInfo?.squad_setting;
        
        if (squadData && Array.isArray(squadData)) {
            let isValidSquad = true;
    
            // Loop through the squadData array to validate each object
            squadData.forEach((squad, index) => {
                if (!squad.description || !squad.workspaceId) {
                    isValidSquad = false;
                    console.error(`Invalid data in squad setting at index ${index}:`, squad);
                }
            });
    
            if (!isValidSquad) {
                console.error('Validation failed for squad setting.');
                errorData['type'] = 'danger';
                errorData['msg'] = 'Some of your squad assistant fields are empty, all fields are required.';
                setErrorData(errorData);
                window.scrollTo(0, 0);
                setTimeout(() => {
                    setErrorData({});
                }, 3000);
                return; // Stop the function execution if validation fails
            }
        }
        
        tempCompanyInfo.branch_id = props.branch_id;
        tempCompanyInfo.deletedSquad = deletedSquad;
        // check zipcode data
        validateZipcode().then((res) => {
            console.log(res);
            setzipcoderror([]);
            api.botbuilder
                .saveTrainYourAiData(tempCompanyInfo)
                console.log(tempCompanyInfo)
                .then((data) => {
                    if (data.status) {
                        errorData['type'] = 'success'
                    }
                    errorData['msg'] = data.msg;
                    setErrorData(errorData);
                    window.scrollTo(0, 0);
                    props.isConfuseChanged(false);
                })

            setTimeout(() => {
                setErrorData({});
            }, 8000);
        }).catch((err) => {
            setzipcoderror(err);
            setconfusedButtonActive(1);
            window.scrollTo(0, 0);
            console.log(err);
        })
        props.isConfuseChanged(true);
    }
    const showAlert = () => {
        if (errorData.type) {
            if (errorData.type == 'success') {
                return (
                    <div class="alert alert-success"><strong>{errorData.msg}</strong> </div>
                )
            } else {
                return (
                    <>
                        <div class="alert alert-danger"><strong>{errorData.msg}</strong> </div>
                    </>
                )
            }
        }
    }
    let companyInfo = trainYourAiData.companyInfo ? trainYourAiData.companyInfo : {};
    let BuisnessCat = trainYourAiData.BuisnessCat ? trainYourAiData.BuisnessCat : {};
    let AboutUsd = trainYourAiData.AboutUs ? trainYourAiData.AboutUs : [];
    let YourFaqData = trainYourAiData.YourFAQA ? trainYourAiData.YourFAQA : [];
    let conversationGoal = trainYourAiData.conversationGoal ? trainYourAiData.conversationGoal : "";
    let squad_setting = trainYourAiData.squad_setting;

    const onAddLocation = (data) => {
        let aiData = { ...trainYourAiData };
        aiData.zipcoderules.push(data);
        console.log(aiData.zipcoderules);
        setTrainYourAiData(aiData);

    }

    const changeZipcode = (e, index, type) => {
        let aiData = { ...trainYourAiData };
        let val = e.target.value;
        if(type === 'zipcode'){
            val = val.replace(/[^\d]*/gi, "");
            if(val.length > 5){
                return;
            }
        }
        aiData.zipcoderules[index][type] = val;
        setTrainYourAiData(aiData);
    }

    const deleteLocations = (i) => {
        let aiData = { ...trainYourAiData };
        aiData.zipcoderules.splice(i, 1);
        setTrainYourAiData(aiData);
    }

    const saveZipCodeRules = () => {

    }
    const setDeletedSq = (wid) => {
        let squadArr = deletedSquad;
        squadArr.push(wid);
        setDeletedSquad(squadArr);
    }
    return (
        <div className="yr-chat-round-box addEditBranch confusedbranch" style={{ padding: 0 }}>
            {showAlert()}
            <div className={`yr-chat-round-box ${classes.confusedbranch__heading}`}>
                <p><strong> When this branch is triggered, a free flowing conversation which would simulate a member of your team will take place. By answering the questions below about your company, aur AI model will be able to ask relevant questions and capture the information you desire. If this branch is being triggered in the middle of conversation, our AI will analyze all prior responses from the user chatting.
                </strong></p>
                {charCount && <span className={`badge badge-${(charCount > 0) ? 'warning' : 'danger'}`} style={{float: 'right'}}>
                    <strong>{charCount} characters left</strong>
                </span>}
                
            </div>
            <div className={`${classes.confusedbranch__content}`}>
                <div>
                    <div className={`ui secondary menu ${classes.confusedbranch__content_menu}`} style={{display:"flex", alignItems:"center", justifyContent:"flex-start"}}>
                        <div class={`kt-menu__link ${confusedButtonActive === 1 ? `btn linear-gradient yr-submitbtn` : `item`}`} onClick={(e) => handleConfusedClick(e, 1)} >
                            <span class={`kt-menu__link-text ${confusedButtonActive === 1 ? `` : classes.link_text}`}>
                                <i class="fa fa-info-circle" aria-hidden="true"></i>Basic info
                            </span>
                        </div>
                        <a class={`kt-menu__link ${confusedButtonActive === 2 ? `btn linear-gradient yr-submitbtn` : `item`}`} href="#" onClick={(e) => handleConfusedClick(e, 2)}>
                            <span class={`kt-menu__link-text ${confusedButtonActive === 2 ? `` : classes.link_text}`}>
                                <i class="fa fa-briefcase"></i>Company Overview
                            </span>
                        </a>
                        <div class={`kt-menu__link ${confusedButtonActive === 3 ? `btn linear-gradient yr-submitbtn` : `item`}`} href="#" onClick={(e) => handleConfusedClick(e, 3)}>
                            <span class={`kt-menu__link-text ${confusedButtonActive === 3 ? `` : classes.link_text}`}>
                                <i class="fa fa-id-card-o" aria-hidden="true"></i>About Us
                            </span>
                        </div>
                        <div class={`kt-menu__link ${confusedButtonActive === 4 ? `btn linear-gradient yr-submitbtn` : `item`}`} href="#" onClick={(e) => handleConfusedClick(e, 4)}>
                            <span class={`kt-menu__link-text ${confusedButtonActive === 4 ? `` : classes.link_text}`}>
                                <i class="fa fa-question-circle" aria-hidden="true"></i>Your FAQs
                            </span>
                        </div>
                        <div class={`kt-menu__link ${confusedButtonActive === 5 ? `btn linear-gradient yr-submitbtn` : `item`}`} href="#" onClick={(e) => handleConfusedClick(e, 5)}>
                            <span class={`kt-menu__link-text ${confusedButtonActive === 5 ? `` : classes.link_text}`}>
                                <i class="fa fa-comment-o" aria-hidden="true"></i>Conversation Goal
                            </span>
                        </div>
                        <div class={`kt-menu__link ${confusedButtonActive === 6 ? `btn linear-gradient yr-submitbtn` : `item`}`} href="#" onClick={(e) => handleConfusedClick(e, 6)}>
                            <span class={`kt-menu__link-text ${confusedButtonActive === 6 ? `` : classes.link_text}`}>
                                <i class="fa fa-comment-o" aria-hidden="true"></i>Excluded Context
                            </span>
                        </div>
                        <div class={`kt-menu__link ${confusedButtonActive === 7 ? `btn linear-gradient yr-submitbtn` : `item`}`} href="#" onClick={(e) => handleConfusedClick(e, 7)}>
                            <span class={`kt-menu__link-text ${confusedButtonActive === 7 ? `` : classes.link_text}`}>
                            <i class="fa fa-plug yr-icon-sub" aria-hidden="true"></i>   Integrations
                            </span>
                        </div>
                        <div class={`kt-menu__link ${confusedButtonActive === 9 ? `btn linear-gradient yr-submitbtn` : `item`}`} href="#" onClick={(e) => handleConfusedClick(e, 9)}>
                            <span class={`kt-menu__link-text ${confusedButtonActive === 9 ? `` : classes.link_text}`}>
                            <i class="fa fa-plug yr-icon-sub" aria-hidden="true"></i>   Data Connect
                            </span>
                        </div>
                        <div class={`kt-menu__link ${confusedButtonActive === 8 ? `btn linear-gradient yr-submitbtn` : `item`}`} href="#" onClick={(e) => handleConfusedClick(e, 8)}>
                            <span class={`kt-menu__link-text ${confusedButtonActive === 8 ? `` : classes.link_text}`}>
                                <i class="fa fa-eye-o" aria-hidden="true"></i>Preview
                            </span>
                        </div>
                    </div>
                    <div class={`ui segment ${confusedButtonActive === 1 && `active`} tab`}>
                        {trainYourAiData?.companyInfo &&
                            <CompanyInformation 
                                companyInfo={companyInfo} 
                                setValue={setTrainYourAiDataChildcalue} 
                                botType={props.bot_type} 
                                turboList={props.turboList}
                                workspace_id={props.workspace_id}
                                squad_setting={squad_setting}
                                squadDeleted={setDeletedSq}
                            />
                        }
                    </div>
                    <div class={`ui segment ${confusedButtonActive === 2 && `active`} tab`}>
                        <ComapnyOverview 
                            rands={Math.random() * 1000}
                            companyOverview={trainYourAiData.companyOverview}
                            setValue={setTrainYourAiDataChildcalue}
                        />
                        {/* <BusinessCategories
                            intent_data={props.intent_data}
                            BuisnessCat={BuisnessCat}
                            setValue={setTrainYourAiDataChizldcalue}
                            rands={Math.random() * 1000}
                        /> */}

                    </div>
                    <div class={`ui segment ${confusedButtonActive === 3 && `active`} tab`}>
                        <AboutUs
                            zipCodeData={trainYourAiData.zipcoderules}
                            aboutData={AboutUsd}
                            setValue={setTrainYourAiDataChildcalue}
                            onAddLocation={onAddLocation}
                            changeZipcode={changeZipcode}
                            deleteLocations={deleteLocations}
                            zipcoderror={zipcoderror}
                        />
                    </div>
                    <div class={`ui segment ${confusedButtonActive === 4 && `active`} tab`}>
                        <FAQs
                            faqdata={YourFaqData} setValue={setTrainYourAiDataChildcalue}
                            AdditonalFaqContext={trainYourAiData.AdditonalFaqContext} />
                    </div>
                    <div class={`ui segment ${confusedButtonActive === 5 && `active`} tab`}>
                        <ConversationGoal conversationData={conversationGoal} setValue={setTrainYourAiDataChildcalue} />
                    </div>
                    <div class={`ui segment ${confusedButtonActive === 6 && `active`} tab`}>
                        <BusinessCategories
                            intent_data={props.intent_data}
                            BuisnessCat={BuisnessCat}
                            setValue={setTrainYourAiDataChildcalue}
                            rands={Math.random() * 1000}
                        />
                    </div>
                    <div class={`ui segment ${confusedButtonActive === 7 && `active`} tab`}>
                        <Integration
                          idata={integrationData}
                          integration_branch_id={props.integrationBranchId}
                          active_tab={confusedButtonActive}
                        />
                    </div>

                    <div class={`ui segment ${confusedButtonActive === 9 && `active`} tab`}>
                        <DataConnect
                          active_tab={confusedButtonActive}
                        />
                    </div>
                    
                    <div class={`ui segment ${confusedButtonActive === 8 && `active`} tab`}>
                        <Preview
                            fullAiData={trainYourAiData}
                            sethandleConfusedClick={handleConfusedClick}
                        />
                    </div>
                    
                    <div className="modal-footer">
                        {
                            confusedButtonActive !== 1 && (
                                <button
                                    type="button"
                                    onClick={(e) => handleconfusedNextPrevious(e, confusedButtonActive, 'prev')}
                                    className={`yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small ${classes.footerbutton}`}
                                >

                                    <span><i class="fa fa-angle-left" aria-hidden="true"></i> Previous</span>
                                </button>
                            )
                        }

                        {
                            confusedButtonActive !== 8 && (
                                <button
                                    type="button"
                                    onClick={(e) => handleconfusedNextPrevious(e, confusedButtonActive, 'next')}
                                    className={`yr-submitbtn btn-sm yr-btn-small ${classes.footerbutton}`}
                                >
                                    <span>Next <i class="fa fa-angle-right" aria-hidden="true"></i></span>

                                </button>
                            )
                        }
                        {confusedButtonActive === 8 && (
                            <button
                                type="button"
                                onClick={() => submitFinalData()}
                                className={`yr-submitbtn btn-sm yr-btn-small ${classes.footerbutton}`}
                            >
                                <span>Submit <i class="fa fa-angle-right" aria-hidden="true"></i></span>
                            </button>)
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfusedBranchDetails