import axios from "../config/axios";

const manageUser = {
  getLists: (params) =>
    axios
      .get(`/settings/manageuser/list?` + params, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  editUser: (params) =>
    axios
      .post(`/settings/manageuser/edit`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  addUser: (params) =>
    axios
      .post(`/settings/manageuser/add`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  deleteUser: (params) =>
    axios
      .post(`/settings/manageuser/delete`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  checkUserEmail: (params) =>
    axios
      .post(`/settings/manageuser/checkSubuserEmail`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  teamMemberProfileSetting: (formData) =>
    axios.post(`/settings/manageuser/teamMemberProfileSetting?random=${Math.random()*1000}`, formData),
  teamMemberProfileSettingSecond: (formData) =>
    axios.post("/settings/manageuser/teamMemberProfileSettingSecond", formData),
  teamMemberProfileSwitchSave: (formData) =>
    axios.post("/settings/manageuser/teamMemberProfileSwitchSave", formData),

  getLegacyNotification: (formData) =>
    axios.post("/settings/manageuser/getLegacyNotification", formData),

  deleteLegacyLiveChatMember: (formData) =>
    axios.post("/settings/manageuser/deleteLegacyLiveChatMember", formData),

  deleteLegacyCompleteChatMember: (formData) =>
    axios.post("/settings/manageuser/deleteLegacyCompleteChatMember", formData),

  deleteVoiceListMember: (formData) =>
      axios.post("/settings/manageuser/deleteVoiceListMember", formData),
  
  deleteReportedchatListMember: (formData) =>
      axios.post("/settings/manageuser/deleteReportedchatListMember", formData),
      
  getHipaaLists: (params) =>
    axios
      .get(`/settings/manageuser/hipaaList?` + params, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getLiveChatLists: (params) =>
    axios
      .get(`/settings/manageuser/legacyList?` + params, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getCompletedChatLists: (params) =>
    axios
      .get(`/settings/manageuser/legacyCompletedList?` + params, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
  getVoiceLists: (params) =>
      axios
          .get(`/settings/manageuser/getVoiceLists?` + params, {})
          .then((resp) => resp.data)
          .catch((err) => console.log(err)),


  getReportChatLists: (params) =>
      axios
          .get(`/settings/manageuser/getReportChatLists?` + params, {})
          .then((resp) => resp.data)
          .catch((err) => console.log(err)),

      deleteIncompleteChatEmail: (params) =>
    axios
      .get(`/settings/manageuser/deleteIncompleteChatEmail?id=` + params, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getIncompletedChatLists: (params) =>
    axios
      .get(`/settings/manageuser/legacyIncompletedList?` + params, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  // getLegacyNotification: (formData) =>
    // axios.post("/settings/manageuser/getLegacyNotification", formData),

  getHipaaLoginUserLists: (formData) =>
    axios.post("/settings/manageuser/getHipaaLoginUserLists", formData),
  addHipaaUser: (formData) =>
    axios.post("/settings/manageuser/addHipaaUser", formData),

  deleteHipaaUser: (formData) =>
    axios.post("/settings/manageuser/deleteHipaaSubUser", formData),
  savePin: (formData) => axios.post("/settings/manageuser/savePin", formData),
  checkHipaaAccount: (formData) =>
    axios.post("/settings/manageuser/checkHipaaAccount", formData),
  addZipLocation: (formData) =>
    axios.post("/settings/manageuser/addZipLocation", formData),
  addStateLocation: (formData) =>
    axios.post("/settings/manageuser/addStateLocation", formData),

  getLocationData: (formData) =>
    axios.post("/settings/manageuser/getLocationData", formData),
  deleteLocation: (formData) =>
    axios.post("/settings/manageuser/deleteLocation", formData),

  IndividualNotificationsSetting: (formData) =>
    axios.post("/settings/manageuser/IndividualNotificationsSetting", formData),

  deleteCallNotification: (formData) =>
    axios.post("/settings/manageuser/deleteCallNotification", formData),

  CallNotification: (formData) =>
    axios.post("/settings/manageuser/CallNotification", formData),

  changeCallstatus: (formData) =>
    axios.post("/settings/manageuser/changeCallstatus", formData),

  getLagacyLocationButton: (formData) =>
    axios.post("/settings/manageuser/getLegacyButtonLocation", formData),

  userjumpmessgae: (formData) =>
    axios.post("/settings/manageuser/userjumpmessgae", formData),

  manageuserIncompletegpt: (formData) =>
    axios.post("/settings/manageuser/manageuserIncompletegpt", formData),
  
  getUserVoiceHourData: (formData) =>
    axios.post("/settings/voiceNotification/gethoursdata", formData), 
    
  getVoiceScheduleList: (params) =>
    axios
        .get(`/settings/voiceNotification/getVoiceScheduleList?` + params, {})
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),  
  saveVoiceCompanySchedule : (formData) =>       
    axios.post("/settings/voiceNotification/saveCompanySchedule", formData),

  saveVoiceScheduleMember : (formData) =>       
    axios.post("/settings/voiceNotification/saveCompanyScheduleMember", formData),

  updateNotificationMember : (formData) =>       
    axios.post("/settings/voiceNotification/updateNotificationMember", formData),
  updateCompNotificationMember : (formData) =>       
    axios.post("/settings/manageuser/updateCompNotificationMember", formData),
  updateReportedChatMember : (formData) =>       
    axios.post("/settings/manageuser/updateReportedChatMember", formData),
  updateIncompChatMember : (formData) =>       
    axios.post("/settings/manageuser/updateIncompChatMember", formData),
  updateliveNotificationMember : (formData) =>       
    axios.post("/settings/manageuser/updateliveNotificationMember", formData),
  getVoiceWorkspaceBranch : (formData) =>       
    axios.post("/settings/voiceNotification/getVoiceWorkspaceBranch", formData),
  updateLiveNotificationMember : (formData) =>       
    axios.post("/settings/voiceNotification/updateLiveNotificationMember", formData),
  getwebWorkspaceBranch : (formData) =>       
    axios.post("/settings/WebNotification/get_web_workspace_branches", formData)      
}
export default manageUser;

