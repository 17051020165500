import React from "react";
import "./Tabsetting.css";
import DatatableList from "./DatatableList";
import DatatableListLivechat from "./Livechat/DatatableList";
import DatatableListAiPhone from "./AiPhoneCallNotificationList/DatatableList";
import DatatableListCompletedchat from "./Completedchat/DatatableList";
import DatatableListReportedChat from "./reportedchatList/DatatableList"
import api from "../../../../api";
import DialogBoxExternal from "./DialogBoxExternal";
import DialogBoxIndividualNotifications from "./DialogBoxIndividualNotifications";
import DialogBoxCallNotifications from "./DialogBoxCallNotifications";
import utilFunc from "../../../../util_funs";
import { Message } from "semantic-ui-react";
import IncompleteChat from './IncompleteChat'
import TimeZone from "./VoiceNotificationTimeZone/TimeZone";
import jstz from "jstz";
class ManageUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      getLegacyLiveNotificationMember: [],
      getLegacyCompleteNotificationMember: [],
      loading: false,
      modalstatus: false,
      modaltitle: "",
      modalmessage: "",
      modalbuttonmsg: "",
      modaldata: [],
      llnCount: 0,
      lcnCount: 0,
      modalFormLoader: false,
      ISDrenderOption1: [],
      ISDrenderOption2: [],
      notiAndSettingmodalData: {},
      loadNowIndividualNotifications: false,
      currentChecked: [],
      currentExpanded: [],
      switchValue1: "",
      //ReceiveACall: "",
      CallNotiData: {},
      getCallNotificationData: [],
      message1: "none",
      color1: "green",
      color2: "green",
      msg1: "Setting has been saved successfully.",
      msg2: "Legacy live chat notification member has been deleted successfully.",
      msg3: "Legacy completed chat notification member has been deleted successfully.",
      message2: "none",
      message3: "none",
      getLagacyLocationButton: null,
      jumpin_newmsg: '',
      reloadScheduleList: false,
      voice_type: "voice",
      parsed_values:[]
    };
  }

  componentDidMount() {
    api.manageUser
      .getLegacyNotification({ ref_user_id: this.props.sessionUser.user_id })
      .then((data) => {
        this.setState({
          getLegacyLiveNotificationMember: data.data.data.liveAll,
          getLegacyCompleteNotificationMember: data.data.data.completedAll,
          getCallNotificationData: data.data.data.callNotiData,
          llnCount: data.data.data.liveAll.length,
          lcnCount: data.data.data.completedAll.length,
          cnCount: data.data.data.callNotiData.length,
          ReceiveACall: data.data.data.ReceiveACall,
        });
        //console.log("MOUNT", this.state);
      });
    //fetching and managing locations buttons
    api.manageUser
      .getLagacyLocationButton({ user_id: this.props.sessionUser.user_id })
      .then((data) => {
        this.setState({
          ...this.state,
          getLagacyLocationButton: data.data.data.LegacyButtonLocation
        });
        //console.log("MOUNT", this.state);
      });
      //from props
      if(this.props.profiledata && this.props.profiledata.profileDetails){
        const remailCountryCodeArr = this.props.profiledata.remailCountryCodeArr;
        const defaultCountryCodeArr = this.props.profiledata.defaultCountryCodeArr;
        this.setState({ ISDrenderOption1: defaultCountryCodeArr });
        this.setState({ ISDrenderOption2: remailCountryCodeArr });
      }
    // api.user
    //   .getProfile()
    //   .then((resp) => {
    //     if (resp.data && resp.data.data.profileDetails) {
    //       const remailCountryCodeArr = resp.data.data.remailCountryCodeArr;
    //       const defaultCountryCodeArr = resp.data.data.defaultCountryCodeArr;
    //       this.setState({ ISDrenderOption1: defaultCountryCodeArr });
    //       this.setState({ ISDrenderOption2: remailCountryCodeArr });
    //     }
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  }
  componentWillReceiveProps(nextProps) {
    if(nextProps.profiledata && nextProps.profiledata.profileDetails){
      const remailCountryCodeArr = nextProps.profiledata.remailCountryCodeArr;
      const defaultCountryCodeArr = nextProps.profiledata.defaultCountryCodeArr;
      this.setState({ ISDrenderOption1: defaultCountryCodeArr });
      this.setState({ ISDrenderOption2: remailCountryCodeArr });
    }
  }


  adduser = () => {
    this.child.addUserSubAccounts();
  };
  jumpin_newmsg = (val) => {
    this.setState({ jumpin_newmsg: val });
  }
  liveNotificationMemberFeed() {
    const { getLegacyLiveNotificationMember } = this.state;
    return getLegacyLiveNotificationMember.map(
      (
        {
          id,
          username,
          email_id,
          isd_code,
          phone_number,
          jump_in_question,
          profile_pic,
        },
        idx
      ) => (
        <tr>
          <th>
            <a
              onClick={() => this.deleteLegacyLiveChatMember(id)}
              title="Delete"
              className="btn btn-icon gray"
            >
              <i className="la la-trash"></i>
            </a>
          </th>

          <td data-label="Name">{username}</td>
          <td data-label="Name">{email_id}</td>
          <td data-label="Name">{isd_code + " " + phone_number}</td>
          <td data-label="Name">{jump_in_question}</td>
          <td data-label="Name">
            {profile_pic == "" ? (
              <img
                height="50px"
                src={
                  this.props.sessionUser.assetPath +
                  "/layouts/layout2/img/default-user-icon-4.jpg"
                }
              />
            ) : (
              <img
                height="50px"
                src={
                  this.props.sessionUser.assetPath +
                  "/images/chatlogo/" +
                  profile_pic
                }
              />
            )}
          </td>
        </tr>
      )
    );
  }

  completedNotificationMemberFeed = () => {
    const { getLegacyCompleteNotificationMember } = this.state;
    var index,
      id,
      email,
      isd_code,
      phone_number,
      table = "";
    return getLegacyCompleteNotificationMember.map(
      ({ index, id, email, isd_code, phone_number, table }, idx) => (
        <tr>
          <th>
            <a
              onClick={() =>
                this.deleteLegacyCompletedChatMember({
                  index: index,
                  id: id,
                  table: table,
                })
              }
              title="Delete"
              className="btn btn-icon gray"
            >
              <i className="la la-trash"></i>
            </a>
          </th>
          <td data-label="Name">
            {email == "" ? isd_code + " " + phone_number : email}
          </td>
          {/* <td data-label="Name">{isd_code + " " + phone_number}</td> */}
        </tr>
      )
    );
  };

  deleteLegacyCompletedChatMember = (obj) => {
    this.setState({
      modalstatus: true,
      modaltitle: "Confirm your action",
      modalmessage: "Are you sure you want to delete?",
      modalbuttonmsg: { submit: "Yes", cancel: "No" },
      modalType: "Delete",
      modaldata: {
        refId: obj.id,
        for: "CompleteNoti",
        index: obj.index,
        table: obj.table,
      },
    });
  };

  deleteLegacyLiveChatMember = (id) => {
    this.setState({
      modalstatus: true,
      modaltitle: "Confirm your action",
      modalmessage: "Are you sure you want to delete?",
      modalbuttonmsg: { submit: "Yes", cancel: "No" },
      modalType: "Delete",
      modaldata: { refId: id, for: "LiveNoti" },
    });
  };

  modalClose = (id) => {
    this.setState({
      modalstatus: false,
    });
  };

  modalbuttofun = (obj) => {
    this.setState({
      modalstatus: false,
    });

    if (obj.modaldata.for == "LiveNoti") {
      this.deleteLegacyLiveChatMemberNow(obj.modaldata.refId);
    }

    if (obj.modaldata.for == "CompleteNoti") {
      this.deleteLegacyCompletedChatMemberNow(obj);
    }

    if (obj.modaldata.for == "CallNoti") {
      this.deleteCallNotiNow(obj.modaldata.refId);
    }
  };
  deleteLegacyLiveChatMemberNow = (id) => {
    this.setState({
      loading: true,
    });
    api.manageUser.deleteLegacyLiveChatMember({ id: id }).then((data) => {
      this.props.showUserToaster(
        "green",
        "Legacy live chat notification member has been deleted successfully."
      );
      utilFunc.scrollTop(800);
      this.state.getLegacyLiveNotificationMember =
        this.state.getLegacyLiveNotificationMember.filter(function (obj) {
          return obj.id !== id;
        });
      this.setState({
        loading: false,
        getLegacyLiveNotificationMember:
          this.state.getLegacyLiveNotificationMember,
        llnCount: this.state.getLegacyLiveNotificationMember.length,
      });
    });
  };
  deleteLegacyCompletedChatMemberNow = (obj) => {
    this.setState({
      loading: true,
    });
    api.manageUser
      .deleteLegacyCompleteChatMember({
        id: obj.modaldata.refId,
        table: obj.modaldata.table,
      })
      .then((data) => {
        this.props.showUserToaster(
          "green",
          "Legacy completed chat notification member has been deleted successfully."
        );
        utilFunc.scrollTop(800);
        console.log(this.state.getLegacyCompleteNotificationMember);
        this.state.getLegacyCompleteNotificationMember =
          this.state.getLegacyCompleteNotificationMember.filter(function (
            object
          ) {
            return object.index !== obj.modaldata.index;
          });
        this.setState({
          loading: false,
          getLegacyCompleteNotificationMember:
            this.state.getLegacyCompleteNotificationMember,
          lcnCount: this.state.getLegacyCompleteNotificationMember.length,
        });
      });
  };

  //ANKIT START
  reloadDataTable = () => {
    this.dataTableChild.loadData({});
    this.CompletedchatChild.loadData({});
    this.AiPhoneCallChild.loadData({});
    this.reportedChatChild.loadData({})
    this.reloadTimeZoneSchedule();
  };
  reloadTimeZoneSchedule = () => {
    this.setState({
      reloadScheduleList: true
    });
  }
  IndividualNotifications = () => {
    this.setState({ loading: true });
    api.manageUser.getwebWorkspaceBranch({}).then((res) => {
      if (res.status) {
        this.setState({
          webworkspaceBranches: res.data.data,
        });
      }
    }).catch((err) => {
      console.log(err);
    })
    
    api.manageUser.getVoiceWorkspaceBranch({}).then((res) => {
      if (res.status) {
        var obj = {
          is_livechat_notoficationChecked: false,
          is_completedchat_notoficationChecked: false,
          isd: "+1",
        };
        this.setState({
          modalstatusNotiAandMisc: true,
          loadNowIndividualNotifications: true,
          notiAndSettingmodalData: obj,
          workspaceId: res.data.workspaceId,
          workspaceBranches: res.data.data,
          gptworkspaceBranches: res.data.webparsedetail.data,
          voice_type:res.data.type,
          parsed_values:res.data.parsed_values,
          gpt_parsed_values:res.data.webparsedetail.parsed_values,
          loading: false
        });
      }
    }).catch((err) => {
      console.log(err);
    })
    
  };

  loadNowIndividualNotifications = () => {
    document.querySelector(".manageNotificationIndiviNoti").reset();
  };

  notiAndMiscmodalClose = (id) => {
    var obj = {
      is_livechat_notoficationChecked: false,
      is_completedchat_notoficationChecked: false,
      isd: "+1",
    };
    this.setState({
      modalstatusNotiAandMisc: false,
      notiAndSettingmodalData: obj,
    });
    this.loadNowIndividualNotifications();
  };

  UpdateCompleteNotification = (data) => {
    this.setState({
      getLegacyCompleteNotificationMember: data,
      lcnCount: 1,
    });
  };

  UpdateLiveNotification = (data) => {
    this.setState({
      getLegacyLiveNotificationMember: data,
      llnCount: 1,
    });
  };

  changeSwitch = (e) => {
    var val = "";

    if (e.target.value == "ON") {
      val = "OFF";
    } else {
      val = "ON";
    }
    if (e.target.name == "receive_a_call") {
      this.setState({
        switchValue1: val,
      });
      if (val == "ON" && this.state.cnCount == 0) {
        this.CallNotifications();
      }

      if (this.state.getCallNotificationData != "") {
        api.manageUser
          .changeCallstatus({
            chatBot_id: this.props.sessionUser.chatBot_id,
            status: val,
          })
          .then((data) => { });
      }
    }
  };

  CallNotifications = () => {
    if (this.state.getCallNotificationData != "") {
      var obj = {
        isd:
          this.state.getCallNotificationData[0]["isd_code"] == null ||
            this.state.getCallNotificationData[0]["isd_code"] == ""
            ? "+1"
            : this.state.getCallNotificationData[0]["isd_code"],

        tw_id:
          this.state.getCallNotificationData[0]["id"] == null ||
            this.state.getCallNotificationData[0]["id"] == ""
            ? ""
            : this.state.getCallNotificationData[0]["id"],

        phone: this.state.getCallNotificationData[0]["phone_number"],
        output_phone_number:
          this.state.getCallNotificationData[0]["output_phone_number"],
      };
    } else {
      var obj = {
        isd: "+1",
      };
    }

    this.setState({
      modalstatusCallNoti: true,
      CallNotiData: obj,
    });
  };

  notiAndCallClose = (id) => {
    this.setState({
      modalstatusCallNoti: false,
      CallNotiData: {},
    });
    if (this.state.cnCount == 0) {
      document.querySelector("#custom-switch").click();
    }
    this.phonenumbertoreceiveReset();
  };

  CallNotificationFeed = () => {
    const { getCallNotificationData } = this.state;
    var index,
      id,
      email,
      isd_code,
      phone_number,
      output_phone_number,
      table = "";
    return getCallNotificationData.map(
      (
        {
          index,
          id,
          email,
          isd_code,
          phone_number,
          output_phone_number,
          table,
        },
        idx
      ) => (
        <tr>
          <th>
            <a
              onClick={() => this.deleteCallNotiData(id)}
              title="Delete"
              className="btn btn-icon gray"
            >
              <i className="la la-trash"></i>
            </a>
            <a
              onClick={() => this.CallNotifications()}
              title="Delete"
              className="btn btn-icon gray"
            >
              <i className="la la-edit blue"></i>
            </a>
          </th>
          <td data-label="Name">{isd_code + " " + output_phone_number}</td>
        </tr>
      )
    );
  };

  deleteCallNotiData = (id) => {
    this.setState({
      modalstatus: true,
      modaltitle: "Confirm your action",
      modalmessage: "Are you sure you want to delete?",
      modalbuttonmsg: { submit: "Yes", cancel: "No" },
      modalType: "Delete",
      modaldata: { refId: id, for: "CallNoti" },
    });
  };

  deleteCallNotiNow = (id) => {
    this.setState({
      loading: true,
    });
    api.manageUser.deleteCallNotification({ id: id }).then((data) => {
      // this.props.showUserToaster(
      //   "green",
      //   "Phone number has been deleted successfully."
      // );
      // utilFunc.scrollTop(800);
      this.setState({
        loading: "none",
        message1: "block",
        color1: "green",
        msg1: "Phone number has been deleted successfully.",
      });

      var ref = this;
      setTimeout(function () {
        ref.setState({
          message1: "none",
        });
      }, 5000);
      this.state.getCallNotificationData =
        this.state.getCallNotificationData.filter(function (obj) {
          return obj.id !== id;
        });
      this.setState({
        loading: false,
        getCallNotificationData: this.state.getCallNotificationData,
        cnCount: this.state.getCallNotificationData.length,
      });
      if (this.state.switchValue1 == "ON") {
        document.querySelector("#custom-switch").click();
      }
    });
  };

  UpdateCallNotification = (data, objstate) => {
    this.setState({
      getCallNotificationData: data,
      cnCount: 1,
      ReceiveACall: true,
      loading: false,
      message1: objstate.message1,
      color1: objstate.color1,
      msg1: objstate.msg1,
    });
    var ref = this;
    setTimeout(function () {
      ref.setState({
        message1: "none",
      });
    }, 5000);
  };

  phonenumbertoreceiveReset = () => {
    document.querySelector(".manageCallNotification").reset();
  };

  UpdateToasterMsg = (objstate) => {
    this.setState({
      loading: false,
      message2: objstate.message1,
      color2: objstate.color1,
      msg2: objstate.msg1,
    });
    var ref = this;
    setTimeout(function () {
      ref.setState({
        message2: "none",
      });
    }, 5000);
    var t = document.querySelector(".scrollMsg").offsetTop;
    window.scrollTo({
      top: t,
      left: 0,
      behavior: "smooth",
    });
  };

  UpdateToasterMsgThree = (objstate) => {
    this.setState({
      loading: false,
      message2: objstate.message2,
      color2: objstate.color2,
      msg2: objstate.msg2,
    });
    var ref = this;
    setTimeout(function () {
      ref.setState({
        message2: "none",
      });
    }, 5000);
    var t = document.querySelector(".scrollMsg").offsetTop;
    window.scrollTo({
      top: t,
      left: 0,
      behavior: "smooth",
    });
  };
  //ANKIT END

  renderOptionsForLagacyButtons = (selected = null) => {
    return this.state.getLagacyLocationButton ?
      (
        <>
          {this.state.getLagacyLocationButton.map((button, index) => {
            let select = selected == button.location_id ? true : false;
            return <option key={index} selected={select} value={button.location_id}>{button.location}</option>
          })}
        </>
      ) : ''
  }
  render() {
    return (
      <div className="row">
        {this.state.loading && <div className="ui loading form"></div>}

        <div className="col-lg-12 col-md-12 col-sm-12">
          {/*begin: Search Form */}
          {/*end: Search Form */}
          <div className="kt-portlet__body kt-portlet__body--fit">
            {/*begin: Datatable */}
            <DatatableList
              ref={(child) => {
                this.child = child;
              }}
              showUserToaster={this.props.showUserToaster}
              sessionUser={this.props.sessionUser}
              legacybuttons={this.renderOptionsForLagacyButtons}
              jumpin_newmsg={this.jumpin_newmsg}
            />
            {/*end: Datatable */}
          </div>
          {/*begin: Search Form */}
          <div
            className="kt-portlet__body kt-portlet__body--fit"
            style={{ paddingLeft: 25, paddingRight: 25 }}
          >
            <div
              className="d-flex align-items-center flex-wrap justify-content-between"
              style={{ margin: "3.5rem 0 0" }}
            >
              <h3 class="mh-customColorTitle pl-0 mb-4">
                If a customer completes a chat and you would like to give them
                an option to be immediately connected via phone, you can turn
                this option on
              </h3>
              <div style={{ width: 66 }}>
                <span
                  className={
                    (this.state.switchValue1 == ""
                      ? this.state.ReceiveACall == true
                        ? "ON"
                        : "OFF"
                      : this.state.switchValue1) == "ON"
                      ? "kt-switch kt-switch-sm kt-switch--success chat-cus-check"
                      : "kt-switch kt-switch-sm kt-switch--error chat-cus-check"
                  }
                >
                  <label style={{ marginTop: -4 }}>
                    <input
                      name="receive_a_call"
                      type="checkbox"
                      value={
                        this.state.switchValue1 == ""
                          ? this.state.ReceiveACall == true
                            ? "ON"
                            : "OFF"
                          : this.state.switchValue1
                      }
                      onClick={(e) => this.changeSwitch(e)}
                      defaultChecked={this.state.ReceiveACall}
                      id="custom-switch"
                    />
                    <span />
                  </label>
                </span>
              </div>
            </div>
            <Message
              color={this.state.color1}
              style={{
                display: this.state.message1,
              }}
            >
              {this.state.msg1}
            </Message>

            <table
              style={{ display: this.state.cnCount > 0 ? "" : "none" }}
              class="ui celled table mh-legacyNoti"
            >
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Phone Number</th>
                </tr>
              </thead>
              <tbody>{this.CallNotificationFeed()}</tbody>
            </table>

            {/* <h4
              style={{ display: this.state.llnCount > 0 ? "" : "none" }}
              class="mh-customColorTitle"
            >
              Live Chat:-
            </h4> */}
            <Message
              color={this.state.color2}
              style={{
                display: this.state.message2,
              }}
              className="scrollMsg"
            >
              {this.state.msg2}
            </Message>
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ margin: "3.5rem 0 0" }}
            >
              {/* <h3 class="mh-customColorTitle">Legacy Notification Members</h3>
               */}
              <ul className="nav-tabs nav border-bottom-0 bottom-tabs mt-2">
                <li className="nav-item">
                  <a
                    className="nav-link active below-link"
                    href="#live-chat"
                    data-toggle="tab"
                  >
                    Live Chat
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#completed-chat"
                    className="nav-link below-link"
                    data-toggle="tab"
                  >
                    Completed Chat
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#incompleted-gpt-chat"
                    className="nav-link below-link"
                    data-toggle="tab"
                  >
                    Incompleted GPT Chat
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#ai-phone-call"
                    className="nav-link below-link"
                    data-toggle="tab"
                  >
                    Zyra Voice
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#reported-chat"
                    className="nav-link below-link"
                    data-toggle="tab"
                  >
                    Reported Chats/Calls
                  </a>
                </li>
              </ul>
              <button
                className="btn linear-gradient yr-add-new float-right"
                data-toggle="modal"
                onClick={() => this.IndividualNotifications()}
              >
                Individual Notifications
              </button>
            </div>
            <div className="tab-content">
              <div className="tab-pane fade show active" id="live-chat">
                <DatatableListLivechat
                  ref={(dataTableChild) => {
                    this.dataTableChild = dataTableChild;
                  }}
                  showUserToaster={this.props.showUserToaster}
                  sessionUser={this.props.sessionUser}
                  UpdateToasterMsg={this.UpdateToasterMsg}
                  jumpin_newmsg={this.state.jumpin_newmsg}
                  profiledata={this.props.profiledata}
                  ISDrenderOption1={this.state.ISDrenderOption1}
                  ISDrenderOption2={this.state.ISDrenderOption2}
                />
              </div>
              <div className="tab-pane fade" id="completed-chat">
                <DatatableListCompletedchat
                  ref={(CompletedchatChild) => {
                    this.CompletedchatChild = CompletedchatChild;
                  }}
                  showUserToaster={this.props.showUserToaster}
                  sessionUser={this.props.sessionUser}
                  UpdateToasterMsgThree={this.UpdateToasterMsgThree}
                  profiledata={this.props.profiledata}
                  ISDrenderOption1={this.state.ISDrenderOption1}
                  ISDrenderOption2={this.state.ISDrenderOption2}
                  legacybuttons={this.renderOptionsForLagacyButtons}
                />
              </div>
              <div className="tab-pane fade" id="incompleted-gpt-chat">
                <IncompleteChat />
              </div>
              <div className="tab-pane fade" id="ai-phone-call">
                <DatatableListAiPhone
                  ref={(AiPhoneCallChild) => {
                    this.AiPhoneCallChild = AiPhoneCallChild;
                  }}
                  showUserToaster={this.props.showUserToaster}
                  sessionUser={this.props.sessionUser}
                  UpdateToasterMsgThree={this.UpdateToasterMsgThree}
                  ISDrenderOption1={this.state.ISDrenderOption1}
                  ISDrenderOption2={this.state.ISDrenderOption2}
                />
                <TimeZone
                  reloadScheduleList={this.state.reloadScheduleList}
                />
              </div>
              <div className="tab-pane fade" id="reported-chat">
                <DatatableListReportedChat
                  ref={(reportedChatChild) => {
                    this.reportedChatChild = reportedChatChild;
                  }}
                  showUserToaster={this.props.showUserToaster}
                  sessionUser={this.props.sessionUser}
                  UpdateToasterMsgThree={this.UpdateToasterMsgThree}
                />
              </div>
            </div>
          </div>
        </div>
        <DialogBoxExternal
          obj={this.state}
          show={this.state.modalstatus}
          title={this.state.modaltitle}
          message={this.state.modalmessage}
          modalClose={this.modalClose}
          buttonmsg={this.state.modalbuttonmsg}
          modalbuttofun={this.modalbuttofun}
          modaldata={this.state.modaldata}
        />
        <DialogBoxIndividualNotifications
          obj={this.state}
          notiAndMiscmodalClose={this.notiAndMiscmodalClose}
          onValidSubmitFirstCol={this.onValidSubmitFirstCol}
          onValidSubmitSwicth={this.onValidSubmitSwicth}
          sessionUser={this.props.sessionUser}
          loadNowIndividualNotifications={
            this.state.loadNowIndividualNotifications
          }
          getLegacyCompleteNotificationMember={
            this.state.getLegacyCompleteNotificationMember
          }
          UpdateCompleteNotification={this.UpdateCompleteNotification}
          UpdateLiveNotification={this.UpdateLiveNotification}
          reloadDataTable={this.reloadDataTable}
          UpdateToasterMsg={this.UpdateToasterMsg}
          legacybuttons={this.renderOptionsForLagacyButtons}
        />
        <DialogBoxCallNotifications
          obj={this.state}
          sessionUser={this.props.sessionUser}
          notiAndCallClose={this.notiAndCallClose}
          UpdateCallNotification={this.UpdateCallNotification}
        />
      </div>
    );
  }
}

export default ManageUser;
