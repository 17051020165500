import React from "react";
import { connect } from "react-redux";
import { Table,Loader } from 'semantic-ui-react';
import api from "../../../api";
// import DesignRules from './DesignRules';
import Notes from './Notes';
import { Message } from "semantic-ui-react";
import {
    setPageTitle,
    toggleNavigation,
    setMessage,
    setBreadCrum,
} from "../../../store/Page";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
const queryString = require("query-string");

class ViewDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data:this.props.detaildata,
            datatrans:"",
            translate:false,
            display:"none",
            showtranslate:false,
            buttonName:"Translate in english"
        }

    }
    // Detects changes in props and updates state accordingly
    componentDidUpdate(prevProps) {
        if (prevProps.detaildata !== this.props.detaildata) {
            this.setState({
                data: this.props.detaildata,
                datatrans: "",
                translate: false,
                display:"none",
                buttonName:"Translate in english"
            });
        }
    }
    translate = () => {
        if(this.state.translate){
            this.setState({
                data:this.state.data,
                translate:false,
                buttonName:"Translate in english"
            })
            return;
        }else{
            
            if(this.state.datatrans == ""){
                this.setState({
                    display:"block"
                });
                api.transcriptchatlist.viewVoiceChatDetails({ auto_id: this.props.auto_id, type: 'question_answer',translate:true }).then((data) => {
                    try {
                        if(data.status){
                            this.setState({
                                translate:true,
                                buttonName:"Revert",
                                datatrans: data.data.dataTrans,
                                display:"none"
                            });
                        }
                    } catch (err) {
                     }
                });
            }else{
                this.setState({
                    translate:true,
                    buttonName:"Revert",
                });
            }
        }
    }
    render() {
        console.log("showtranslate",this.props );
        const { auto_id }=this.props
        return (
            <div >
            <div className="ui loading form chatstatusloadingdiv" style={{display:this.state.display }}></div>

            {this.props.showtranslate && <div className="translate-bn d-flex justify-content-end" style={{}}>
                <button type="button" className="btn linear-gradient btn linear-gradient yr-add-new  onclick" onClick={() => this.translate()} >{this.state.buttonName}</button>
            </div>}

            <div className="ui green message chatstatusloadingdivsuccess"  style={{display:"none",marginTop: "30px"}}></div>
            <div >
                {
                    !this.state.translate ?
                    <div  dangerouslySetInnerHTML={{__html:this.state.data}}  className="newmodalscroll"></div> : 
                    <div  dangerouslySetInnerHTML={{__html:this.state.datatrans}}  className="newmodalscroll"></div> 
                }
            
               {!this.props.summary && <Notes auto_id={auto_id} commentform="true" tableref="voice_log" updatenote={this.props.updatenote}></Notes>}
                
            </div>
            </div>
            
        );
    }
}
export default ViewDetails