import React from "react";
import App from "./App";

import { setUserLoggedIn } from "./store/User";
import { setPageLoading } from "./store/Page";
import Spinner from "./components/Spinner";
import { connect } from "react-redux";
import util from "./util";
import api from "./api";
import config from "./config";
import { withRouter } from "react-router-dom";
class Root extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  setFaviconEl = (iconUrl) => {
    const favIcon = document.getElementById("favicon");
    if (favIcon) favIcon.href = iconUrl;
  };

  // done by sagar
  componentWillMount() {
    this.props.setPageLoading({isLoading:true})
  }

  componentDidMount() {
    this.setState({ loading: true });
    
    //if state does not have user information and cookies is not set
    if (
      !window.location.pathname.includes("/user/auth") &&
      !this.props.user &&
      !util.getAuthTokens().access_token
    ) {
      window.location.href = config.authLogoutUrl;
    } else if (
      !window.location.pathname.includes("/user/auth") &&
      !this.props.user &&
      util.getAuthTokens().refresh_token
    ) {
      // if state does not have user but cookies have tokens then set user state
      this.loadUserInfo()
        .then((user) => {
          this.setState({ loading: false });
          if(user.password_update_status === "0"){
            window.location.href = config.authLogoutUrl;
          }else{
            this.props.setUserLoggedIn({ user });
          }
          
          
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    } else if (window.location.pathname === "/user/auth") {
      
      this.authenticateUser();
      this.setState({ loading: false });
    } else {
      window.location.href = config.authLogoutUrl;
    }
  }



  authenticateUser = () => {
    const { location, history } = this.props;
    // setPageLoading({ isLoading: true });
   
    if (location.search) {
      let params = new URLSearchParams(location.search);
      var route = params.get("route");
      var loginvia = params.get("loginvia");
      util.setCookie("loginVia",loginvia);
      
      if (params.has("user")) {
        api.user
          .verifyUser(params.get("user"))
          .then((resp) => {
           
            const { access_token, refresh_token } = resp.data;
            if (
              resp.status === 200 &&
              access_token &&
              access_token !== "" &&
              refresh_token &&
              refresh_token !== ""
            ) {
              util.setAuthTokens(
                access_token,
                refresh_token,
                params.get("subid")
              );

              this.loadUserInfo()
                .then((user) => {
                  this.props.setUserLoggedIn({ user });
                  if (route == 'dashboard')
                    history.push("/");
                  else
                    history.push("/" + route);

                })
                .catch((err) => {
                  console.log(err.message);
                });
            }

          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  loadUserInfo = () => {
    // this.setState({loading:true})
    var subid = util.getAuthTokens().user_subaccount_id
    return api.user
      .getUserInfo({ subid: subid })
      .then((userResp) => {
        // console.log("User Full information : ",userResp );
        
        if (!userResp.data.user.logoUrl) {
          userResp.data.user.logoUrl =
            config.imageUrl + "/assets/media/logos/Zyra-Fullmark-Blue-Bg.svg";
        }
        if (userResp.data.user.favIcon) {
          this.setFaviconEl(userResp.data.user.favIcon);
        }
        userResp.data.user.user_subaccount_id = util.getAuthTokens().user_subaccount_id;

        userResp.data.user.leftBarAccessList = userResp.data.leftBarAccessList;
        userResp.data.user.totalAvailableModules = userResp.data.totalAvailableModules;
        userResp.data.user.customizedDashboard = userResp.data.customizedDashboard;
        userResp.data.user.userBillingDetail = userResp.data.userBillingDetail;
        this.props.setPageLoading({ isLoading: false });
        return userResp.data.user;
      })
      .catch((err) => {
        this.setState({ loading: false })
        console.log(err)
      });
  };
  render() {
    return (
      <React.Fragment>
        {this.props.isPageLoading ? <Spinner /> : ""}

        {this.state.loading ? <Spinner /> : ""}

        {this.props.user && <App />}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company ? true : false,
    isPageLoading: state.page.isLoading ? true : false,
  };
};
export default connect(mapStateToProps, { setUserLoggedIn, setPageLoading })(
  withRouter(Root)
);
