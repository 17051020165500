import React from 'react';
import { useHistory } from 'react-router-dom';

const TopActionButtons = (props) => {
   const history = useHistory(); 
    return(
        <div className="kt-portlet__head-toolbar d-flex justify-content-end item-content-end">
            <button type="button" className="btn linear-gradient zy-save-btn ml-2" onClick={() => history.push('installation')}>Install Instructions</button>
            {props.activeButton!='pagelocation' &&
            <button type="button" className="btn zy-cancel-btn linear-gradient ml-2" onClick={()=>props.cancelBt(props.activeButton)}>Cancel</button>}
          {props.activeButton!='pagelocation' &&  <button type="button" className="btn linear-gradient zy-save-btn ml-2" onClick={()=>props.clickTest(props.activeButton)}>Save</button>}
        </div>
    )
}


export default TopActionButtons;