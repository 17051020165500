import React, { useState ,useContext,useEffect}  from 'react';
import { Menu,Grid,Form, Button, Input, Dropdown, Tab } from "semantic-ui-react";
import DataLayerModal from './DataLayerModal';
import styles from "./DataConnections.module.css";
import DatabaseListing from './DatabaseListing'
import { DataProvider } from './DataProvider';
const authOptions = [
    { key: "none", value: "none", text: "None" },
    { key: "bearer", value: "bearer", text: "Bearer Token" },
    { key: "basic", value: "basic", text: "Basic Auth" },
];
const DataConnections = () => {
	const [activeTab, setActiveTab] = useState("authorization");
	const [modalStatus, setmodalStatus] = useState(false);
	const [databases, setDatabases] = useState([]);
	const [initFormdata, setinitFormdata] = useState({id: "", name: "", host:"",username:"",password:"",tablename:""});

	// on update of coneections
	useEffect(() => {
		setActiveTab("authorization")
		setDatabases([])
	}, [modalStatus]);

    const panes = [
      {
		menuItem: (
		<Menu.Item key="authorization" className={
			activeTab === "authorization" ? styles.menuActiveDataconnection : ""
		  }
		  onClick={() => setActiveTab("authorization")}>
			Authorization
		</Menu.Item>
		),
        render: () => (
          <Tab.Pane>
            <Form>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {/* Left Section: Auth Type */}
                <div style={{ width: "40%",borderRight:"1px solid rgb(190 202 213)",paddingRight:"10px" }}>
                  <Form.Field>
                    <label>Auth Type</label>
                    <Dropdown
                      placeholder="Select Auth Type"
                      fluid
                      selection
                      options={authOptions}
                    />
                  </Form.Field>
				  <p>The authorisation header will be automatically genratedwhen you send the request .</p>
                </div>
      
                {/* Right Section: Key, Value, Add To */}
                <div style={{ width: "55%" }}>
					<Form.Field>
						<label>Key</label>
						<Input placeholder="Key" />
					</Form.Field>
					<Form.Field>
						<label>Value</label>
						<Input placeholder="Value" />
					</Form.Field>
					<Form.Field>
						<label>Add to</label>
						<Input placeholder="Header or Query" />
					</Form.Field>
                  	{/* Buttons below */}
					<div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
						<Button color="blue" className="btn linear-gradient" style={{ marginRight: "10px" }}>
							Connect to Database
						</Button>
						<Button color="blue" className="btn linear-gradient" onClick={() => {setmodalStatus(!modalStatus);}}>Add New Database</Button>
					</div>
                </div>
              </div>
            </Form>
          </Tab.Pane>
        ),
      },
      {
        menuItem:
		(<Menu.Item key="headers" 
			onClick={() => setActiveTab("headers")}
			className={
			activeTab === "headers" ? styles.menuActiveDataconnection : ""
		  }>
			Headers
			</Menu.Item>
		),
        render: () => <Tab.Pane>Headers Content</Tab.Pane>,
      },
      {
		menuItem:
		(<Menu.Item key="body" 
			onClick={() => setActiveTab("body")}
			className={
				activeTab === "body" ? styles.menuActiveDataconnection : ""
			  }
		>
			 Body
			</Menu.Item>
		),
        render: () => <Tab.Pane >Body Content</Tab.Pane>,
      },
      {
        menuItem:
		(<Menu.Item key="responses" 
			onClick={() => setActiveTab("responses")}
			className={
			activeTab === "responses" ? styles.menuActiveDataconnection : ""
		  }>
			Responses
			</Menu.Item>
		),
        render: () => <Tab.Pane>Responses Content</Tab.Pane>,
      },
      {
		menuItem:
        (<Menu.Item key="settings" 
			onClick={() => setActiveTab("settings")}
			className={
			activeTab === "settings" ? styles.menuActiveDataconnection : ""
		  }>
			Settings
			</Menu.Item>
		),
        render: () => <Tab.Pane>Settings Content</Tab.Pane>,
      },
    ];
    return (
		<>
			<DataProvider >
				<div style={{ padding: "20px" }}>
					<Form>
						<Form.Field>
							<Grid columns={2} stackable>
								<label style={{ width: "30%" }}>Endpoint Name</label>
								<Input style={{ width: "69%" }}placeholder="Enter endpoint name" />
							</Grid>
						</Form.Field>
						<Form.Field>
							<Grid columns={2} stackable style={{ marginTop: "2rem" }}>
								<label style={{ width: "30%" }}>Endpoint URL</label>
								<Input
									placeholder="https://www.example.com"
									style={{ width: "69%"}}
									action={{
										content: "Test",
										className: "btn linear-gradient",
										style: { width: "15%" },
									}}
									label={
										<Dropdown
										placeholder="GET"
										options={[
											{ key: "get", text: "GET", value: "get" },
											{ key: "post", text: "POST", value: "post" },
											{ key: "put", text: "PUT", value: "put" },
											{ key: "delete", text: "DELETE", value: "delete" },
											{ key: "patch", text: "PATCH", value: "patch" },
										]}
										defaultValue="get"
										selection
										style={{ minWidth: "80px" ,width:"15%}"}}
										/>
									}
									labelPosition="left"
								/>
							</Grid>
						</Form.Field>
					</Form>
					<Tab panes={panes} style={{ marginTop: "2rem" }} />
				</div>
				{modalStatus && 
						<DataLayerModal open={modalStatus}  onClose={()=> setmodalStatus(false)} onOpen={() => setmodalStatus(true)} setDatabases={setDatabases} initFormdata={initFormdata}/>
				}
				<DatabaseListing databases={databases} open={modalStatus}  onClose={()=> setmodalStatus(false)} onOpen={() => setmodalStatus(true)} setDatabases={setDatabases} setinitFormdata={setinitFormdata}/>
			</DataProvider>
		</>
    );
};

export default DataConnections;