import React, { useState, useEffect} from 'react';
import api from "../../api";
import {  Message,Form } from "semantic-ui-react";
import ConfirmDialog from "../Modal/ConfirmDialog";
import Switch from "react-switch";
import utilFunc from "../../util_funs";


const ServiceTitan = (props,ref) => {

    const [defaultState, setdefaultState] = useState({
        loading: false,
        modal: false,
        successMessage: null,
        errorMessage: null,
        dataLoading: null,
        listdata: true,
    });
    const [isEnabled,setIsEnabled] = useState(false)
    const [errors, setError] = useState('')
    const [videoURL,setVideoURL] = useState('')
    const [onForEngage,setOnForEngage] = useState(false)
    const [model, setModel] = useState(false);
    const [model2, setModel2] = useState(false);
    const [disableModalIsOpen,setDisableModalIsOpen] = useState(false)
    const [enableModalIsOpen,setEnableModalIsOpen] = useState(false)
    const [clientIdTitan, setClientIdTitan]=useState("");
    const [clientSecretTitan, setClientSecretTitan]= useState("");
    const [appKeyTitan, setAppKeyTitan]=useState("");
    const [tenantIdTitan, setTenantIdTitan]= useState("");
    const [showButtons,setShowButtons] = useState(false)
    const [enagageStatus, setEngageStatus] = useState(false)
    const [updateCredBtn, setUpdateCredBtn] = useState(false)
    const [enableSt,SetEnableSt]=useState(false)
    const [disableAlert, setDisableAlert]=useState(false)

    const getListData= ()=>{
        setdefaultState({
            ...defaultState,
            loading:true
        })
        getDataSource().then((res) => {
            setDisableAlert(false)
            console.log(res)
            if (res.totalCount > 0) {
                setShowButtons(false)
                setEngageStatus(false)
                setdefaultState({
                    ...defaultState,
                    listdata: false,
                    loading:false
                })
                SetEnableSt(true)
                if(res?.engage_on=="1"){
                    setIsEnabled(true)
                }
                if(res.engage_on == null || res.engage_on == "0"){
                    setIsEnabled(false)
                }else{
                    setEngageStatus(true)
                    setUpdateCredBtn(true)
                    setUpdateCredBtn(true)
                }
                props.setDataEngage({
                    clientId:res.list[0].st_client_id,
                    clientSecret:res.list[0].st_client_secret,
                    appKey:res.list[0].st_app_key,
                    tenetId:res.list[0].st_tenet_id,
                    webOnOff:res.list[0].st_web_on,
                    voiceOnOff:res.list[0].st_voice_on,
                    createUserOnOff:res.list[0].st_create_user_on,
                    id:res.list[0].st_id 
                })   
                setClientIdTitan(res.list[0].st_client_id);
                setClientSecretTitan(res.list[0].st_client_secret)
                setAppKeyTitan(res.list[0].st_app_key)
                setTenantIdTitan(res.list[0].st_tenet_id)
                props.setTotalCount(res.totalCount)
                if(res.engage_on !== "0"){
                    if(res.engage_on !== null){
                        // props.setDataEngage({
                        //     clientId:res.list[0].st_client_id,
                        //     clientSecret:res.list[0].st_client_secret,
                        //     appKey:res.list[0].st_app_key,
                        //     tenetId:res.list[0].st_tenet_id,
                        //     webOnOff:res.list[0].st_web_on,
                        //     voiceOnOff:res.list[0].st_voice_on,
                        //     createUserOnOff:res.list[0].st_create_user_on,
                        //     id:res.list[0].st_id 
                        // })   
                        // setClientIdTitan(res.list[0].st_client_id);
                        // setClientSecretTitan(res.list[0].st_client_secret)
                        // setAppKeyTitan(res.list[0].st_app_key)
                        // setTenantIdTitan(res.list[0].st_tenet_id)
                        
                    }
                }
            }else{
                setClientIdTitan("");
                setClientSecretTitan("")
                setAppKeyTitan("")
                setTenantIdTitan("")
                setdefaultState({
                    ...defaultState,
                    loading:false
                })
                setUpdateCredBtn(false)
            }
            if(res.engage_on=="0" || res.engage_on===null){
                setOnForEngage(false)
                props.setEngageOnValue("0") 
            }else{
                setOnForEngage(true)
                props.setEngageOnValue(res.engage_on) 
                setIsEnabled(true)
            }
            if(res.chat_on===null){
                props.setChatOnValue("0")
            }else{
                props.setChatOnValue(res.chat_on)
            }

        }).catch((err) => { console.log(err); });
    }

    useEffect(() => {
        getListData()
    }, []);

    // useEffect(()=>{
    //     getListData();
    // },[props.activeMenu])

    // useImperativeHandle(ref, () => ({
    //     getListData
    //   }));

    // data list 
    const getDataSource = (params) => api.servicetitan.list(params);


    const handleChange = (e) => {
        setEngageStatus(true)
        setShowButtons(true)
        setDisableAlert(true)
        if(e.target.name == 'clientId'){
            setClientIdTitan(e.target.value);
            props.setDataEngage({...props.dataEngage,[e.target.name]:e.target.value})
        }
        if(e.target.name == 'clientSecret'){
            setClientSecretTitan(e.target.value);
            props.setDataEngage({...props.dataEngage,[e.target.name]:e.target.value})
        }
        if(e.target.name == 'appKey'){
            setAppKeyTitan(e.target.value);
            props.setDataEngage({...props.dataEngage,[e.target.name]:e.target.value})
        }
        if(e.target.name == 'tenetId'){
            setTenantIdTitan(e.target.value);
            props.setDataEngage({...props.dataEngage,[e.target.name]:e.target.value})
        }
        if (errors && errors[e.target.name]) {
            props.setDataEngage({...props.dataEngage,[e.target.name]:e.target.value})
            setError({...errors,[e.target.name]:""})
        } else {
          props.setDataEngage({...props.dataEngage,[e.target.name]:e.target.value})
        }
      };

    const validateForm = ()=> {
        const errors = {};
        
        if(clientIdTitan == ""){
            errors.clientId = "Please enter client id."
          }
          if(clientSecretTitan == ""){
            errors.clientSecret = "Please enter client secret."
          }
          if(appKeyTitan == ""){
            errors.appKey = "Please enter app key."
          }
          if(tenantIdTitan == ""){
            errors.tenetId = "Please enter tenant id."
          }
        return errors;
      }

    const modalBeforeEnable = ()=>{
        setDisableAlert(true)
        const error = validateForm();
        console.log(Object.keys(error))
        if(Object.keys(error)?.length>0){
            setError(error);
        }else{
            setError("");
          setEnableModalIsOpen(true)
        }
      }
    
    /* Disable service titan for engage */
    const deleteCredentials = () => {  
        setdefaultState({
            ...defaultState,
            loading: true,
            
        });
            const formData = {
                user_client_id:props.dataEngage.clientId,
                user_client_secret:props.dataEngage.clientSecret,
                user_app_key:props.dataEngage.appKey,
                user_tenet_id:props.dataEngage.tenetId,
                chat_on:props.chatOnValue,
                engage_on:"0",
                st_web_on:props.dataEngage.webOnOff,
                st_voice_on:props.dataEngage.voiceOnOff,
                st_create_user_on:props.dataEngage.createUserOnOff,
                id:props.dataEngage.id
            }     
            api.servicetitan.deleteCredential(formData)
                .then((resp) => {
                    setDisableAlert(false)
                    console.log(resp);
                    if (resp && resp.message) {
                        // setrefreshGrid(true);
                        setIsEnabled(false)
                        setUpdateCredBtn(false)
                        updateCredBtn(false)
                        setdefaultState({
                            ...defaultState,
                            successMessage: resp.message,
                            loading: false,
                            
                        });
                        // setUpdateCredBtn(false)
                        setClientIdTitan("");
                        setClientSecretTitan("")
                        setAppKeyTitan("")
                        setTenantIdTitan("")
                        props.setDataEngage({
                            clientId:"",
                            clientSecret:"",
                            appKey:"",
                            tenetId:"",
                            id: 0,  
                        })
                        props.setData({
                            clientId:"",
                            clientSecret:"",
                            appKey:"",
                            tenetId:"",
                            webOnOff:"OFF",
                            voiceOnOff:"OFF",
                            chat_on:"0",
                            createUserOnOff:"OFF",
                            id:0  
                        })
                        props.setEngageOnValue('0')
                        setOnForEngage(false)
                        setIsEnabled(false)
                        utilFunc.scrollTop(800);
                        // setrefreshGrid(false);
                        setTimeout(() => {
                            setdefaultState({
                                ...defaultState,
                                successMessage: null,
                                loading: false,
                                listdata: true
                            });
                            setIsEnabled(false)
                        }, 4000);
                    }else if (resp.errorMessage) {
                        setdefaultState({
                            ...defaultState,
                            errorMessage: resp.errorMessage,
                            loading: false,
                            // id: 0,
                        },()=>{
                            getListData()
                        });
                    }
                })
                .catch((err) => {
                    setdefaultState({
                        ...defaultState,
                        loading: false,
                        // id: 0,
                    });
                })
    }
 
    const handleSwitch = (checked) => {
        console.log(isEnabled)
        if(isEnabled){
            if(props.engageOnValue=="1"){
                disableModalOpen();
            }else{
                setIsEnabled(false)
            }
        }else{
            if(enableSt==true){
                updateStStatus("1")
                // setIsEnabled(true)
            }else{
                setIsEnabled(true)
            }
        }
      }

      const enableModalOpen = ()=>{
          setEnableModalIsOpen(true)
        }
      const disableModalOpen = ()=>{
          setDisableModalIsOpen(true)
        }
    const enableModalClose = ()=>{
        setEnableModalIsOpen(false)
      }
    const disableModalClose = ()=>{
        setDisableModalIsOpen(false)
      }


    const disableServiceTitan = ()=>{
        setDisableModalIsOpen(false)
        deleteCredentials();
    }

    const updateStStatus = (value) => {
        setdefaultState({...defaultState,loading:true});
        let formData = {
            user_chat_on:props.chatOnValue,
            user_engage_on:value,
            type:"engage",
        }
        if(props.dataEngage.id==undefined){
            formData["id"]=props.data.id;
        }else{
            formData["id"]=props.dataEngage.id;
        }
        api.servicetitan.updateStStatus({data:formData}).then(resp=>{
            if(resp.status == true){
                setdefaultState({...defaultState,loading:false});
                if(resp.type == "engage"){
                    if(resp.action == "0"){
                        setIsEnabled(false)
                    }else{
                        setIsEnabled(true)
                        getListData();
                    }
                }
            }
        })
      }

    /* Enable service titan for engage */
    const  enableServiceTitan = (id) => {
        if(Object.keys(errors)?.length===0){
            setdefaultState({...defaultState,loading:true});
            setEnableModalIsOpen(false);
            let formData={}
            if(props.totalCount>0){
                formData = {
                     user_client_id:props.dataEngage.clientId,
                     user_client_secret:props.dataEngage.clientSecret,
                     user_app_key:props.dataEngage.appKey,
                     user_tenet_id:props.dataEngage.tenetId,
                     user_chat_on:props.chatOnValue,
                     user_engage_on:id,
                     type:"engage",
                 }
                if(props.dataEngage.id==undefined){
                    formData["id"]=props.data.id;
                }else{
                    formData["id"]=props.dataEngage.id;
                }
            }else{
                formData = {
                    user_client_id:props.dataEngage.clientId,
                    user_client_secret:props.dataEngage.clientSecret,
                    user_app_key:props.dataEngage.appKey,
                    user_tenet_id:props.dataEngage.tenetId,
                    user_chat_on:props.chatOnValue,
                    user_engage_on:"1",
                    type:"engage",
                } 
            }

            
            api.servicetitan.createNew({data:formData}).then(resp=>{
                if(resp && resp.message){
                    setTimeout(()=>{
                        getListData()
                    },2000)
                    setdefaultState({...defaultState,loading:false,successMessage:resp.message});
                    setTimeout(() => {
                        setdefaultState({
                            ...defaultState,
                            loading: false,
                            successMessage: null,
                            listdata : false
                        });
                    }, 4000); 
                }else if (resp.errorMessage) {

                    setdefaultState({
                        ...defaultState,
                        errorMessage: resp.errorMessage,
                        // id: 0,
                        loading: false
                    });
                    props.setDataEngage({
                        clientId:"",
                        clientSecret:"",
                        appKey:"",
                        tenetId:"",
                        id:0  
                    })
                        setClientIdTitan("");
                        setClientSecretTitan("")
                        setAppKeyTitan("")
                        setTenantIdTitan("")
                    document.getElementById('formEngage').reset();
                    setTimeout(() => {
                        setdefaultState({
                            ...defaultState,
                            loading: false,
                            errorMessage: null,
                        });
                        getListData()
                    }, 4000);
                }
            }).catch(err=>{
                console.log(err)
            })
        }
      };


    const closeInstructionModal = () => {
        setModel2(false);
        setVideoURL("");
    }

    const openInstructionModal = ()=>{
        setModel2(true);
        setVideoURL('https://www.youtube.com/embed/1BGQmwmKzdU');
    }

    const updateCredentials=()=>{
        setClientIdTitan("")
        setClientSecretTitan("")
        setAppKeyTitan("")
        setTenantIdTitan("")
        setShowButtons(true)
        setEngageStatus(true)
    }

    return (
        <>
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="kt-portlet__body kt-portlet__body--fit">
                    {defaultState.successMessage && (
                        <Message color={"green"}>{defaultState.successMessage}</Message>
                    )}
                    {defaultState.errorMessage && (
                        <Message color={"red"}>{defaultState.errorMessage}</Message>
                    )}
                    {defaultState.loading && <div className="ui loading form"></div>}
                    
                    {disableModalIsOpen && isEnabled && <ConfirmDialog
                            show={disableModalIsOpen}
                            toggleModal={disableServiceTitan}
                            closeAction={disableModalClose}
                            title="Confirm your action"
                            message={"Do you really want to disable Service Titan?"}
                        />
                    }
                    {enableModalIsOpen && <ConfirmDialog
                            show={enableModalIsOpen}
                            toggleModal={enableServiceTitan}
                            closeAction={enableModalClose}
                            title="Confirm your action"
                            message={"Do you want to enable Service Titan?"}
                        />}

                    <div class="form-group row">
                        <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub d-flex flex-row ">
                        <label class="form-control-label">Enable to get data from ServiceTitan</label>
                        <div style={{ margin: "3px 175px 0 10px" }}>
                            <Switch
                            onChange={handleSwitch}
                            checked={isEnabled}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            id="material-switch"
                            />
                        </div>
                        <button
                            style={{ margin: "0 20px 15px 120px", float: "right", height:"0px", width:"160px" }}
                            type="button"
                            className="btn linear-gradient yr-submitbtn"
                            onClick={openInstructionModal}
                        >
                            Watch Tutorial
                        </button>
                        {updateCredBtn && <button
                            style={{ margin: "0 10px 15px 0", float: "right", height:"0px", width:"200px" }}
                            type="button"
                            className="btn linear-gradient yr-submitbtn"
                            onClick={updateCredentials}
                        >
                            Update Credentials
                        </button>}
                        </div>
                    </div>
 
                    <Form autoComplete="off" id="formEngage">
                        {isEnabled && <div class="form-group row">
                            <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub mt-2">
                            <div class="form-group-sub">
                                <label class="form-control-label">
                                Client Id
                                </label>
                                <input  onChange={handleChange} name="clientId" value={clientIdTitan}>
                                
                                </input> 
                                {(errors.clientId && disableAlert) && (
                                <div className="red" pointing={"true"}>
                                {errors.clientId}
                                </div>
                                )}
                            </div>
                            <div class="form-group-sub">
                                <label class="form-control-label">
                                Client Secret
                                </label>
                                <input   onChange={handleChange} name="clientSecret" value={clientSecretTitan}>
                                
                                </input> 
                                {(errors.hasOwnProperty('clientSecret') && disableAlert) && (
                                <div className="red">
                                {errors.clientSecret}
                                </div>
                                )}
                            </div>
                            <div class="form-group-sub">
                                <label class="form-control-label">
                                App Key
                                </label>
                                <input   onChange={handleChange} name="appKey" value={appKeyTitan}>
                                
                                </input> 
                                {(errors.appKey && disableAlert) && (
                                <div className="red" pointing={"true"}>
                                {errors.appKey}
                                </div>
                                )}
                            </div>
                            <div class="form-group-sub">
                                <label class="form-control-label">
                                Tenant Id
                                </label>
                                <input   onChange={handleChange} name="tenetId" value={tenantIdTitan}></input> 
                                {(errors.hasOwnProperty('tenetId') && disableAlert) && (
                                <div style={{color:'red'}} pointing={"true"}> {errors.tenetId}</div>
                                )}
                            </div>
                            </div>
                            {/* <div style={{marginTop:"10px", marginLeft:"10px"}}>
                                <span style={{fontWeight:"bold"}}>Note </span>: You can change or update your service titan credentials from chat Integration Service Titan Integration
                            </div> */}
                            
                            {(showButtons && enagageStatus) && <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub mt-5">
                                <button
                                    // type="submit"
                                    style={{ float: "right", marginLeft:"20px" }}
                                    className="btn linear-gradient yr-submitbtn btn-sm"
                                    onClick={modalBeforeEnable}
                                >
                                Save
                                </button>
                            
                                <button
                                style={{ float: "right" }}
                                className="btn linear-gradient yr-submitbtn btn-sm"
                                onClick={()=>getListData()}
                                >
                                Discard Changes
                                </button>
                            
                            </div>}
                        </div>}
                    </Form>
                </div>
            </div>
        </div>
        <div
            className={model2 ? "modal fade show " : "modal fade"}
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="staticBackdrop"
            aria-hidden="true"
            style={{ display: model2 ? "block" : "none" }}
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <button
                        type="button"
                        className="close linear-gradient"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={closeInstructionModal}
                    >
                        X
                    </button>
                    <div className="modal-body">
                    <iframe width="100%" height="480" style={{aspectRatio: '16/9'}} src={videoURL} title="Service Titan Integration" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>

        </div>
        </>
    )
}

export default ServiceTitan;