import React, { Component } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper,Circle } from "google-maps-react";

export class MapContainer extends Component {
  state = {
    activeMarker: {},
    selectedPlace: {},
    showingInfoWindow: false,
    activecenter:{},
    loadcircle:false,
    active_distance:0,
  };


  componentDidMount() {
    this.onLoad();
  }

  componentDidUpdate(prevProps) {
    if (this.props.indextime != prevProps.indextime) {
      this.onLoad();
    }
  }
  onLoad=()=>{
   
    
    this.setState({activecenter:{lat:this.props.mapdata[0]?.lat!='' ? this.props.mapdata[0]?.lat:'40.723080',lng:this.props.mapdata[0]?.lng!='' ? this.props.mapdata[0]?.lng:'-73.984340'}})
  }

  onMarkerClick = (props, marker) =>{
    console.log(props)
    console.log(marker)
    let  radius_km = (parseInt(props.willing_travel) * '1.609344')*1000;
    this.setState({
      activeMarker: marker,
      loadcircle:true,
      selectedPlace: props,
      showingInfoWindow: true,
      activecenter:props.position,
      active_distance:radius_km
    
    },()=>{
      console.log(this.state.activecenter)
    });
  }
  onInfoWindowClose = () =>
    this.setState({
      activeMarker: null,
      showingInfoWindow: false
    });

  onMapClicked = () => {
    if (this.state.showingInfoWindow)
      this.setState({
        activeMarker: null,
        showingInfoWindow: false
      });
  };

  render() {
    if (!this.props.loaded) return <div>Loading...</div>;

    return (
      <Map
        className="map"
        google={this.props.google}
        onClick={this.onMapClicked}
        style={{ height: "100%", position: "relative", width: "100%" }}
        zoom={10}
        center={this.state.activecenter}
      >

{this.state.loadcircle &&
             <Circle
              radius={this.state.active_distance}
              center={{lat:parseFloat(this.state.activecenter.lat),lng:parseFloat(this.state.activecenter.lng)}}
              onMouseover={() => console.log('mouseover')}
              onClick={() => console.log('click')}
              onMouseout={() => console.log('mouseout')}
              strokeColor='transparent'
              strokeOpacity={0}
              strokeWeight={5}
              fillColor='#FF0000'
              fillOpacity={0.2}
            />
}        
            {this.props.mapdata.length>0 &&  this.props.mapdata.map((obj,index)=>{
              return (
                <Marker
                  name={obj.zipcode}
                  onClick={this.onMarkerClick}
                  position={{ lat: obj.lat, lng: obj.lng }}
                  willing_travel={obj.willing_travel}
                />
              )
     
          })}
       

        <InfoWindow
          marker={this.state.activeMarker}
          onClose={this.onInfoWindowClose}
          visible={this.state.showingInfoWindow}
        >

      



          <div>
            <h4>{this.state.selectedPlace.name}</h4>
          </div>
        </InfoWindow>
      </Map>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyCTdtzDSSjnAEZR8JY8d4eEERwBhlbiYcg  ",
  version: "3.38"
})(MapContainer);
