import React from "react";
import { connect } from "react-redux";
import { Table } from 'semantic-ui-react';
// import DesignRules from './DesignRules';
import Notes from './Notes';
import { Message } from "semantic-ui-react";
import {
    setPageTitle,
    toggleNavigation,
    setMessage,
    setBreadCrum,
} from "../../../store/Page";
const queryString = require("query-string");

class ViewDetails extends React.Component {
    constructor(props) {
        super(props);
    }
 
    

    render() {
        const { auto_id }=this.props
        return (
            <div>
            <div className="ui loading form chatstatusloadingdiv" style={{display:"none"}}></div>
            <div className="ui green message chatstatusloadingdivsuccess"  style={{display:"none",marginTop: "30px"}}></div>
            <div >
                <div  dangerouslySetInnerHTML={{__html:this.props.detaildata}}  className="newmodalscroll cplt_chat">
             
               </div>
                <Notes textRow="5" auto_id={auto_id} commentform="true" tableref="chat_log" updatenote={this.props.updatenote}></Notes>
            </div>
            </div>
            
        );
    }
}
export default ViewDetails