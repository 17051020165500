import React, { useState, useEffect ,useContext} from 'react';
import { Table, Modal, Button } from 'semantic-ui-react';
import styles from "./DataConnections.module.css";
import dataconnection from '../../../api/DataConnection/dataconnection'
import { DataContext } from './DataProvider';
import DataLayerModal from './DataLayerModal';
const DatabaseListing = ( props ) => {
	const {connections, updateConeection  } = useContext(DataContext);
	const [loading, setLoading] = useState(true);
	const [conectlist, setconectlist] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedDatabase, setSelectedDatabase] = useState(null);

	useEffect(() => {
		if(props.databases.length>0){
			setconectlist(props.databases)
		}else{
			setconectlist(connections)
		}
		setLoading(false)
	}, [connections,conectlist,selectedDatabase]);

	if (loading) {
		return <div>Loading databases...</div>;
	}

	// open confirmatio modal and set selected row inside setdatabale
	const openModal = (database) => {
		setSelectedDatabase(database);
		setModalOpen(true);
	};

	// this will close the modal 
	const closeModal = () => {
		setSelectedDatabase(null);
		setModalOpen(false);
	};

	//if user allow to delete it will call the api and delete from the database
	const confirmDelete = async () => {
	if (selectedDatabase) {
		try {
		// Update the connections list by filtering out the deleted database
		dataconnection.deleteConnection(selectedDatabase.id).then( ( res ) => {
			setconectlist(res.data);
			updateConeection(res.data); // Update context if needed
		});
		} catch (error) {
			console.error("Failed to delete the database:", error);
		} finally {
			closeModal();
		}
	}
	};

	//openmodel to update database
	const updateDBConeection = (database) => {
		props.setinitFormdata(database);
		props.onOpen();
	}

  return (
    <>
    {/* Table Title */}
    <h2 className="form-control-label">Database Connections</h2>
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Hostname</Table.HeaderCell>
          <Table.HeaderCell>Username</Table.HeaderCell>
          <Table.HeaderCell>Table</Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {conectlist && conectlist.map((database) => (
              <Table.Row key={database.id ?? ""}>
                <Table.Cell>{database.name}</Table.Cell>
                <Table.Cell>{database.host}</Table.Cell>
                <Table.Cell>{database.username}</Table.Cell>
                <Table.Cell>{database.tablename}</Table.Cell>
              <Table.Cell>
              <div className={styles.actionButtons}>
				<span>
                    <i
                      className="la la-trash"
                      onClick={() => openModal(database)}
                      style={{ cursor: "pointer" }}
                    ></i>
                  </span>
				  <a onClick={() => updateDBConeection(database)}>
					<span ><i class="la la-edit blue"></i></span></a>
              </div>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
	{/* Confirmation Modal */}
	<Modal size="small" open={modalOpen} onClose={closeModal}>
		<Modal.Header>Delete Database</Modal.Header>
		<Modal.Content>
		<p>Are you sure you want to delete the database <strong>{selectedDatabase?.name}</strong>?</p>
		</Modal.Content>
		<Modal.Actions>
		<Button onClick={closeModal} color="grey">
			No
		</Button>
		<Button onClick={confirmDelete} color="red">
			Yes
		</Button>
		</Modal.Actions>
	</Modal>
	{props.modalStatus && 
			<DataLayerModal open={props.modalStatus}  onClose={()=> props.onClose} onOpen={() => props.onOpen} setDatabases={props.setDatabases} initFormdata={props.initFormdata}/>
	}
    </>
  );
};

export default DatabaseListing;
