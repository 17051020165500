import React from "react";
import { connect } from "react-redux";
import TopActionButtons from "./components/TopActionButtons";
import EditPageLocation from "./components/EditPageLocation";
import PageRulesHours from "./components/PageRulesHours";
import ReviewChat from "./components/ReviewChat";
import DesignRules from "./DesignRules";


import { Message } from "semantic-ui-react";
import {
  setPageTitle,
  toggleNavigation,
  setMessage,
  setBreadCrum,
  toggleBreadcrum
} from "../../../store/Page";
const queryString = require("query-string");

class ChatSetup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activetab: "design",
      userToaster: false,
      userToasterMsg: "",
      userToasterColor: "",
    };
  }
  componentDidMount() {
    const {
      setPageTitle,
      toggleNavigation,
      location,
      PageMessage,
      setMessage,
      setBreadCrum,
      toggleBreadcrum
      
    } = this.props;
    setPageTitle({ title: "Chat SetUp",subHeaderTitle:'Conversations' });
    toggleBreadcrum({ toggle: false });

    toggleNavigation({ toggle: true });
    setBreadCrum({ breadcrum: null });
    const params = queryString.parse(location.search);

    if (params && params.tab !== undefined) {
      this.setState({
        activetab: params.tab,
      });
    }
  }
  showActiveTab = (btn, e) => {
    e.preventDefault();
    this.setState({
      activetab: btn,
    });
  };

  saveFuncionality = (button) => {
    if (button == "pagerules") {
     // if(this.props.feature!="freemium" || this.props.user_signup_from!="self"){
        this.pagerules.saveData();
     // }
     
    }
    if (button == "design") {
      this.designrules.saveData();
    }
    if (button == "reviewchat") {
      this.reviewchat.saveData();
    }   
  };

  showToaster = (color, msg) => {
    this.setState({
      userToaster: true,
      userToasterMsg: msg,
      userToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ userToaster: false });
      }.bind(this),
      3000
    );
  };

  cancelBt = (button) => {
    if (button == "pagerules") {
      this.pagerules.cancelData();
    }
    if (button == "pagelocation") {
      this.editpagelocation.cancelData();
    }
    if (button == "design") {
      this.designrules.cancelData();
    }
    if (button == "reviewchat") {
      this.reviewchat.cancelData();
    }   
  };

  render() {
    const { activetab } = this.state;
    return (
      <div className="kt-container kt-body  kt-grid kt-grid--ver" id="kt_body">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className="kt-content kt-grid__item kt-grid__item--fluid">
            <div className="yr-body-p yr-mt-20">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div>
                    <div
                      className="kt-portlet kt-portlet--tabs"
                      style={{
                        backgroundColor: "transparent",
                        boxShadow: "none",
                      }}
                    >
                      <div className="kt-portlet__head">
                        <div className="kt-portlet__head-toolbar mb-2">
                          <ul
                            className="nav nav-tabs nav-tabs-line nav-tabs-line-success nav-tabs-line-3x"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <a
                                className={`nav-link ${
                                  activetab == "design" ? "active" : ""
                                }`}
                                href="#design-rules"
                                data-toggle="tab"
                                role="tab"
                                onClick={(e) => this.showActiveTab("design", e)}
                              >
                                Design / Rules
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className={`nav-link ${
                                  activetab == "pagelocation" ? "active" : ""
                                }`}
                                href="#edit-page-location"
                                data-toggle="tab"
                                role="tab"
                                onClick={(e) =>
                                  this.showActiveTab("pagelocation", e)
                                }
                              >
                                Edit Page Location
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className={`nav-link ${
                                  activetab == "pagerules" ? "active" : ""
                                }`}
                                href="#page-rules-hours "
                                data-toggle="tab"
                                role="tab"
                                onClick={(e) =>
                                  this.showActiveTab("pagerules", e)
                                }
                              >
                                Page Rules / Hours
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className={`nav-link ${
                                  activetab == "reviewchat" ? "active" : ""
                                }`}
                                href="#review-chat"
                                data-toggle="tab"
                                role="tab"
                                onClick={(e) =>
                                  this.showActiveTab("reviewchat", e)
                                }
                              >
                                Review Via Chat
                              </a>
                            </li>
                          </ul>
                        </div>
                        <TopActionButtons
                          activeButton={this.state.activetab}
                          clickTest={this.saveFuncionality}
                          cancelBt={this.cancelBt}
                        />
                      </div>
                      <div className="kt-portlet__body yr-pd0-lr">
                        <div className="tab-content">
                          <div
                            className={`tab-pane ${
                              activetab == "design" ? "active" : ""
                            }`}
                            id="design-rules"
                            role="tabpanel"
                          >
                            <Message
                              color={
                                this.state.userToasterColor
                                  ? this.state.userToasterColor
                                  : "teal"
                              }
                              style={{
                                display: this.state.userToaster
                                  ? "block"
                                  : "none",
                                marginTop: 30,
                              }}
                            >
                              {this.state.userToasterMsg}
                            </Message>
                            <DesignRules
                              ref={(designrules) =>
                                (this.designrules = designrules)
                              }
                              showUserToaster={this.showToaster}
                            />
                          </div>
                          <div
                            className={`tab-pane ${
                              activetab == "pagelocation" ? "active" : ""
                            }`}
                            id="edit-page-location"
                            role="tabpanel"
                          >
                            <EditPageLocation
                             ref={(editpagelocation) =>
                              (this.editpagelocation = editpagelocation)
                            }
                            />
                          </div>
                          <div
                            className={`tab-pane ${
                              activetab == "pagerules" ? "active" : ""
                            }`}
                            id="page-rules-hours"
                            role="tabpanel"
                          >
                            <Message
                              color={
                                this.state.userToasterColor
                                  ? this.state.userToasterColor
                                  : "teal"
                              }
                              style={{
                                display: this.state.userToaster
                                  ? "block"
                                  : "none",
                                marginTop: 30,
                              }}
                            >
                              {this.state.userToasterMsg}
                            </Message>
                            <PageRulesHours
                              ref={(pagerules) => (this.pagerules = pagerules)}
                              showUserToaster={this.showToaster}
                              feature={this.props.feature}
                              user_signup_from={this.props.user_signup_from}

                            />
                          </div>
                          <div
                            className={`tab-pane ${
                              activetab == "reviewchat" ? "active" : ""
                            }`}
                            id="revie-wchat"
                            role="tabpanel"
                          >
                            <Message
                              color={
                                this.state.userToasterColor
                                  ? this.state.userToasterColor
                                  : "teal"
                              }
                              style={{
                                display: this.state.userToaster
                                  ? "block"
                                  : "none",
                                marginTop: 30,
                              }}
                            >
                              {this.state.userToasterMsg}
                            </Message>
                            <ReviewChat 
                              ref={(reviewchat) => (this.reviewchat = reviewchat)}
                              showUserToaster={this.showToaster}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    PageMessage: state.page.message,
    user_signup_from: state.user.company.user_signup_from,
    feature:state.user.company.feature
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  setMessage,
  setBreadCrum,
  toggleBreadcrum
})(ChatSetup);
