import React, { useCallback } from 'react';
import api from "../../../../api";
import config from "../../../../config";
import { toggleBreadcrum } from '../../../../store/Page';
import util from "../../../../util";
var oldImg = '';
class ReviewViaChat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listdata: [],
            loading: true,
            location: '',
            dialogCaptured: false,
            dialogCapturedLoading: false,
            dialogCapturedMessage: '',
            dialogCapturedAction: '',
            link: '',
            errors: {},
            reviewImage: '',
            dialogData: '',
            tempImg: ''
        };
    }

    componentDidMount() {
        this.loadData();
    }

    cancelData = () => {
        oldImg = '';
        this.setState({
            listdata: [],
            loading: true,
            location: '',
            dialogCaptured: false,
            dialogCapturedLoading: false,
            dialogCapturedMessage: '',
            dialogCapturedAction: '',
            link: '',
            errors: {},
            reviewImage: '',
            dialogData: '',
            tempImg: ''
        });
        this.componentDidMount();
    }

    loadData = () => {
        api.reviewchat.reviewchatinfo({ 'lid': '' }).then(data => {
            try {
                this.setState({
                    loading: false,
                    listdata: data.data,
                    location: data.data.locationlist.length > 0 && this.state.location == '' ? data.data.locationlist[0].id : this.state.location
                });
            } catch (err) {
                console.log(err);
            }
        });
    }

    onchangeCustom = (e, type) => {
        const errList =  this.state.errors;
        if (errList.hasOwnProperty('locationname')) {
            delete  errList['locationname'];   
            this.setState({error : errList});
        }
        let msg = this.state.listdata;
        if (type == 'review') {
            let errList = this.state.errors;
            if (e.target.name == 'thumbs_up_msg' && e.target.value == '')
                errList['thumbs_up_msg'] = "This field is required.";
            if (e.target.name == 'thumbs_down_msg' && e.target.value == '')
                errList['thumbs_down_msg'] = "This field is required.";

            msg.userinfo[e.target.name] = e.target.value;
            this.setState({
                listdata: msg,
                errors: errList
            });
        }
        if (type == 'location') {
            let lid = e.target.value;
            this.setState({ loading: true });
            api.reviewchat.onchangelocation({ 'lid': lid }).then(data => {
                try {
                    msg.reviewinfo_1 = data.data.reviewinfo_1;
                    msg.reviewinfo_2 = data.data.reviewinfo_2;
                    msg.reviewinfo_3 = data.data.reviewinfo_3;
                    msg.reviewinfo_4 = data.data.reviewinfo_4;
                    msg.reviewinfo_5 = data.data.reviewinfo_5;
                    msg.reviewinfo_6 = data.data.reviewinfo_6;
                    msg.reviewinfo_c = data.data.reviewinfo_c;
                    let errList = this.state.errors;
                    delete errList['location'];
                    delete errList['locationname'];
                    this.setState({
                        location: lid,
                        listdata: msg,
                        loading: false,
                        errors: errList
                    });
                } catch (err) {
                    console.log(err);
                }
            });
        }
    }

    onchangeCustomPre = (e, index) => {
        let oldState = this.state.listdata;

        if (index == 1) {
            if (oldState.reviewinfo_1.length > 0)
                oldState.reviewinfo_1[0].review_link = e.target.value;
            else
                oldState.reviewinfo_1.push({ 'review_link': e.target.value });
        }
        if (index == 2) {
            if (oldState.reviewinfo_2.length > 0)
                oldState.reviewinfo_2[0].review_link = e.target.value;
            else
                oldState.reviewinfo_2.push({ 'review_link': e.target.value });
        }
        if (index == 3) {
            if (oldState.reviewinfo_3.length > 0)
                oldState.reviewinfo_3[0].review_link = e.target.value;
            else
                oldState.reviewinfo_3.push({ 'review_link': e.target.value });
        }
        if (index == 4) {
            if (oldState.reviewinfo_4.length > 0)
                oldState.reviewinfo_4[0].review_link = e.target.value;
            else
                oldState.reviewinfo_4.push({ 'review_link': e.target.value });
        }
        if (index == 6) {
            if (oldState.reviewinfo_6.length > 0)
                oldState.reviewinfo_6[0].review_link = e.target.value;
            else
                oldState.reviewinfo_6.push({ 'review_link': e.target.value });
        }

        this.setState({ listdata: oldState })
    }

    onSaveLocation = (e) => {
        let lname = document.getElementById("locationnameID").value.trim();
        if (lname != '') {
            this.checkdupli(this.state, (error) => {
                this.setState({ errors: error });
                console.log(error.hasOwnProperty('locationname'));
                if (error.hasOwnProperty('locationname') === false) {
                    this.setState({ loading: true });
                    api.reviewchat.locationadd({ 'name': lname }).then(data => {
                        try {
                            let msg = this.state.listdata;
                            msg.locationlist.push({
                                'id': data.data.id,
                                'user_id': this.state.listdata.userinfo.user_id,
                                'chat_bot_id': this.state.listdata.userinfo.chat_bot_id,
                                'location': lname
                            });
                            this.setState({
                                listdata: msg,
                                loading: false
                            });
                            document.getElementById("locationnameID").value = '';
                        } catch (err) {
                            console.log(err);
                        }
                    });
                }
            })
        } else {
            let errList = this.state.errors;
            errList['locationname'] = "This field is required.";
            this.setState({ errors: errList });
        }
    }

    onDeleteLocation = (type) => {
        if (type == 'Close') {
            this.setState({
                dialogCaptured: false,
                dialogCapturedLoading: false,
                dialogCapturedMessage: '',
                dialogCapturedAction: 'Close',
                reviewImage: '',
                link: '',
                errors: {},
                dialogData: '',

            });
        }
        if (type == 'Alert') {
            this.setState({
                dialogCaptured: true,
                dialogCapturedLoading: false,
                dialogCapturedMessage: 'Are you sure you wants to delete this Location?',
                dialogCapturedAction: 'Alert',
            });
        }
        if (type == 'Delete') {
            this.setState({ dialogCapturedLoading: true });
            api.reviewchat.locationdelete({ 'val': this.state.location }).then(data => {
                try {
                    if (data.status) {
                        let msg = this.state.listdata;
                        let recordDeleted = this.state.listdata.locationlist.findIndex(
                            list => list.id === this.state.location
                        );
                        msg.locationlist.splice(recordDeleted, 1);
                        msg.reviewinfo_1 = data.data.reviewinfo_1;
                        msg.reviewinfo_2 = data.data.reviewinfo_2;
                        msg.reviewinfo_3 = data.data.reviewinfo_3;
                        msg.reviewinfo_4 = data.data.reviewinfo_4;
                        msg.reviewinfo_5 = data.data.reviewinfo_5;
                        msg.reviewinfo_6 = data.data.reviewinfo_6;
                        msg.reviewinfo_c = data.data.reviewinfo_c;
                        this.setState({
                            dialogCaptured: false,
                            dialogCapturedLoading: false,
                            dialogCapturedMessage: '',
                            dialogCapturedAction: 'Close',
                            location: this.state.listdata.locationlist.length > 0 ? this.state.listdata.locationlist[0].id : '',
                            listdata: msg,
                        });
                    }
                } catch (err) {
                    console.log(err);
                }
            });
        }
    }

    onCustomAction = (e, type) => {

        if (type == 'AlertC') {
            this.setState({
                dialogCaptured: true,
                dialogCapturedLoading: false,
                dialogCapturedMessage: 'Are you sure you wants to delete this link?',
                dialogCapturedAction: 'AlertC',
                dialogData: e,
            });
        }
        if (type == 'Edit') {
            this.setState({
                dialogCaptured: true,
                dialogCapturedLoading: false,
                dialogCapturedMessage: '',
                dialogCapturedAction: 'Edit',
                reviewImage: config.apiBaseURL.split('api')[0] + "assets/contractorschatbot/images/" + this.state.listdata.reviewinfo_c[e].review_link_image,
                link: this.state.listdata.reviewinfo_c[e].review_link,
                errors: {},
                dialogData: e,
            });
        }
        if (type == 'Add') {
            this.setState({
                dialogCaptured: true,
                dialogCapturedLoading: false,
                dialogCapturedMessage: '',
                dialogCapturedAction: 'Add',
                reviewImage: '',
                link: '',
                errors: {},
                dialogData: '',
            });
        }
        if (type == 'Save') {
            this.checkvalidation(this.state, (error) => {
                this.setState({ errors: error });
                if (Object.keys(error).length == 0) {
                    if (this.state.dialogCapturedAction == 'Add') {
                        this.setState({ dialogCapturedLoading: true });
                        var myform = document.getElementById("linkFormID");
                        var formData = new FormData(myform);
                        const tokenInfo = util.getAuthTokens();
                        var xhr = new XMLHttpRequest();
                        xhr.open("POST", config.apiBaseURL + "/chatsetup/reviewchat/uploadfile", true);
                        xhr.setRequestHeader(config.access_token_name, tokenInfo.access_token);
                        xhr.setRequestHeader(config.refresh_token_name, tokenInfo.refresh_token);
                        xhr.onreadystatechange = () => {
                            if (xhr.readyState == XMLHttpRequest.DONE) {
                                this.setState({ dialogCapturedLoading: false });
                                const resp = JSON.parse(xhr.response);
                                if (resp.status) {
                                    let r_link = this.state.link;
                                    let s1 = r_link.search("http://");
                                    let s2 = r_link.search("https://");
                                    if (s1 == 0 || s2 == 0) {
                                        r_link = this.state.link;
                                    } else {
                                        r_link = 'http://' + r_link;
                                    }
                                    let oldState = this.state.listdata;
                                    oldState.reviewinfo_c.push({
                                        'chatBot_id': this.state.listdata.userinfo.chat_bot_id,
                                        'id': 0,
                                        'location_id': document.getElementById("locationnameID").value.trim(),
                                        'order_links': this.state.listdata.reviewinfo_c.length + 7,
                                        'review_link': r_link,
                                        'review_link_image': resp.data,
                                        'review_name': this.state.listdata.reviewinfo_c.length + 7,
                                        'review_name_long': this.state.listdata.reviewinfo_c.length + 7,
                                        'user_id': this.state.listdata.userinfo.user_id,
                                    });
                                    this.setState({
                                        dialogCaptured: false,
                                        dialogCapturedLoading: false,
                                        dialogCapturedMessage: '',
                                        dialogCapturedAction: '',
                                        dialogData: '',
                                        listdata: oldState,
                                        tempImg: ''
                                    });

                                } else {

                                }
                            }
                        };
                        xhr.send(formData);

                    }
                    if (this.state.dialogCapturedAction == 'Edit') {

                        this.setState({ dialogCapturedLoading: true });
                        var myform = document.getElementById("linkFormID");
                        var formData = new FormData(myform);
                        const tokenInfo = util.getAuthTokens();
                        var xhr = new XMLHttpRequest();
                        xhr.open("POST", config.apiBaseURL + "/chatsetup/reviewchat/uploadfile", true);
                        xhr.setRequestHeader(config.access_token_name, tokenInfo.access_token);
                        xhr.setRequestHeader(config.refresh_token_name, tokenInfo.refresh_token);
                        xhr.onreadystatechange = () => {
                            if (xhr.readyState == XMLHttpRequest.DONE) {
                                this.setState({ dialogCapturedLoading: false });
                                const resp = JSON.parse(xhr.response);
                                if (resp.status) {
                                    let r_link = this.state.link;
                                    let s1 = r_link.search("http://");
                                    let s2 = r_link.search("https://");
                                    if (s1 == 0 || s2 == 0) {
                                        r_link = this.state.link;
                                    } else {
                                        r_link = 'http://' + r_link;
                                    }
                                    let oldState = this.state.listdata;
                                    oldState.reviewinfo_c[this.state.dialogData].review_link = r_link;
                                    if (resp.data != '')
                                        oldState.reviewinfo_c[this.state.dialogData].review_link_image = resp.data;
                                    this.setState({
                                        dialogCaptured: false,
                                        dialogCapturedLoading: false,
                                        dialogCapturedMessage: '',
                                        dialogCapturedAction: '',
                                        dialogData: '',
                                        listdata: oldState,
                                        tempImg: ''
                                    });

                                } else {

                                }
                            }
                        };
                        xhr.send(formData);



                    }
                }

            });

        }
        if (type == 'Delete') {
            api.reviewchat.removefile({ 'file': this.state.listdata.reviewinfo_c[this.state.dialogData].review_link_image }).then(data => {
                try {
                    if (data.status) {
                        let oldState = this.state.listdata;
                        oldState.reviewinfo_c.splice(this.state.dialogData, 1);
                        this.setState({
                            dialogCaptured: false,
                            dialogCapturedLoading: false,
                            dialogCapturedMessage: '',
                            dialogCapturedAction: '',
                            dialogData: '',
                            listdata: oldState,
                            // location:data.data.locationlist.length>0 && this.state.location=='' ? data.data.locationlist[0].id:this.state.location
                        });
                    }
                } catch (err) {
                    console.log(err);
                }
            })
        }
    }


    handleCompanyLogo = (event, type) => {
        let imageMIME = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
        if (imageMIME.includes(event.target.files[0].type) == true && event.target.files[0].type != '') {
            let errList = this.state.errors;
            delete errList['reviewImage'];
            this.setState({ errors: errList });
            if (type == 'Edit') {
                oldImg = this.state.reviewImage;
            }
            this.setState({
                reviewImage: URL.createObjectURL(event.target.files[0]),
                tempImg: URL.createObjectURL(event.target.files[0])
            })
        } else {
            let errList = this.state.errors;
            errList['reviewImage'] = 'Invalid file format. Only "jpg, jpeg, png, gif" files are supported.';
            this.setState({ errors: errList })
        }

    }

    handleCompanyLogoRemove = (event, type) => {
        document.getElementById("reviewImageId").value = "";
        this.setState({
            reviewImage: type == 'Edit' ? oldImg : '',
            tempImg: ''
        })
    }

    checkvalidation = (data, callback) => {
        const errors = {};
        if (data.link == '') {
            errors['link'] = "This field is required.";
        }
        if (data.reviewImage == '') {
            errors['reviewImage'] = "This field is required.";
        }
        callback(errors);
    }

    saveData = () => {
        const errListLc =  this.state.errors;
        if (errListLc.hasOwnProperty('locationname')) {
            delete  errListLc['locationname'];   
            this.setState({error : errListLc});
        }
        if (this.state.listdata.locationlist.length > 0 && this.state.location == '') {
            let errList = this.state.errors;
            errList['location'] = "This field is required.";
            this.setState({ errors: errList })
        }
        
        if (Object.keys(this.state.errors).length == 0) {
            this.setState({ loading: true });
            var myform = document.getElementById("reviewchatID");
            var formData = new FormData(myform);
            formData.append('thumbs_up_msg', this.state.listdata.userinfo.thumbs_up_msg);
            formData.append('thumbs_down_msg', this.state.listdata.userinfo.thumbs_down_msg);
            const tokenInfo = util.getAuthTokens();
            var xhr = new XMLHttpRequest();
            xhr.open("POST", config.apiBaseURL + "/chatsetup/reviewchat/savereviewchat", true);
            xhr.setRequestHeader(config.access_token_name, tokenInfo.access_token);
            xhr.setRequestHeader(config.refresh_token_name, tokenInfo.refresh_token);
            xhr.onreadystatechange = () => {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    this.setState({ dialogCapturedLoading: false });
                    const resp = JSON.parse(xhr.response);
                    if (resp.status) {
                        this.setState({ loading: false });
                        this.props.showUserToaster('teal', "Review links saved successfully.");
                    } else {

                    }
                }
            };
            xhr.send(formData);
        }
    }


    checkdupli = (data, callback) => {
        let errors = this.state.errors
        if (data.listdata.locationlist.length > 0) {
            let recordDeleted = data.listdata.locationlist.findIndex(
                list => list.location.trim().toLowerCase() === document.getElementById("locationnameID").value.trim().toLowerCase()
            );
            if (recordDeleted >= 0) {
                errors['locationname'] = "Location can not be duplicate.";
                callback(errors);
            } else {
                callback(errors);
            }
        } else {
            callback(errors);
        }
    }

    render() {
        const { errors } = this.state;
        return (
            <div>
                {this.state.loading && <div className="ui loading form"></div>}
                {!this.state.loading && (<div className="row">
                    <div className="col-lg-4 col-md-4">
                        <form id="reviewchatID" name="reviewchat" encType="multipart/form-data" method="post" >
                            <div className="yr-createTicket min-height yr-max-height pd20" style={{ height: 650 + 'px' }}>
                                <div className="row">
                                    <div className="form-group col-md-12">
                                        <strong>Please add all website you would like to capture reviews on</strong>
                                        <p className="mt-4">If you multiple locations please add them with each location's reviews site. If you are a single location, leave this option blank.</p>
                                    </div>
                                </div>
                                <div id="reviewlinklist">
                                    <div className="form-group reviewlink">
                                        <div className="col-xs-12">
                                            <input type="text"
                                                name="locationname"
                                                id='locationnameID'
                                                onChange={(e) => {
                                                    let errList = this.state.errors;
                                                    if (e.target.value.trim() != '') {
                                                        delete errList[e.target.name];
                                                    } 
                                                    this.setState({ errors: errList })
                                                }}
                                                placeholder="Location" className="form-control" />
                                        </div>
                                        {errors && errors.hasOwnProperty('locationname') && (
                                            <div className="red">{errors['locationname']}</div>
                                        )}
                                        <div className="mt-3">
                                            <button type="button"  id="saveLocationBt" onClick={(e) => this.onSaveLocation(e)} className="btn linear-gradient zy-save-btn">Save</button>
                                        </div>
                                    </div>
                                    <div className="form-group reviewlink">
                                        <div className="col-xs-12">
                                            <label className="control-label">
                                                Select Location
                                            </label>
                                        </div>
                                        <div className="col-xs-12 yr-selectLocation">
                                            <select className="form-control"
                                                name="location"
                                                onChange={(e) => this.onchangeCustom(e, 'location')}
                                                value={this.state.location}
                                            >
                                                <option value=''>Select</option>
                                                {this.state.listdata.locationlist && this.state.listdata.locationlist.map(function (obj, index) {
                                                    return (
                                                        <option key={obj.id} value={obj.id}>{obj.location}</option>
                                                    )
                                                })}
                                            </select>
                                            {this.state.listdata.locationlist.length > 0 && this.state.location &&
                                                <a href="javascript:;" title="Delete" className="yr-minusBtn" onClick={(e) => this.onDeleteLocation('Alert')}><i style={{paddingLeft:"8px"}} className="remove icon"></i></a>
                                            }
                                            {errors && errors.hasOwnProperty('location') && (
                                                <div className="red">{errors['location']}</div>
                                            )}
                                        </div>
                                        <div className="col-xs-12 mt-3 ml-2">
                                            <b>Default</b>
                                        </div>
                                    </div>
                                    <div className="form-group reviewlink">
                                        <label className="col-xs-12 mb-3 ml-2">
                                            <input type="hidden" name="reviewnamehid[1]" value="f-logo" />
                                            <input type="hidden" name="reviewnamelong[1]" value="Facebook" />
                                            <input type="hidden" name="reviewimage[1]" value="f-logo.png" />
                                            <img src="assets/images/f-logo.png" />
                                        </label>
                                        <div className="col-xs-12">
                                            <input type="text" name="reviewlink[1]"
                                                placeholder="Review Link"
                                                value={this.state.listdata.reviewinfo_1.length > 0 ? this.state.listdata.reviewinfo_1[0].review_link : ''}
                                                onChange={(e) => this.onchangeCustomPre(e, 1)}
                                                className="form-control reviewlinktext" />
                                        </div>
                                    </div>
                                    <div className="form-group reviewlink">
                                        <label className="col-xs-12 mb-3 ml-2">
                                            <input type="hidden" name="reviewnamehid[2]" value="google-logo" />
                                            <input type="hidden" name="reviewnamelong[2]" value="Google" />
                                            <input type="hidden" name="reviewimage[2]" value="google-logo.png" />
                                            <img src="assets/images/google-logo.png" />
                                        </label>
                                        <div className="col-xs-12">
                                            <input type="text" name="reviewlink[2]"
                                                placeholder="Review Link"
                                                onChange={(e) => this.onchangeCustomPre(e, 2)}
                                                value={this.state.listdata.reviewinfo_2.length > 0 ? this.state.listdata.reviewinfo_2[0].review_link : ''}
                                                className="form-control reviewlinktext" />
                                        </div>
                                    </div>
                                    <div className="form-group reviewlink">
                                        <label className="col-xs-12 mb-3 ml-2">
                                            <input type="hidden" name="reviewnamehid[3]" value="homeadvisor" />
                                            <input type="hidden" name="reviewnamelong[3]" value="Homeadvisor" />
                                            <input type="hidden" name="reviewimage[3]" value="homeadvisor.png" />
                                            <img src="assets/images/homeadvisor.png" />
                                        </label>
                                        <div className="col-xs-12">
                                            <input type="text" name="reviewlink[3]"
                                                placeholder="Review Link"
                                                onChange={(e) => this.onchangeCustomPre(e, 3)}
                                                value={this.state.listdata.reviewinfo_3.length > 0 ? this.state.listdata.reviewinfo_3[0].review_link : ''}
                                                className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group reviewlink">
                                        <label className="col-xs-12 mb-3 ml-2">
                                            <input type="hidden" name="reviewnamehid[4]" value="bbb" />
                                            <input type="hidden" name="reviewnamelong[4]" value="Bbb" />
                                            <input type="hidden" name="reviewimage[4]" value="bbb.png" />
                                            <img src="assets/images/bbb.png" />
                                        </label>
                                        <div className="col-xs-12">
                                            <input type="text" name="reviewlink[4]"
                                                placeholder="Review Link"
                                                onChange={(e) => this.onchangeCustomPre(e, 4)}
                                                value={this.state.listdata.reviewinfo_4.length > 0 ? this.state.listdata.reviewinfo_4[0].review_link : ''}
                                                className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group reviewlink">
                                        <label className="col-xs-12 mb-3 ml-2">
                                            <input type="hidden" name="reviewnamehid[6]" value="yp" />
                                            <input type="hidden" name="reviewnamelong[6]" value="Yp" />
                                            <input type="hidden" name="reviewimage[6]" value="yp.png" />
                                            <img src="assets/images/yp.png" />
                                        </label>
                                        <div className="col-xs-12">
                                            <input type="text" name="reviewlink[6]"
                                                placeholder="Review Link"
                                                onChange={(e) => this.onchangeCustomPre(e, 6)}
                                                value={this.state.listdata.reviewinfo_6.length > 0 ? this.state.listdata.reviewinfo_6[0].review_link : ''}
                                                className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group reviewlink">
                                        <label className="col-xs-12 mb-3 ml-2">Your Custom Links</label>

                                        {this.state.listdata.reviewinfo_c && this.state.listdata.reviewinfo_c.map((obj, index) => {
                                            let m = index + 7;
                                            return (
                                                <div>
                                                    <label class="col-xs-12">
                                                        <input type="hidden" name={"reviewnamehid[" + m + "]"} value={m} />
                                                        <input type="hidden" name={"reviewnamelong[" + m + "]"} value={m} />
                                                        <input type="hidden" name={"reviewimage[" + m + "]"} value={obj.review_link_image} />
                                                        <img height='20px' src={config.apiBaseURL.split('api')[0] + "assets/contractorschatbot/images/" + obj.review_link_image} />
                                                    </label>
                                                    <div class="col-xs-12 yr-selectLocation" >
                                                        <input type="text" name={"reviewlink[" + m + "]"} placeholder="Review Link" class="form-control" value={obj.review_link} />
                                                        <a href="javascript:;" title="Edit" className="yr-minusBtn editbtn" onClick={(e) => this.onCustomAction(index, 'Edit')}><i className="edit icon"></i></a>
                                                        <a href="javascript:;" title="Delete" className="yr-minusBtn" onClick={() => this.onCustomAction(index, 'AlertC')} ><i className="remove icon"></i></a>

                                                        {/* <a href="javascript:;" title="Edit" class="yr-minusBtn editbtn"><i class="edit icon"></i></a>
                    <a href="javascript:;" title="Delete" class="yr-minusBtn"><i class="remove icon"></i></a> */}
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                    <div class="text-center form-group">
                                        <label className="control-label col-xs-12 mgt10">
                                            <a href="javascript:;" onClick={(e) => this.onCustomAction('', 'Add')}>+ Add a link</a>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-lg-8 col-md-8">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="min-height yr-chatleftbox">
                                    <div className="form-group">
                                        <label>Response for a thumbs up review</label>
                                        <textarea className="form-control" rows="4"
                                            name='thumbs_up_msg'
                                            onChange={(e) => this.onchangeCustom(e, 'review')}
                                            value={this.state.listdata.userinfo.thumbs_up_msg}
                                        ></textarea>
                                        {errors && errors.hasOwnProperty('thumbs_up_msg') && (
                                            <div className="red">{errors['thumbs_up_msg']}</div>
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label>Response for a thumbs down review</label>
                                        <textarea className="form-control" rows="4"
                                            name='thumbs_down_msg'
                                            onChange={(e) => this.onchangeCustom(e, 'review')}
                                            value={this.state.listdata.userinfo.thumbs_down_msg}
                                        ></textarea>
                                        {errors && errors.hasOwnProperty('thumbs_down_msg') && (
                                            <div className="red">{errors['thumbs_down_msg']}</div>
                                        )}
                                    </div>

                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="yr-createTicket min-height yr-max-height" style={{ height: 650 + 'px' }}>
                                    <div className="form-group text-right">
                                        <span className="yr-rightchatMsg">I had a good experience</span></div>
                                    <div className="yr-body-top" style={{ marginBottom: 20 + 'px' }}>
                                        <img src="assets/media/logos/yr-logo.png" alt="" style={{ marginTop: 0 }} />
                                        <span className="welcome-text">
                                            {this.state.listdata.userinfo.thumbs_up_msg}
                                        </span>
                                    </div>
                                    <div className="form-group text-right">
                                        <span className="yr-rightchatMsg">I had a good experience</span></div>
                                    <div className="yr-body-top" style={{ marginBottom: 0 }}>
                                        <img src="assets/media/logos/yr-logo.png" alt="" style={{ marginTop: 0 }} />
                                        <span className="welcome-text">
                                            {this.state.listdata.userinfo.thumbs_down_msg}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className={this.state.dialogCaptured ? "modal fade show" : "modal fade"}
                        data-backdrop="static"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="staticBackdrop"
                        aria-hidden="true"
                        style={{ display: this.state.dialogCaptured ? "block" : "none" }}
                    >
                        <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: 600 }}>
                            {this.state.dialogCapturedLoading && <div className="ui loading form"></div>}
                            <div className="modal-content">
                                <button
                                    type="button"
                                    className="close linear-gradient"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => this.onDeleteLocation('Close')}
                                >
                                    X
                                </button>
                                <div className="modal-header">
                                    <h5 className="modal-title">{
                                        this.state.dialogCapturedAction == 'Alert' || this.state.dialogCapturedAction == 'AlertC' ? 'Confirm your action' :
                                            this.state.dialogCapturedAction == 'Add' ? 'Add Link' :
                                                this.state.dialogCapturedAction == 'Edit' ? 'Edit Link' : ''
                                    }</h5>
                                </div>
                                <form id="linkFormID" name="linkForm" encType="multipart/form-data" method="post" >
                                    <div className="modal-body">
                                        <div className="px-4">
                                            {this.state.dialogCapturedAction == 'Alert' || this.state.dialogCapturedAction == 'AlertC' ?
                                                <p dangerouslySetInnerHTML={{ __html: this.state.dialogCapturedMessage }}></p>
                                                :
                                                <div>
                                                    <div class="form-group row">
                                                        <label class="col-lg-4 col-form-label" style={{ color: '#848588' }}>Review Link :</label>
                                                        <div class="col-lg-8">
                                                            <div class="field">
                                                                <div class="ui fluid input">
                                                                    <input autocomplete="off"
                                                                        name="link"
                                                                        placeholder=""
                                                                        type="text"
                                                                        value={this.state.link}
                                                                        onChange={(e) => {
                                                                            let errList = this.state.errors;
                                                                            if (e.target.value.trim() != '')
                                                                                delete errList[e.target.name];
                                                                            else
                                                                                errList[e.target.name] = "This field is required.";
                                                                            this.setState({ link: e.target.value.trim(), errors: errList })
                                                                        }}
                                                                    />
                                                                </div>
                                                                {errors && errors.hasOwnProperty('link') && (
                                                                    <div className="red">{errors['link']}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-lg-4 col-form-label" style={{ color: '#848588' }}>Review Image :</label>
                                                        <div class="col-lg-8">
                                                            <div class="field">
                                                                <div className="logo-img">
                                                                    <img src={this.state.reviewImage == '' ? "assets/media/logos/upload-bg.png" : this.state.reviewImage} alt="" />
                                                                </div>
                                                                {this.state.dialogCapturedAction == 'Add' && (
                                                                    <div className="d-flex justify-content-center">
                                                                        <div className="upload-btn-wrapper">
                                                                            <button className="btn linear-gradient zy-save-btn width-100" style={{cursor:"pointer"}}>Add Image</button>
                                                                            <input type="file" name="reviewImage" id='reviewImageId' onChange={(e) => this.handleCompanyLogo(e, '')} accept="image/jpg,image/png,image/jpeg,image/gif" />
                                                                        </div>
                                                                        {this.state.reviewImage && (<button type="reset" className="btn linear-gradient zy-cancel-btn ml-2" onClick={(e) => this.handleCompanyLogoRemove(e, '')}>Remove</button>)}
                                                                    </div>
                                                                )}
                                                                {this.state.dialogCapturedAction == 'Edit' && (
                                                                    <div className="d-flex justify-content-center">
                                                                        <div className="upload-btn-wrapper">
                                                                            <button className="btn linear-gradient zy-save-btn width-100">Edit Image</button>
                                                                            <input type="file" name="reviewImage" id='reviewImageId' onChange={(e) => this.handleCompanyLogo(e, 'Edit')} accept="image/jpg,image/png,image/jpeg,image/gif" />
                                                                        </div>
                                                                        {this.state.tempImg != '' && (<button type="reset" className="btn linear-gradient-cancel zy-cancel-btn ml-2" onClick={(e) => this.handleCompanyLogoRemove(e, 'Edit')}>Remove</button>)}
                                                                    </div>
                                                                )}
                                                                {errors && errors.hasOwnProperty('reviewImage') && (
                                                                    <div className="red">{errors['reviewImage']}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            }
                                        </div>
                                    </div>
                                </form>
                                {this.state.dialogCapturedAction == 'Alert' && (
                                    <div className="modal-footer">
                                        <button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" onClick={() => this.onDeleteLocation('Close')} data-dismiss="modal">
                                            <span>No</span>
                                        </button>
                                        <button type="button" className="btn linear-gradient yr-submitbtn btn-sm" onClick={() => this.onDeleteLocation('Delete')}>
                                            Yes
                                        </button>
                                    </div>
                                )}
                                {this.state.dialogCapturedAction == 'AlertC' && (
                                    <div className="modal-footer">
                                        <button type="button" className="yr-cancelbtn linear-gradient-cancel linear-gradient btn-sm yr-btn-small" onClick={() => this.onDeleteLocation('Close')} data-dismiss="modal">
                                            <span style={{color:"black"}}>No</span>
                                        </button>
                                        <button type="button" className="btn linear-gradient yr-submitbtn btn-sm" onClick={() => this.onCustomAction('', 'Delete')}>
                                            Yes
                                        </button>
                                    </div>
                                )}
                                {(this.state.dialogCapturedAction == 'Add' || this.state.dialogCapturedAction == 'Edit') && (
                                    <div className="modal-footer">
                                        <button type="button" className="linear-gradient-cancel yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" onClick={() => this.onDeleteLocation('Close')} data-dismiss="modal">
                                            <span>Cancel</span>
                                        </button>
                                        <button type="button" className="btn linear-gradient yr-submitbtn btn-sm" onClick={() => this.onCustomAction('', 'Save')}>
                                            Save
                                        </button>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>)}
            </div>

        )
    }
}

export default ReviewViaChat;